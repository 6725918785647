import crudActions from '@/store/crud/actions'
import $http from "axios";

const actions = {
  ...crudActions('mailings'),
  Send({ commit, dispatch }, params) {
    return new Promise((resolve, reject) => {
      $http
        .post(`/mailings/${params.id}/send_mail`, params)
        .then(
          (response) => {
            commit("SET", { type: "item_saved", items: response.data.success });
            if (response.data.success) {
            } else {
              dispatch("marketplace/Failure", response.data.message, {
                root: true,
              });
            }
            resolve(response.data);
          },
          (error) => {
            reject(error);
          }
        )
        .catch((err) => console.log(err.response));
    });
  },
}
export default actions
