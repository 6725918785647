export default {
  getLogin(state) {
    return state.login
  },
  getRegister(state) {
    return state.register
  },
  getRemind(state) {
    return state.remind
  },
  getAuth(state) {
    return state.auth
  }
}