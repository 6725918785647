import $http from 'axios';
import crudActions from '@/store/crud/actions'
const actions = {
  ...crudActions('delivery_mans'),
  ShowRoute({ commit,dispatch }, {id,date}){
    return new Promise((resolve, reject) => {
      $http.get(`/delivery_mans/${id}/route?date=${date}`).then(response => {
        if(response.data.success){
          commit('SET', {type: 'item_current', items: response.data.item })
        }
        else{
          commit('SET', {type: 'item_current', items: {} })
          dispatch('marketplace/Failure',response.data.message, {root: true})
        }
        resolve(response.data);
      }, error => {
          reject(error);
        }
      ).catch(err => console.log(err.response))
    })

  }
}
export default actions
