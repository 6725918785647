import $http from "axios";
import crudActions from '@/store/crud/actions'


const actions = {
  ...crudActions('packings'),
  Edit({ commit, dispatch }, params) {
    return new Promise((resolve, reject) => {
      $http(`/packings/${params.id}/edit`, {params: params})
        .then(
          (response) => {
            if (response.data.success) {
              commit("SET", { type: "item_edited", items: response.data.item });
              resolve(response.data);
            } else {
              commit("SET", { type: "item_edited", items: {} });
              dispatch("marketplace/Failure", response.data.message, {
                root: true,
              });
            }
          },
          (error) => {
            console.log(error);
          }
        )
        .catch((err) => console.log(err.response));
    });
  },
  Start({ commit, dispatch }, id) {
    return new Promise((resolve, reject) => {
      $http(`/packings/${id}/start`)
        .then(
          (response) => {
            if (response.data.success) {
              commit("SET", {
                type: "item_saved",
                items: response.data.success,
              });
              resolve(response.data);
            } else {
              commit("SET", { type: "item_saved", items: {} });
              dispatch("marketplace/Failure", response.data.message, {
                root: true,
              });
            }
          },
          (error) => {
            console.log(error);
          }
        )
        .catch((err) => console.log(err.response));
    });
  },
  Confirm({ commit, dispatch }, params) {
    return new Promise((resolve, reject) => {
      $http(`/packings/${params.id}/confirm`, {params: params})
        .then(
          (response) => {
            if (response.data.success) {
              commit("SET", {
                type: "item_saved",
                items: response.data.success,
              });
              resolve(response.data);
            } else {
              commit("SET", { type: "item_saved", items: {} });
              dispatch("marketplace/Failure", response.data.message, {
                root: true,
              });
            }
          },
          (error) => {
            console.log(error);
          }
        )
        .catch((err) => console.log(err.response));
    });
  },
  Update({ commit, dispatch }, params) {
    return new Promise((resolve, reject) => {
      $http.put(`/packings/${params.id}`, params)
        .then(
          (response) => {
            if (response.data.success) {
              commit("SET", { type: "item_saved", items: response.data.success });
              resolve(response.data);
            } else {
              commit("SET", { type: "item_saved", items: response.data.success });
              dispatch("marketplace/Failure", response.data.message, {
                root: true,
              });
            }
          },
          (error) => {
            console.log(error);
          }
        )
        .catch((err) => console.log(err.response));
    });
  },
};
export default actions