import { render, staticRenderFns } from "./AddProductTableItem.pug?vue&type=template&id=478a1ef2&scoped=true&lang=pug&"
import script from "./AddProductTableItem.js?vue&type=script&lang=js&"
export * from "./AddProductTableItem.js?vue&type=script&lang=js&"
import style0 from "./AddProductTableItem.scss?vue&type=style&index=0&id=478a1ef2&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../shared/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "478a1ef2",
  null
  
)

export default component.exports