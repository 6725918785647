import crudState from '@/store/crud/state'
const state = {
  ...crudState(),
  items: {},
  item_edited: {
    title: {},
    text: {}
  },
  item: null,
}
export default state