import crudState from '@/store/crud/state'
const state = {
  ...crudState(),
  item_rating: {},
  products: [],
  offers: {
    items:[],
    total: 0
  },
  requests: {
    items:[],
    total: 0
  },
  active_doc_type: null,
  active_category: {}
}
export default state
