import Treeselect from '@riophae/vue-treeselect';

export default {
  props: {
    items: {
      type: Array,
      default: () => []
    },
    vmodel: {
      type: String,
      default: () => "point"
    },
    disabled: {
      type: Boolean,
      default: () => false
    }
  },
  components: {
    Treeselect
  },
  methods:{
    
  }
}