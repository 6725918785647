import crudState from '@/store/crud/state'
const state = {
  ...crudState(),
  item_new: {},
  log_items: [],
  buttons: [],
  filters: {
    title: "",
    warehouse_id: "",
  },
  sorting: {},
}
export default state