import $http from 'axios';

export default {
  Save({ commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      $http.put(`/color`, { item: payload })
        .then(
          response => {
            commit("SET", { type: "item_saved", items: response.data.success });
            resolve(response.data);
          },
          error => {
            reject(error);
          }
        )
        .catch(err => console.log(err));
    });
  },
  setColorPalette({ commit }, item) {
    commit("SET_LOCALSTORE", { type: "colorPalette", items: item });
  }
}
