export default {
  computed: {
    activePoint: {
      // getter
      get: function() {
        return this.$store.getters["cart/getActivePoint"];
      },
      // setter
      set: function(newValue) {
        this.$store.dispatch("cart/setActivePoint", newValue);
      },
    },
    activeCustomer: {
      // getter
      get: function() {
        return this.$store.getters["cart/getActiveCustomer"];
      },
      // setter
      set: function(newValue) {
        this.$store.dispatch("cart/setActiveCustomer", newValue);
      },
    },
    activeCompany: {
      // getter
      get: function() {
        return this.$store.getters["cart/getActiveCompany"];
      },
      // setter
      set: function(newValue) {
        this.$store.dispatch("cart/setActiveCompany", newValue);
      },
    },
    cartTotal() {
      return this.$store.getters["cart/getTotal"];
    },
    cartItems() {
      return (
        this.$store.getters["cart/getCartItems"][this.activePoint] || {}
      );
    },
    cartProducts() {
      let items = { total: 0, points: {} };
      for (let storeId in this.$store.getters["cart/getCartItems"]) {
        let _storeItems = Object.values(
          this.$store.getters["cart/getCartItems"][storeId]
        ).flat();

        let storeItems = {};
        for (let i in _storeItems) {
          for (let id in _storeItems[i]) {
            let item = _storeItems[i][id].item;
            if (item){
              item["bought_quantity"] = _storeItems[i][id].quantity;
              storeItems[item.id] = item;
              items.total +=
                Number(
                  _storeItems[i][id].item.prices[
                    _storeItems[i][id].selected_unit.label
                  ].price
                ) * Number(_storeItems[i][id].quantity);
            }
          }
        }

        
        // let points = [];
        // if (this.loggedIn) {
        //   if (this.user.is_client) {
        //     points = this.user.config.points;
        //   } else {
        //     points = [].concat.apply(
        //       [],
        //       this.user.config.companies.map((company) => company.points)
        //     );
        //   }
        // }
        let points = this.user.config.points;

        let point = points.find((obj) => {
          return obj.id == storeId;
        });
        items.points[storeId] = {
          title: point ? point.label : "",
          items: storeItems,
        };
      }
      return items;
    },
  },
  methods: {
    checkAvailability(){
      if (!this.gon.config.guest_user_id){
        this.$store.dispatch(
          "marketplace/Failure",
          this.translate('common.server_error')
        );
        return false;
      }
      if (!this.loggedIn){
        return true;
      } else {
        if (this.user.is_client) {
          return true
        } else if (this.user.layout.can_buy) {
          if (!this.activeCustomer) {
            this.activeCustomer = this.gon.config.guest_user_id;
          }
          return true
        } else {
          this.$store.dispatch(
            "marketplace/Failure",
            this.translate('error.not_allowed_order')
          );
          return false
        }
      } 
    },
    addToCart(product) {
      if (this.checkAvailability()) {
        this.$store.dispatch("cart/UpdateFullCart", {
          product: product.items[0],
          quantity: 1,
        });
      }
    },
    clearShopCart(id) {
      this.$confirm(
        {
          message: this.translate('common.are_you_sure'),
          button: {
            no: this.translate('common.no_'),
            yes: this.translate('common.yes_')
          },
          callback: confirm => {
            if (confirm) {
              for (let sellerId in this.$store.getters["cart/getCartItems"][id]) {
                for (let productId in this.$store.getters["cart/getCartItems"][id][
                  sellerId
                ]) {
                  this.$store.dispatch("cart/UpdateFullCart", {
                    product: this.$store.getters["cart/getCartItems"][id][sellerId][
                      productId
                    ].item,
                    quantity: 0,
                  });
                }
              }
              this.loadCart({cart: {points: [{id: id, items: []}]}});
            }
          }
        }
      )
    },
    goToCart() {
      if (this.$store.getters["cart/getItemsCount"] > 0) {
        let params = {}
        if (!this.loggedIn) {
          if (this.config.can_buy_in_one_click){
            this.$isLoading("isLoading", "cart_id");
            params.user_id = window.gon.config.guest_user_id
            this.$store.dispatch("cart/Create", params).then((response) => {
              if (response.success) {
                if (this.$route.name == "cart") {
                  location.reload();
                } else {
                  this.$router.push({ name: "cart" });
                }
              } else {
                this.$store.dispatch("marketplace/Failure", response.message);
              }
            });
          } else {
            this.showLogin();
            this.$store.dispatch("account/AddItem", {
              type: "auth",
              items: "register",
            });
            this.logIn = true;
          }
        } else if (this.user.is_client) {
          this.$isLoading("isLoading", "cart_id");
          this.$store.dispatch("cart/Create", {}).then((response) => {
            if (response.success) {
              if (response.message) {
                this.$store.dispatch(
                  "marketplace/Success",
                  response.message +
                    " " +
                    this.translate("common.point_confirmed")
                );
              }
              if (this.$router.history.current.name == "cart") {
                location.reload();
              } else {
                this.$router.push({ name: "cart" });
              }
            } else {
              this.$store.dispatch("marketplace/Failure", response.message);
            }
          });
        } else if (this.user.layout.can_buy) {
          if (this.activeCustomer) {
            params["user_id"] = this.activeCustomer;
            this.$isLoading("isLoading", "cart_id");
            this.$store.dispatch("cart/Create", params).then((response) => {
              if (response.success) {
                if (this.$route.name == "cart") {
                  location.reload();
                } else {
                  this.$router.push({ name: "cart" });
                }
              } else {
                this.$store.dispatch("marketplace/Failure", response.message);
              }
            });
          } else {
            this.$store.dispatch("marketplace/Failure", "Chose user!");
          }
        } 
      }
    },
    loadCartWithoutUpdate(toCabinet) {
      this.$store.dispatch("cart/Show", {}).then((response) => {
        if (!response.success) {
          this.$store.dispatch("marketplace/Failure", response.message);
        } else {
        }
      });
    },
    loadCart({ id, part_id, cart }) {
      this.$isLoading("isLoading", "cart_id");
      this.$store.dispatch("cart/Update", { id, cart }).then((response) => {
        if (response.success) {
          this.$isLoading("isLoading", "item_current");
          this.$store
            .dispatch("cart/Show", { id: id, part: part_id })
            .then((response) => {
              if (!response.success) {
                if (response.error == 'no_more_parts'){
                  this.$store.dispatch("cart/Reset");
                  window.location = "/";
                }
                this.$store.dispatch("marketplace/Failure", response.message);
              } else {
                if (!response.points.length) {
                  this.$store.dispatch("cart/Reset");
                  this.$router.push({ name: "orders" });
                }
              }
            });
        } else {
          this.$store.dispatch("marketplace/Failure", response.message);
        }
      });
    },
    clearCart(product) {
      for (let i in product.items) {
        this.$store.dispatch("cart/UpdateFullCart", {
          product: product.items[i],
          quantity: 0,
        });
      }
    },
  },
};
