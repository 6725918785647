<template lang="pug">
  #app(:style="cssColors")
    .statistics-menu(v-if="user.layout.can_see_finances_balances")
      .statistics-menu--btn(@click="toggleFinanceBoard")
        svg-icon(name="icon-diagram")
      FinanceBoard(v-if="showFinanceBoard")
    vue-confirm-dialog.confirm-dialog
    Header
    loading(:active.sync='isLoading')
    nprogress-container
    router-view
    Footer
    NotificationPopup(:message="message")
    ChangePasswordPopup
</template>

<script>
import Header from "@/components/layout/header/Header.vue";
import Footer from "@/components/layout/footer/Footer.vue";
import ChangePasswordPopup from "@/components/ChangePasswordPopup";
import NotificationPopup from "@/components/popup/NotificationPopup";
import FinanceBoard from "@/components/finance-board/FinanceBoard.vue";
import NprogressContainer from 'vue-nprogress/src/NprogressContainer'
export default {
  channels: {
    ChatChannel: {
      connected() {
        //console.log('I ChatChannel connected.');
      },
      rejected() {},
      received(data) {
        if (data.new_message) {
        }
      },
      disconnected() {}
    },
    NotificationsChannel: {
      connected() {
        this.getNotifications()
      },
      rejected() {},
      received(data) {
        this.$store.dispatch("notifications/SetSideNotifications", data)
      },
      disconnected() {}
    },
    NodeChanel: {
      connected() {
        console.log('NodeChanel connected')
      },
      rejected() {},
      received(data) {
        console.log(data)
      },
      disconnected() {}
    }
  },

  data() {
    return {
      showFinanceBoard: false,
    }
  },

  components: {
    Header,
    Footer,
    ChangePasswordPopup,
    NotificationPopup,
    FinanceBoard,
    NprogressContainer
  },
  mounted() {
    let tagArr = document.getElementsByTagName("input");
    for (let i = 0; i < tagArr.length; i++) {
      tagArr[i].autocomplete = 'nope'
    }
    if ("geolocation" in navigator) {
      /* geolocation is available */
      navigator.geolocation.getCurrentPosition(displayLocationInfo);
      
      function displayLocationInfo(position) {
        console.log(position )
      }
    } else {
      /* geolocation IS NOT available  */
      alert('geolocation IS NOT available on your browser')
    }
    if (gon.reset_token) {
      this.$bvModal.show("change-passsword-popup")
    }
    if (gon.message) {
      this.$store.dispatch("marketplace/Success", gon.message)
    }
    this.$cable.subscribe({ channel: "NotificationsChannel" })
    if (this.$route.meta && this.$route.meta.showLogin) {
      this.showLogin()
    }
    this.trackIdentity()
  },
  async created() {
    await this.$store.dispatch("colors/setColorPalette", gon.config.colors)
    await this.$isLoading("isLoading", "global_config");
    await this.$store.dispatch("marketplace/GlobalConfig").then(response => {
      if(response.success){
        if (this.loggedIn && !this.user.is_client && response.is_outdate) {
          this.$router.push({
            name: 'dashboard'
          });
        }
      }
      else{
        this.$store.dispatch("marketplace/Failure", response.message);
      }
    });
    await this.$store.dispatch('users/RefreshSession')
  },
  methods: {
    toggleFinanceBoard() {
      // this.showFinanceBoard = !this.showFinanceBoard;
      this.$router.push({
        name: 'dashboard'
      });
    },
    getNotifications(){
      this.$cable.perform({
        channel: 'NotificationsChannel',
        action: 'get_notification'
      });
    },
    onlyNumber($event) {
      let theEvent = $event || window.event;

      // Handle paste
      if (theEvent.type === "paste") {
        const key = event.clipboardData.getData("text/plain");
      } else {
        const key = theEvent.keyCode || theEvent.which;
        key = String.fromCharCode(key);
      }
      var regex = /[0-9]|\./;
      if (!regex.test(key)) {
        theEvent.returnValue = false;
        if (theEvent.preventDefault) {
          theEvent.preventDefault();
        }
      }
    }
  },
  computed: {
    cssColors(){
      return {
        '--primary': this.themeColors.primary,
        '--primary2': this.themeColors.secondPrimary,
        '--bg_grey': this.themeColors.body_bg,
        '--white': this.themeColors.layout,
        '--hover_menu': this.themeColors.menuHover,
      }
    },
    message() {
      return this.$store.getters["marketplace/getMessage"];
    },
    error() {
      return this.$store.getters["marketplace/getError"];
    },
    isLoading() {
      return this.$store.getters["marketplace/getLoading"];
    },
    needLogin() {
      return this.$store.getters["marketplace/needLogin"];
    }
  },
  watch: {
    needLogin(val) {
      if (val) {
        this.showLogin();
      }
    },
    message(val) {
      if (val && val != 'default') {
        const self = this
        this.$toast.open({
          message: val,
          type: "info",
          duration: 1000,
          dismissible: true,
          onDismiss: function(){
            self.$store.dispatch('marketplace/Success', 'default')
          }
        })
      }
    },
    error(val) {
      if (val && val != 'default') {
        const self = this
        this.$toast.open({
          message: val,
          type: "custom",
          duration: 1000,
          dismissible: true,
          onDismiss: function(){
            self.$store.dispatch('marketplace/Failure', 'default')
          }
        })
      }
    },
    $route(to, from) {
      if (to.meta.showLogin) {
        this.showLogin();
      }
      if ("/" == to.path) {
        this.$store.dispatch("marketplace/ClearBreadcrumps");
      } else {
        if (!to.params.back) {
          this.$store.dispatch("marketplace/AddBreadcrumps", {
            title: from.meta.breadcrump,
            path: from.path
          });
        }
      }
      this.$store.dispatch("users/CheckMessages");
      this.$store.dispatch('users/RefreshSession')
    },
  }
};
</script>

<style lang="scss">
@import "@/assets/styles/main.scss";
.vld-icon {
  display: none;
}
</style>
