import NavMenu from "@/components/layout/nav-menu/NavMenu";
import ExternalLinks from "@/components/ui/external-links/ExternalLinks";
import ToTopBtn from "@/components/ui/to-top-btn/ToTopBtn";
export default {
  components: {
    NavMenu,
    ExternalLinks,
    ToTopBtn
  },
  methods:{
    onUpdate (e){
      this.contact.country_code = e.countryCode
      this.contact.phone = e.formattedNumber 
      this.contact.phone_number = e.nationalNumber
    },
    submit(){
      this.submited = true;
      this.$validator.validateAll().then((result) => {
        if(result){
          this.$isLoading('isLoading', 'contact_saved');
          this.$store.dispatch('marketplace/Contact', this.contact ).then(response => {
            if(response.success){
              this.contact = {
                email: null,
                text: null,
                name: null,
              }
              this.phone_number = null
              this.submited = false;
              this.$store.dispatch('marketplace/Success', this.translate("common.success_saved") )
            }
          })
        }
      })
    },
  },

  data() {
    return {
      submited: false,
      contact:{
        email: null,
        text: null,
        name: null
      },
      phone_number: null,
      telProps: {
        'required': true,
        'default-country-code': gon.config.country,
        'preferred-countries': [gon.config.country],
        'valid-color': 'green',
        'error': false,
      },
    }
  }
}