import $http from "axios";
import crudActions from '@/store/crud/actions'
const actions = {
  ...crudActions('incoming_invoices'),
  Update({ commit, getters }, item) {
    return new Promise((resolve, reject) => {
      let form = new FormData();
      if (item.files) {
        for (var i = item.files.length - 1; i >= 0; i--) {
          form.append(`attachments['${i}']`, item.files[i]);
        }
      }
      for (const [key, value] of Object.entries(item)) {
        if (key == "items") {
          form.append("items", JSON.stringify(item.items));
          continue;
        }
        form.append(key, value);
      }

      $http
        .put(`/incoming_invoices/${item.id}`, form, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then(
          (response) => {
            commit("SET", {
              type: "item_saved",
              items: response.data.success,
            });
            resolve(response.data);
          },
          (error) => {
            console.log(error);
          }
        )
        .catch((err) => console.log(err.response));
    });
  },
  Show({ commit, dispatch }, id) {
    return new Promise((resolve, reject) => {
      $http.get(`/incoming_invoices/${id}`).then(response => {
        const item = response.data.success ? response.data.item : {}
        commit('SET', {type: 'item_edited', items: item })
        if (!response.data.success) {
          dispatch('marketplace/Failure', response.data.message, { root: true })
        }
        resolve(response.data);
      }, error => {
          reject(error);
        }
      ).catch(err => console.log(err.response))
    })
  },
  Edit({ commit, dispatch }, id) {
    return new Promise((resolve, reject) => {
      $http
        .get(`/incoming_invoices/${id}/edit`)
        .then(
          (response) => {
            if (response.data.success) {
              commit("SET", { type: "item_edited", items: response.data.item });
              commit("SET", { type: "suppliers", items: response.data.items });
              resolve(response.data);
            } else {
              commit("SET", { type: "item_edited", items: {} });
              dispatch("marketplace/Failure", response.data.message, {
                root: true,
              });
            }
          },
          (error) => {
            console.log(error);
          }
        )
        .catch((err) => console.log(err.response));
    });
  },
  Create({ commit, dispatch }) {
    return new Promise((resolve, reject) => {
      $http
        .post(`/incoming_invoices`)
        .then(
          (response) => {
            commit("SET", {
              type: "item_saved",
              items: response.data.success,
            });
            if (response.data.success) {
              resolve(response.data);
            } else {
              commit("SET", { type: "items", items: [] });
              dispatch("marketplace/Failure", response.data.message, {
                root: true,
              });
              resolve(response.data);
            }
          },
          (error) => {
            reject(error);
          }
        )
        .catch((err) => console.log(err.response));
    })
  }
}
export default actions
