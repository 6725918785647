import $http from "axios";
import crudActions from '@/store/crud/actions'

export default {
  ...crudActions('articles'),
  List({ commit, dispatch }) {
    return new Promise((resolve, reject) => {
      $http
        .get(`/articles`)
        .then(
          (response) => {
            if (response.data.success) {
              commit("SET", { type: "items", items: response.data.items });
            } else {
              commit("SET", { type: "items", items: [] });
              dispatch("marketplace/Failure", response.data.message, {
                root: true,
              });
            }
            resolve(response.data);
          },
          (error) => {
            reject(error);
          }
        )
        .catch((err) => console.log(err.response));
    });
  }
};
