export default {
  props: {
    placeholder: String,
    type: String,
    query: {
      type: String,
      default: "title"
    }
  },
  data() {
    return {
      title: "",
    };
  },
  mounted() {
    if (this.$store.getters[`${this.type}/getFilters`]) {
      this.title = this.$store.getters[`${this.type}/getFilters`].title;
    }
  },
  methods: {
    update() {
      let q = {}
      if (this.$route.query && this.$route.query[this.query] == this.title) {
        this.$router.push({ query: {} });
        this.$router.push({ query: this.$route.query });
      }
      if (this.title) {
        q[this.query] = this.title
        if (this.$route.params) {
          this.$router.push({
            name: this.$route.name,
            query: Object.assign({}, this.$route.query, q),
          });
        } else {
          this.$router.push({
            query: Object.assign({}, this.$route.query, q),
          });
        }
      } else {
        q[this.query] = ""
        this.$router.push({
          query: Object.assign({}, this.$route.query, q),
        });
      }
    },
  },
};
