import crudGetters from '@/store/crud/getters'
const getters = {
  ...crudGetters(),
  getButtons(state) {
    return state.buttons;
  },
}
export default getters

  

