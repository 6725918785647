import crudGetters from '@/store/crud/getters'
const getters = {
  ...crudGetters(),
  getActiveItem(state){
    return state.active_item;
  },
  getActiveItemType(state){
    return state.active_item_type;
  },
  getMyItems(state){
    return state.my_items;
  },
  getFilters(state){
    return state.filters;
  },
  getSorting(state){
    return state.sorting;
  },
  getFullItems(state){
    return state.full_items;
  },
  getPage(state){
    return state.page || 1;
  },
  getTotal(state){
    return state.total;
  },
  getFilteredIds(state){
    return state.filtered_ids;
  },
  getSelected(state){
    return state.selected_items;
  },
  getFullItem(state){
    return state.full_item;
  }
}
export default getters