<template lang="pug">
.mt-4(:class="user.is_client ? 'container' : 'container-fluid'")
  .row
    .col-12
      router-link.back-btn(:to="{ name: 'cabinet-menu' }" v-if="loggedIn")
        span.arrow-icon
        svg-icon.icon-user.back-btn--icon(name="icon-user")
        | {{ translate('common.my_profile') }}
    template(v-if="user.is_client")
      .col-12.d-block.d-lg-none
        .mobile-categories-block
          span.read-more-link(@click.prevent="showMobileCats = !showMobileCats")
            | {{ translate('common.show_categories') }}
            span.arrow-icon(:class="{ active: showMobileCats }")
          Aside(v-on:update="update", v-if="showMobileCats")
        Filters(v-on:update="update" :items="filters")

      .col-xl-3.col-lg-4.d-none.d-lg-block.side-nav-wrapper(
        :class="{ collapsed: !showMenu }"
      )
        .burger-btn(@click.prevent="toggleAside")
          span.line.line--top
          span.line.line--center
          span.line.line--bottom
        Aside(v-on:update="update", :showMenu="showMenu")
    .col-xl-3.col-lg-4.d-none.d-lg-block.side-nav-wrapper(v-else)
      CabinetSideNav
    .col
      .row.mb-3(v-if="user.is_client")
        .col-12.col-sm-6.mb-3.mb-sm-0(v-if="user.customer")
          SelectBar(
            :placeholder="translate('common.select_point')",
            :items="user.config.points"
          )
        .col-12(:class="user.customer ? 'col-sm-6' : ''")
          SearchBar(
            :placeholder="translate('common.search_' + activeType)",
            type="products"
          )
      template(v-else-if="user.layout && user.layout.can_buy")
        .row.mb-3(v-if="loggedIn")
          .col-12.col-sm-6.mb-3.mb-sm-0
            SearchBar(
              :placeholder="translate('common.search_' + activeType)",
              type="products"
            ) 
          .col-12.col-sm-6
            treeselect(
              :options="user.config.companies"
              :disable-branch-nodes="true"
              :show-count="true"
              v-validate="'required'",
              v-model="activeCustomer"
              :placeholder="translate('common.user')",
            )
        .row.mb-3(v-else)
          .col-12
            SearchBar(
              :placeholder="translate('common.search_' + activeType)",
              type="products"
            ) 
      .row.mb-3(v-else)
        .col-12
          SearchBar(
            :placeholder="translate('common.search_' + activeType)",
            type="products"
          ) 
      .product-list(v-if="doShowProducts")
        .heading
          template(v-if="user.is_client")
            h1.category-heading(v-if="currentCategory" @click.prevent="resetPage") 
              svg-icon(name="icon-arrow-left")
              | {{ currentCategory.title }}
            h1(v-else) {{ translate('common.heading_' + activeType) }}
          template(v-else)
            .d-flex.align-items-center
              button.btn.category-btn(
                @click.prevent="openCategory",
              )  {{ translate('common.select_category') }}
              span.chosen-category(v-if="currentCategory") {{ currentCategory.title }}
                span.remove-category(@click.prevent="resetPage")
            SelectCategoryPopup(
              :items="config.categories",
              v-model="currentCategory",
              data-vv-name="category"
            )
          .view-switcher.d-none.d-sm-flex
            a.view-switcher--btn(
              href="#",
              @click.prevent="isPlate = true",
              :class="{ active: isPlate }"
            )
              svg-icon(name="icon-view_plate")
            a.view-switcher--btn.view-list(
              href="#",
              @click.prevent="isPlate = false",
              :class="{ active: !isPlate }"
            )
              svg-icon(name="icon-view_list")
        .sort-bar-block
          SortBar(
            :types="sortButtons",
            v-on:update="update",
            place="products"
          )
        div(v-if="0 == items.length && !$root.isLoading")
          EmptyList
        .offer-list
          //- (v-if="0 != items.length && !$root.isLoading")
          transition(name="slide-fade")
            .row(v-if="isPlate || window.width < 767.98")
              ProductItemOfferPlate(
                :product="product",
                v-for="product in items",
                :key="product.id"
              )
          transition(name="slide-fade")
            div(v-if="!isPlate && window.width > 767.98")
              ProductTable(:items="items")
        Pagination(
          v-on:update="update",
          :total="total",
          :currentPage="currentPage"
        )
      .product-list(v-else-if="currentCategory")
        CategoryBlock
      .product-list(v-else)
        .heading
          template(v-if="user.is_client")
            h1.category-heading(v-if="currentCategory" @click.prevent="resetPage") 
              svg-icon(name="icon-arrow-left")
              | {{ currentCategory.title }}
            h1(v-else) {{ translate('common.heading_' + activeType) }}
          template(v-else)
            button.btn.category-btn(
              @click.prevent="openCategory",
            )  {{ translate('common.select_category') }}
            span(v-if="currentCategory" @click.prevent="resetPage") {{ currentCategory.title + ' x' }}
            SelectCategoryPopup(
              :items="config.categories",
              v-model="currentCategory",
              data-vv-name="category"
            )
          .view-switcher
            a.view-switcher--btn(
              href="#",
              @click.prevent="isPlate = true",
              :class="{ active: isPlate }"
            )
              svg-icon(name="icon-view_plate")
            a.view-switcher--btn.view-list(
              href="#",
              @click.prevent="isPlate = false",
              :class="{ active: !isPlate }"
            )
              svg-icon(name="icon-view_list")
        .sort-bar-block.d-none.d-md-flex
          SortBar(
            :types="sortButtons",
            v-on:update="update",
            place="products"
          )
        div(v-if="0 == items.length && !$root.isLoading")
          EmptyList
        .offer-list
          //- (v-if="0 != items.length && !$root.isLoading")
          transition(name="slide-fade")
            .row(v-if="isPlate || window.width < 767.98")
              ProductItemOfferPlate(
                :product="product",
                v-for="product in showProducts",
                :key="product.id"
              )
          transition(name="slide-fade")
            div(v-if="!isPlate && window.width > 767.98")
              ProductTable(:items="items") 
        Pagination(
          v-on:update="update",
          v-if="doShowProducts"
          :total="total",
          :currentPage="currentPage"
        )
</template>

<script>
import Aside from "@/components/layout/aside/Aside";
import Filters from "@/components/layout/filters/Filters";
import SearchBar from "@/components/layout/search-bar/SearchBar";
import DealSwitcher from "@/components/layout/deal-switcher/DealSwitcher";
import SortBar from "@/components/layout/sort-bar/SortBar";
import ProductItemRequest from "@/components/marketplace/request/product-item/ProductItemRequest";
import EmptyList from "@/components/layout/empty-list/EmptyList";
import Pagination from "@/components/ui/pagination/Pagination";
import BidPopup from "@/components/popup/BidPopup";
import SelectCategoryPopup from "@/components/popup/SelectCategoryPopup";
import Treeselect from '@riophae/vue-treeselect'
import SendOffersPopup from "@/components/popup/SendOffersPopup";
import CabinetSideNav from "@/components/cabinet/side-nav/side-nav-block/SideNav";

import SelectBar from "@/components/ui/select-bar/SelectBar";
import ProductItemOfferPlate from "@/components/platform/marketplace/offer/product-item-plate/ProductItemOfferPlate";
import ProductTable from "@/components/platform/marketplace/offer/product-table/ProductTable";
import CategoryBlock from "@/components/marketplace/CategoryBlock";

export default {
  name: "home",
  components: {
    Aside,
    Filters,
    SearchBar,
    SelectCategoryPopup,
    SelectBar,
    DealSwitcher,
    Treeselect,
    SortBar,
    ProductItemOfferPlate,
    CabinetSideNav,
    ProductTable,
    ProductItemRequest,
    Pagination,
    BidPopup,
    SendOffersPopup,
    EmptyList,
    CategoryBlock
  },
  methods: {
    resetPage() {
      this.$store.dispatch('marketplace/setCurrentCategory', null);
      this.$router.push({name: "marketplace"})
    },
    toggleAside() {
      this.showMenu = !this.showMenu;
    },
    openCategory() {
      this.$bvModal.show("select-category");
    },
    handleResize() {
      this.window.width = window.innerWidth;
    },
    update({ page }) {
      this.$isLoading("isLoading", "items");
      if (page) {
        this.$store.dispatch("products/SetPage", page);
        this.$store.dispatch("products/List");
        window.scrollTo(0, 0);
      }
    },
    processQuery() {
      let query = this.$route.query;
      let filters = {
        characteristics: {}
      };
      if (query.direction && query.field) {
        this.$store.dispatch("products/SetSorting", {
          direction: query.direction,
          field: query.field,
        });
      }
      Object.keys(query).forEach(key => {
        if (/\D/.test(key)){
          filters[key] = query[key]
        } else {
          filters.characteristics[key] = query[key]
        }
      })
      filters["active"] = true;
      this.$store.dispatch(
        "products/SetActiveItemsType",
        this.$route.meta.productType
      );
      this.$store.dispatch("products/SetFilters", filters);
      this.$store.dispatch("marketplace/SetProductFilters", filters);
      this.$isLoading("isLoading", "items");
      this.$store.dispatch("products/List");
    },
  },
  watch: {
    $route(val) {
      this.processQuery(val);
    },
    currentCategory(val){
      if (val){
        this.$router.push({});
        if (!val.children.length) {
          let params = {
            query: Object.assign({}, this.$route.query, { category_id: val.id }),
          };
          this.$router.push(params);
        }
      }
    },
  },
  computed: {
    doShowProducts() {
      return !!(Object.values(this.$route.query).length)
    },
    currentCategory: {
      get(){
        return this.$store.getters["marketplace/getCurrentCategory"];
      },
      set(item){
        this.$store.dispatch('marketplace/setCurrentCategory', item);
      }
    },
    total() {
      return this.$store.getters["products/getTotal"];
    },
    showProducts(){
      return this.$store.getters["show_products/getShowProducts"];
    },
    activeType() {
      return this.$store.getters["products/getActiveItemType"];
    },
    items() {
      return this.$store.getters["products/getItems"];
    },
    currentPage() {
      return this.$store.getters["products/getPage"];
    },
    filters(){
      return this.config.filters
    }
  },
  created() {
    if (!this.$store.getters["show_products/getShowProducts"].length){
      this.$store.dispatch("show_products/List");
    }
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
    if (!this.loggedIn) {
      this.activeCustomer = this.gon.config.guest_user_id;
    } 
    this.processQuery(this.$route);
    if (!this.user.is_client){
      this.isPlate = false
    }
  },
  data() {
    return {
      window: {
        width: 0,
      },
      test: null,
      disablePointSelect: false,
      showMobileCats: false,
      showMenu: true,
      sortButtons: [
        "shop_products|created_at",
        "shop_products|on_sale",
        "shop_products|labels",
        "shop_product_prices|price",
        "shop_products|quantity",
        "shop_products|title"
      ],
      isPlate: true,
    };
  },
};
</script>

<style lang="scss" scoped>
.view-switcher {
  @media (max-width: 767.98px) {
    display: none;
  }
}

.chosen-category {
    display: flex;
    align-items: center;
    border: 1px solid var(--black);
    margin-left: 10px;
    padding: 4px 7px 4px 10px;
    border-radius: 20px;
  .remove-category {
    margin-left: 7px;
    &:before {
      content: '✕';
      display: flex;
      align-items: center;
      justify-content: center;
      width: 20px;
      height: 20px;
      font-size: 14px;
      border-radius: 20px;
      color: var(--white);
      background: var(--black);
    }
  }
}

/deep/.vue-treeselect__control {
  border: none;
}

.category-heading {
  cursor: pointer;
  display: flex;
  align-items: center;
  span {
    width: 15px;
    position: relative;
    top: -2px;
    margin-right: 10px;
  }
}

.offer-list {
  margin-top: 20px;

  // @media (min-width: 992px) {
  //   div[class*="col"] {
  //     min-height: 371px;
  //   }
  // }
}

.side-nav-wrapper {
  // transition: all 0.6s ease;
  &.collapsed {
    flex: 0;
    overflow: hidden;
  }
}
@media (min-width: 992px) {
  .aside {
    // transition: all 0.6s ease;
    position: relative;
    left: -350px;
    &.active {
      left: 0;
    }
  }
}

.burger-btn {
  position: absolute;
  top: 15px;
  right: -15px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 45px;
  height: 15px;
  border-radius: 5px 5px 0 0;
  background: var(--corp_grey);
  transform: rotate(90deg);
  .line {
    height: 1px;
    margin-bottom: 2px;
    background: #fff;
    &:last-child {
      margin: 0;
    }
  }
}

.mobile-categories-block {
  .read-more-link {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 12px;
    margin-bottom: 20px;
    .arrow-icon {
      height: 8px;
      &:before,
      &:after {
        height: 8px;
      }
      &:before {
        left: 4px;
      }
      &:after {
        right: 4px;
      }
    }
  }
}
</style>
