import crudGetters from '@/store/crud/getters'
const getters = {
  ...crudGetters(),
  getItemNew(state) {
    return state.item_new;
  },
  getPnlData(state){
    return state.item_pnl
  }
}
export default getters
