import $http from "axios";
import crudActions from '@/store/crud/actions'
const actions = {
  ...crudActions('tasks'),
  New({ commit, dispatch }) {
    return new Promise((resolve, reject) => {
      $http
        .get(`/tasks/new`)
        .then(
          (response) => {
            if (response.data.success) {
              commit("SET", {
                type: "item_new",
                items: response.data.item,
              });
            } else {
              commit("SET", { type: "item_new", items: {} });
              dispatch("marketplace/Failure", response.data.message, {
                root: true,
              });
            }
            resolve(response.data);
          },
          (error) => {
            reject(error);
          }
        )
        .catch((err) => console.log(err.response));
    });
  },
  Finish({ commit, dispatch }, id) {
    return new Promise((resolve, reject) => {
      $http(`/tasks/${id}/finish`)
        .then(
          (response) => {
            if (response.data.success) {
              commit("SET", { type: "item_edited", items: {} });
              resolve(response.data);
            } else {
              commit("SET", { type: "item_edited", items: {} });
              dispatch("marketplace/Failure", response.data.message, {
                root: true,
              });
            }
          },
          (error) => {
            console.log(error);
          }
        )
        .catch((err) => console.log(err.response));
    });
  }
}
export default actions