import crudState from '@/store/crud/state'
const state = {
  ...crudState(),
  filters: {},
  item_new: [],
  item_deleted: false,
  item_updated: false,
  page: 1,
  total: 0,
  notifications: {
    'orders': 0,
    'cabinet-chats': 0,
    'cabinet-notifications': 0,
    'my_leads': 0,
    'crm_tasks': 0,
  }
};
export default state
