export default {
  methods:{
    links(){
      return [
        {
          path: "https://eurocommerce.biz/",
          title: "Eurocommerce LTD"
        },
        {
          path: "/page/policy",
          title: "Privacy policy"
        }


        
      ];
    }
  }
}