import { render, staticRenderFns } from "./CommentTextBlock.pug?vue&type=template&id=06752d56&lang=pug&"
import script from "./CommentTextBlock.js?vue&type=script&lang=js&"
export * from "./CommentTextBlock.js?vue&type=script&lang=js&"
import style0 from "./CommentTextBlock.scss?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../shared/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports