import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";

import account from "./account";
import cabinet from "./cabinet";
import marketplace from "./marketplace";
import cart from "./cart/";
import settings from "./settings/";
import dashboard from "./dashboard/";
import tariffs from "./tariffs/";

import companies from "./companies";
import coupons from "./coupons";
import users from "./users";
import mailings from "./mailings";
import statistic from "./statistic";
import credit_cards from "./credit_cards";
import show_products from "./show_products";
import workers from "./workers";
import balances from "./balances";
import notes from "./notes";
import integrations from "./integrations";
import transaction_types from "./transaction_types";
import categories from "./categories";

import telegram from "./telegram";
import instagram from "./instagram";
import liqpay from "./liqpay";
import binotel from "./binotel";
import sms from "./sms";

import loyalty from "./loyalty";
import nova_post from "./nova_post";
import tasks from "./tasks";
import payment_receivers from "./payment_receivers";
import leads from "./leads";
import sellers from "./sellers";
import outlets from "./outlets";
import inventories from "./inventories";
import messages from "./messages";
import notifications from "./notifications";
import products from "./products";
import transactions from "./transactions";
import invoices from "./invoices";
import faq from "./faq";
import subscriptions from "./subscriptions";
import agents from "./agents";
import templates from "./templates";
import loyalty_cards from "./loyalty_cards";
import packings from "./packings";
import returns from "./returns";
import warehouses from "./warehouses";
import deliverymans from "./deliverymans";
import warehousemen from "./warehousemen";
import supplier_orders from "./supplier_orders";
import points from "./points";
import payers from "./payers";
import movements from "./movements";
import managers from "./managers";
import storage_items from "./storage_items";
import customers from "./customers";
import clients from "./clients";
import incoming_invoices from "./incoming_invoices";
import prices from "./prices";
import suppliers from "./suppliers";
import routes from "./routes";
import payments from "./payments";
import calendar from "./calendar";
import bonuses from "./bonuses";
import features from "./features";
import locations from "./locations";
import characteristics from "./characteristics";
import polygons from "./polygons";
import colors from "./colors";
import roles from "./roles";
import articles from "./articles";
import about from "./about";
import statuses from "./statuses";
import groups from "./groups";
import decline_statuses from "./decline_statuses";
import activity_types from "./activity_types";
import sources from "./sources";
import tags from "./tags";
import telegram_users from "./telegram_users";
import facebook from "./facebook";

const key = "sale_hub";

const { plugin: vuexLocal } = new VuexPersistence({
  key,
  storage: window.localStorage,
  filter: (mutation) => mutation.type.search("LOCALSTORE") !== -1,
  reducer: function reducer(_ref2) {
    var user = _ref2.users.item_current;
    var active_type = _ref2.marketplace.active_type;
    var breadcrumbs = _ref2.marketplace.breadcrumbs;
    var last_config = _ref2.marketplace.last_config;
    var cart_items = _ref2.cart.cart_items;
    var cart_id = _ref2.cart.cart_id;
    var colorPalette = _ref2.colors.colorPalette
    var last_items = _ref2.products.last_items;

    return {
      users: {
        user: user,
        item_current: user,
      },
      breadcrumbs: breadcrumbs,
      cart: {
        cart_items: cart_items,
        cart_id: cart_id,
      },
      marketplace: {
        active_type: active_type,
        last_config: last_config,
      },
      colors: {
        colorPalette: colorPalette,
      },
      products: {
        last_items: last_items,
      }
    };
  },
});
const { plugin: vuexSession } = new VuexPersistence({
  key,
  storage: window.sessionStorage,
  filter: (mutation) => mutation.type.search("SESSIONSTORE") !== -1,
  reducer: function reducer(_ref2) {
    var active_point = _ref2.cart.active_point;
    var active_customer = _ref2.cart.active_customer;
    var active_company = _ref2.cart.active_company;
    var diagram_categories = _ref2.statistic.diagram_categories;
    var show_products = _ref2.show_products.show_products;

    return {
      cart: {
        active_point: active_point,
        active_customer: active_customer,
        active_company: active_company,
      },
      diagram_categories: diagram_categories,
      show_products: {
        show_products: show_products,
      }
    };
  },
});
Vue.use(Vuex);

export default new Vuex.Store({
  strict: false, //process.env.NODE_ENV !== 'production',
  modules: {
    marketplace,
    account,
    users,
    messages,
    coupons,
    balances,
    notifications,
    settings,
    returns,
    transactions,
    invoices,
    workers,
    faq,
    credit_cards,
    telegram,
    instagram,
    liqpay,
    show_products,
    binotel,
    tariffs,
    subscriptions,
    movements,
    loyalty_cards,
    mailings,
    cabinet,
    products,
    leads,
    dashboard,
    cart,
    clients,
    categories,
    packings,
    integrations,
    templates,
    loyalty,
    points,
    payers,
    suppliers,
    companies,
    tasks,
    warehouses,
    sellers,
    outlets,
    inventories,
    incoming_invoices,
    supplier_orders,
    payment_receivers,
    nova_post,
    deliverymans,
    agents,
    managers,
    transaction_types,
    customers,
    warehousemen,
    storage_items,
    prices,
    routes,
    payments,
    notes,
    statistic,
    calendar,
    characteristics,
    features,
    bonuses,
    locations,
    polygons,
    colors,
    roles,
    articles,
    about,
    statuses,
    groups,
    decline_statuses,
    activity_types,
    sources,
    tags,
    telegram_users,
    sms,
    facebook
  },

  plugins: [vuexLocal, vuexSession],
});
