import $http from "axios";
import crudActions from '@/store/crud/actions'

const actions = {
  ...crudActions('templates'),
  List({ commit, getters, dispatch }) {
    let params = {
      page: getters.getPage,
    };

    return new Promise((resolve, reject) => {
      $http
        .get(`/templates`, { params: params })
        .then(
          (response) => {
            if (response.data.success) {
              commit("SET", { type: "items", items: response.data.items });
            } else {
              commit("SET", { type: "items", items: [] });
              dispatch("marketplace/Failure", response.data.message, {
                root: true,
              });
            }
            resolve(response.data);
          },
          (error) => {
            reject(error);
          }
        )
        .catch((err) => console.log(err.response));
    });
  },
  CreateOrder({ commit, dispatch }, id) {
    return new Promise((resolve, reject) => {
      $http(`/templates/${id}/create_order`)
        .then(
          (response) => {
            commit("SET", { type: "item_saved", items: response.data.success });
            if (response.data.success) {
            } else {
              dispatch("marketplace/Failure", response.data.message, {
                root: true,
              });
            }
            resolve(response.data);
          },
          (error) => {
            console.log(error);
          }
        )
        .catch((err) => console.log(err.response));
    });
  }
}
export default actions