export default {
  active_point: 1,
  active_customer: null,
  active_company: null,
  item_current: {
    item: {
      parts: [{ customer: {} }],
    },
    points: [],
  },
  cart_id: 0,
  items: {},
  total: 0,
  buttons: [],
  cart_items: {},
  paydesk_items: {},
  item_unconfirmed: {},
  item_new: {},
  count: 0,
  filters: {},
  page: 1,
};
