import crudState from '@/store/crud/state'
const state = {
  ...crudState(),
  senders: [],
  sender_cities: [],
  recipient_cities: [],
  recipient_warehouses: [],
  selected_items: {},
  recipient_streets: [],
  sender_warehouses: [],
  sender_streets: [],
  item_list: []
}
export default state