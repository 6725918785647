import crudActions from '@/store/crud/actions'
import $http from "axios";

const actions = {
  ...crudActions('integrations'),
  Import({ commit, dispatch }, params) {
    return new Promise((resolve, reject) => {
      $http(`/integrations/${params.id}/import`, {params: params})
        .then(
          response => {
            commit("SET", { type: "item_saved", items: response.data.success });
            if (response.data.success) {
            } else {
              dispatch("marketplace/Failure", response.data.message, {
                root: true
              });
            }
            resolve(response.data);
          },
          error => {
            reject(error);
          }
        )
        .catch(err => console.log(err));
    });
  },
  New({ commit, dispatch }) {
    return new Promise((resolve, reject) => {
      $http
        .get(`/integrations/new`)
        .then(
          (response) => {
            commit("SET", { type: "item_edited", items: {credentials: {}} });

            if (response.data.success) {
              commit("SET", {
                type: "item_new",
                items: response.data.item,
              });
            } else {
              commit("SET", { type: "item_new", items: {} });
              dispatch("marketplace/Failure", response.data.message, {
                root: true,
              });
            }
            resolve(response.data);
          },
          (error) => {
            reject(error);
          }
        )
        .catch((err) => console.log(err.response));
    });
  },
}
export default actions
