import { render, staticRenderFns } from "./OfferList.pug?vue&type=template&id=bd2d4502&scoped=true&lang=pug&"
import script from "./OfferList.js?vue&type=script&lang=js&"
export * from "./OfferList.js?vue&type=script&lang=js&"
import style0 from "./OfferList.scss?vue&type=style&index=0&id=bd2d4502&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../shared/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bd2d4502",
  null
  
)

export default component.exports