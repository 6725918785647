import { render, staticRenderFns } from "./NavMenu.pug?vue&type=template&id=15918d1e&scoped=true&lang=pug&"
var script = {}
import style0 from "./NavMenu.scss?vue&type=style&index=0&id=15918d1e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../shared/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "15918d1e",
  null
  
)

export default component.exports