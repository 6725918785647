const crudGetters = () => {
  const getItemCurrent = (state) => {
    return state.item_current
  }
  const getItemEdited = (state) => {
    return state.item_edited
  }
  const getItems = (state) => {
    return state.items.length ? state.items : state.last_items
  }
  const getItem = (state) => {
    return state.item
  }
  const getTotal = (state) => {
    return state.total;
  }
  const getSorting = (state) => {
    return state.sorting
  }
  const getFilters = (state) => {
    return state.filters
  }
  const getPage = (state) => {
    return state.page
  }
  return {
    getItemCurrent,
    getItemEdited,
    getItems,
    getTotal,
    getItem,
    getSorting,
    getFilters,
    getPage
  }
}

export default crudGetters