import { directive as onClickaway } from 'vue-clickaway';

export default {
  directives: {
    onClickaway: onClickaway,
  },
  props: ['list'],
  data() {
    return {
      menuShow: false,
    }
  },
  methods: {
    toggle() {
      this.menuShow = !this.menuShow
    },
    closeMenu(){
      this.menuShow = false
    }
  },
  computed: {
    notifications() {
      return this.$store.getters["notifications/getNotifications"];
    }
  },
}