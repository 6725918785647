export default {
  props: ['heading', 'text'],
  methods: {
    goTo(type){
      if(this.user.user_name){
        this.$router.push({name: 'add-new-' + type})
      }
      else{
        this.showLogin()
      }
    }
  }
}