import $http from "axios";
import crudActions from '@/store/crud/actions'
const actions = {
  ...crudActions('inventories'),
  Finish({ commit, getters, dispatch }, id) {
    return new Promise((resolve, reject) => {
      $http
        .get(`/inventories/${id}/finish`)
        .then(
          response => {
            if (response.data.success) {
            } else {
              commit("SET", { type: "items", items: [] });
              dispatch("marketplace/Failure", response.data.message, {
                root: true
              });
            }
            resolve(response.data);
          },
          error => {
            reject(error);
          }
        )
        .catch(err => console.log(err.response));
    });
  },
  Update({ commit, getters }, { id, item }) {
    return new Promise((resolve, reject) => {
      $http
        .put(`/inventories/${id}`, {
          item: item,
        })
        .then(
          (response) => {
            if (response.data.success) {
              commit("SET", {
                type: "item_edited",
                items: response.data.item
              });
            } else {
              commit("SET", { type: "item_edited", items: {} });
              dispatch("marketplace/Failure", response.data.message, {
                root: true
              });
            }
            resolve(response.data);
          },
          (error) => {
            reject(error);
          }
        )
        .catch((err) => console.log(err.response));
    });
  },
  Save({ commit, dispatch }) {
    return new Promise((resolve, reject) => {
      $http.post(`/inventories`)
        .then(
          response => {
            commit("SET", { type: "item_saved", items: response.data.success });
            if (response.data.success) {
            } else {
              dispatch("marketplace/Failure", response.data.message, {
                root: true
              });
            }
            resolve(response.data);
          },
          error => {
            reject(error);
          }
        )
        .catch(err => console.log(err));
    });
  },
  Show({ commit, dispatch, getters }, id) {

    let params = {
      filters: getters.getFilters,
      page: getters.getPage,
      sorting: getters.getSorting,
    };

    return new Promise((resolve, reject) => {
      $http
        .get(`/inventories/${id}`, {params: params})
        .then(
          response => {
            if (response.data.success) {
              commit("SET", {
                type: "item_edited",
                items: response.data.item
              });
              commit("SET", { type: "total", items: response.data.total })
              commit('SET', {type: 'buttons', items: response.data.buttons })
            } else {
              commit("SET", { type: "item_edited", items: {} });
              dispatch("marketplace/Failure", response.data.message, {
                root: true
              });
            }
            resolve(response.data);
          },
          error => {
            reject(error);
          }
        )
        .catch(err => console.log(err.response));
    });
  },
  Edit({ commit, dispatch }, {id, item}) {
    return new Promise((resolve, reject) => {
      $http(`/inventories/${id}/edit`)
        .then(
          response => {
            if (response.data.success) {
              commit("SET", { type: "item_edited", items: response.data.item })
              resolve(response.data);
            } else {
              commit("SET", { type: "item_edited", items: {} });
              dispatch("marketplace/Failure", response.data.message, {
                root: true
              });
            }
          },
          error => {
            console.log(error);
          }
        )
        .catch(err => console.log(err.response));
    });
  },
  Cancell({ commit, getters, dispatch }, id) {
    return new Promise((resolve, reject) => {
      $http
        .get(`/inventories/${id}/cancell`)
        .then(
          response => {
            if (response.data.success) {
            } else {
              commit("SET", { type: "items", items: [] });
              dispatch("marketplace/Failure", response.data.message, {
                root: true
              });
            }
            resolve(response.data);
          },
          error => {
            reject(error);
          }
        )
        .catch(err => console.log(err.response));
    });
  },
}
export default actions
