import crudGetters from '@/store/crud/getters'
const getters = {
  ...crudGetters(),
  getUserChartIn(state) {
    return state.chart_in;
  },
  getUserChartOut(state) {
    return state.chart_out;
  },
  getMessagesCount(state) {
    return state.new_messages_count;
  }
}
export default getters  
