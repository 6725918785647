export default {
  computed: {
    user(){
      return this.$store.getters['users/getItemCurrent']
    },
    new_messages_count(){
      return this.$store.getters['users/getMessagesCount']
    },
    current_shop(){
      return this.user.points && 1 == this.user.points.length ? this.user.points[0] : {} ;
    },
    loggedIn(){
      return this.user.user_name;
    },
    isOutdate(){
      return this.config.is_outdate;
    },
  },
  methods:{
    trackIdentity() {
      if (this.$mixpanel && this.user) {
        this.$mixpanel.identify(this.user.identity)
        this.$mixpanel.people.set({
          "$email": this.user.email,
          "Sign up date": this.user.created_at,
          "USER_ID": this.user.identity
        })
      }
    },
    trackEvent(event, data) {
      data = data || {}
      if (this.$mixpanel) {
        this.$mixpanel.track(event, data);
      }
    },
    goBack() {
      this.$router.go(-1);
    },
    showLogin(){
      this.$store.dispatch('account/AddItem', {type: 'auth', items: 'login' });
      this.$bvModal.show("register-popup")
    },

    getShopName(id){
      const point = this.user.points.find(obj => { return obj.id == id });
      return point.label
    },



  }
}
