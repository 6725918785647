import vPagination from 'vue-plain-pagination';

export default {
  props: ['total','currentPage','type'],
  components: {
    vPagination
  },
  data() {
    return {
      newPage: undefined,
      page: 1
    }
  },
  methods: {
    jumpToPage: function() {
      if(this.newPage){
        this.page = parseInt(this.newPage);
      }
    }
  },
  watch: {
    currentPage: {
      handler(val){
        if (val){
          this.page = val
        }
      }
    },
    page(val){
      this.$emit("update", {type:this.type, page:val})
    }
  }
}