import OfferListItem from "@/components/layout/offer-list-item/OfferListItem";
import DeleteProductPopup from "@/components/popup/DeleteProductPopup";

export default {
  props: ["items", "options", "hasImg", "hasHoverClass", "hasCheckBlock"],
  components: {
    OfferListItem,
    DeleteProductPopup
  },
  data(){
    return {
      deleteItems: []
    }
  },
  methods: {
    massDelete(ids){
      this.$emit("massDelete", ids)
    },
    deleteProduct(items){
      this.deleteItems = items
      this.$bvModal.show("delete-product-popup");
    },
  }
};
