import $http from 'axios';
import crudActions from '@/store/crud/actions'
const actions = {
  ...crudActions('subscriptions'),
  List({ commit }){
    return new Promise((resolve, reject) => {
      $http.get(`/subscriptions`).then(response => {
        if(response.data.success){
          commit('SET', {type: 'items', items: response.data.items })
        }
        resolve(response.data);
      }, error => {
          reject(error);
        }
      ).catch(err => console.log(err.response))
    })
  }
}
export default actions