export default {
  props: ["types", "place"],
  data() {
    return {};
  },
  methods: {
    setActive(type) {
      let dir = this.isDesc ? "ASC" : "DESC";
      this.$store.dispatch(`${this.place}/SetPage`, 1);
      this.$router.push({
        query: Object.assign({}, this.$route.query, {
          direction: dir,
          field: type
        })
      });
    },
    isActive(type) {
      return this.$store.getters[this.place + "/getSorting"].field == type;
    }
  },
  computed: {
    currentDirection() {
      return this.$store.getters[this.place + "/getSorting"].direction;
    },
    isDesc() {
      return "DESC" == this.currentDirection;
    },
    isAsc() {
      return "ASC" == this.currentDirection;
    }
  }
};
