import { directive as onClickaway } from 'vue-clickaway';
export default {
  props: ["options","item"],
  directives: {
    onClickaway: onClickaway,
  },
  data() {
    return {
      menuShow: false,
    }
  },
  methods: {
    click(option){
      this.$emit("click", {key: option.key, item: this.item} )
    },
    openMenu(e) {
      this.menuShow = true;
    },
    closeMenu() {
      this.menuShow = false;
    },
  },
}