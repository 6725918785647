import $http from "axios";
import crudActions from '@/store/crud/actions'
const actions = {
  ...crudActions('leads'),
  SetKanbanItems({ commit }, item) {
    commit('SET', { type: 'kanban_items', items: item })
  },
  AddMessage({ commit }, item ){
    return new Promise((resolve, reject) => {
      let form = new FormData();
      if (item.file) { form.append('file', item.file ) }
      for (const [key, value] of Object.entries(item)) { form.append(key, value ) }

      $http.post(`/leads/${item.id}/add_message`, form, { headers: { 'Content-Type': 'multipart/form-data' } }).then(response => {
        commit('SET', {type: 'item_updated',items: true })
        resolve(response.data);
      }, error => {
          reject(error);
        }
      ).catch(err => console.log(err.response))
    })
  },
  List({ commit, getters, dispatch }, isKanban) {
    let filters = getters.getFilters;
    let send_filters = {};
    for (let key of Object.keys(filters)) {
      if (filters[key] && typeof filters[key] == "object") {
        send_filters[key] = filters[key].id;
      } else {
        send_filters[key] = filters[key];
      }
    }
    let params = {
      filters: send_filters,
      page: getters.getPage,
      init_kanban_table: isKanban || false
    };
    return new Promise((resolve, reject) => {
      $http
        .get(`/leads`, { params: params })
        .then(
          (response) => {
            commit("SET", { type: "items", items: [] });
            if (response.data.success) {
              if (!isKanban){
                commit("SET", { type: "items_current", items: response.data.items });
                commit("SET", {
                  type: "total",
                  items: response.data.total,
                });
              }
            } else {
              commit("SET", { type: "items_current", items: [] });
              dispatch("marketplace/Failure", response.data.message, {
                root: true,
              });
            }
            resolve(response.data);
          },
          (error) => {
            reject(error);
          }
        )
        .catch((err) => console.log(err.response));
    });
  },
  AddFile({ commit, getters }, item) {
    return new Promise((resolve, reject) => { 
      let form = new FormData();
      if (item.files) {
        for (var i = item.files.length - 1; i >= 0; i--) {
          form.append(`attachments['${i}']`, item.files[i]);
        }
      }
      for (const [key, value] of Object.entries(item)) {
        form.append(key, value);
      }
      $http
        .put(`/leads/${item.id}/attach`, form, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then(
          (response) => {
            commit("SET", { type: "item_saved", items: response.data.success });
            resolve(response.data);
          },
          (error) => {
            reject(error);
          }
        )
        .catch((err) => console.log(err.response));
    });
  },
  SetStatus({ commit, dispatch }, {id, status_id}) {
    return new Promise((resolve, reject) => {
      $http.put(`/leads/${id}/set_status`, {status_id: status_id})
        .then(
          (response) => {
            commit("SET", { type: "item_saved", items: response.data });
            if (response.data.success) {
              resolve(response.data);
            } else {
              dispatch("marketplace/Failure", response.data.message, {
                root: true,
              });
            }
          },
          (error) => {
            console.log(error);
          }
        )
        .catch((err) => console.log(err.response));
    });
  },
  CreateOrder({ commit, dispatch }, id) {
    return new Promise((resolve, reject) => {
      $http(`/leads/${id}/create_order`)
        .then(
          (response) => {
            if (response.data.success) {
              commit("SET", { type: "item_edited", items: {} });
              resolve(response.data);
            } else {
              commit("SET", { type: "item_edited", items: {} });
              dispatch("marketplace/Failure", response.data.message, {
                root: true,
              });
            }
          },
          (error) => {
            console.log(error);
          }
        )
        .catch((err) => console.log(err.response));
    });
  },
  UpdateNote({ commit, dispatch }, { id, params }) {
    return new Promise((resolve, reject) => {
      $http
        .put(`/leads/${id}/note`, {
          items: params,
        })
        .then(
          (response) => {
            if (response.data.success) {
              commit("SET", {
                type: "item_saved",
                items: response.data.success,
              });
              resolve(response.data);
            } else {
              commit("SET", {
                type: "item_saved",
                items: {},
              });
              dispatch("marketplace/Failure", response.data.message, {
                root: true,
              });
            }
          },
          (error) => {
            console.log(error);
          }
        )
        .catch((err) => console.log(err.response));
    });
  },
  SaveActivity({ commit, dispatch }, { id, params }) {
    return new Promise((resolve, reject) => {
      $http
        .put(`/leads/${id}/activity`, {
          items: params,
        })
        .then(
          (response) => {
            if (response.data.success) {
              commit("SET", {
                type: "item_saved",
                items: response.data.success,
              });
              resolve(response.data);
            } else {
              commit("SET", {
                type: "item_saved",
                items: {},
              });
              dispatch("marketplace/Failure", response.data.message, {
                root: true,
              });
            }
          },
          (error) => {
            console.log(error);
          }
        )
        .catch((err) => console.log(err.response));
    });
  },
  New({ commit, dispatch }) {
    return new Promise((resolve, reject) => {
      $http
        .get(`/leads/new`)
        .then(
          (response) => {
            if (response.data.success) {
              commit("SET", {
                type: "item_new",
                items: response.data.item,
              });
            } else {
              commit("SET", { type: "item_new", items: {} });
              dispatch("marketplace/Failure", response.data.message, {
                root: true,
              });
            }
            resolve(response.data);
          },
          (error) => {
            reject(error);
          }
        )
        .catch((err) => console.log(err.response));
    });
  },
  Call({ commit, dispatch }, id) {
    return new Promise((resolve, reject) => {
      $http
        .get(`/leads/${id}/call`)
        .then(
          (response) => {
            if (response.data.success) {
              commit("SET", {
                type: "item_called",
                items: response.data.success,
              });
            } else {
              commit("SET", { type: "item_called", items: response.data.success });
              dispatch("marketplace/Failure", response.data.message, {
                root: true,
              });
            }
            resolve(response.data);
          },
          (error) => {
            reject(error);
          }
        )
        .catch((err) => console.log(err.response));
    });
  },
  CreateClient({ commit, dispatch }, id) {
    return new Promise((resolve, reject) => {
      $http(`/leads/${id}/create_client`)
        .then(
          (response) => {
            if (response.data.success) {
              commit("SET", { type: "item_edited", items: response.data });
              resolve(response.data);
            } else {
              dispatch("marketplace/Failure", response.data.message, {
                root: true,
              });
            }
          },
          (error) => {
            console.log(error);
          }
        )
        .catch((err) => console.log(err.response));
    });
  },
  CloseLead({ commit, dispatch }, {id, reason_id}) {
    return new Promise((resolve, reject) => {
      console.log(reason_id)
      $http(`/leads/${id}/close_lead`, {params: {reason_id: reason_id}})
        .then(
          (response) => {
            if (response.data.success) {
              commit("SET", { type: "item_edited", items: response.data });
              resolve(response.data);
            } else {
              dispatch("marketplace/Failure", response.data.message, {
                root: true,
              });
            }
          },
          (error) => {
            console.log(error);
          }
        )
        .catch((err) => console.log(err.response));
    });
  },
  Update({ commit }, item) {
    return new Promise((resolve, reject) => {
      $http
        .put(`/leads/${item.id}`, item)
        .then(
          (response) => {
            commit("SET", { type: "item_saved", items: response.data.success });
            if (response.data.success) {
            } else {
              dispatch("marketplace/Failure", response.data.message, {
                root: true,
              });
            }
            resolve(response.data);
          },
          (error) => {
            reject(error);
          }
        )
        .catch((err) => console.log(err.response));
    });
  },
  Finish({ commit, dispatch }, id) {
    return new Promise((resolve, reject) => {
      $http(`/leads/${id}/finish`)
        .then(
          (response) => {
            if (response.data.success) {
              commit("SET", { type: "item_edited", items: {} });
              resolve(response.data);
            } else {
              commit("SET", { type: "item_edited", items: {} });
              dispatch("marketplace/Failure", response.data.message, {
                root: true,
              });
            }
          },
          (error) => {
            console.log(error);
          }
        )
        .catch((err) => console.log(err.response));
    });
  },
}
export default actions
