const crudState = () => {
  return {
    item: {},
    item_edited: {},
    item_current: {},
    items: [],
    last_items: [],
    total: 1,
    page: 1,
    item_saved: false,
    sorting: {
      direction: "DESC",
      field: "updated_at"
    },
    active_type: "all",
    filters: {
      title:"",
    }
  }
}

export default crudState
