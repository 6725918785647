import $http from "axios";

export default {
  UpdateFullCart(
    { commit, getters, rootGetters },
    { product, quantity, additional_data }
  ) {
    let items = JSON.parse(JSON.stringify(getters["getCartItems"]));
    let point_id = product.point_id || getters["getActivePoint"];

    if (!point_id && !rootGetters["users/loggedIn"]) {
      point_id = window.gon.config.guest_point_id;
    }

    let depot_id = product.depot_id;
    if (!point_id || !depot_id) {
      return;
    }
    items[point_id] = items[point_id] || {};
    items[point_id][depot_id] = items[point_id][depot_id] || {};
    if (quantity) {
      items[point_id][depot_id][product.id] = {
        item: product,
        quantity: quantity,
        product_price: product.product_price,
        price: product.price,
        unit: product.selected_unit.unit,
        unit_measue: product.selected_unit.unit_measure,
        selected_unit: product.selected_unit,
        payment_type: product.payment_type ? product.payment_type.id : "cash",
        payment_parts: product.payment_parts,
        additional_data: additional_data || {},
      };
    } else {
      delete items[point_id][depot_id][product.id];
    }
    // if (!Object.keys(items[point_id][depot_id]).length){
    //   delete items[point_id]
    // }
    commit("SET_LOCALSTORE", { type: "cart_items", items: items });
  },
  Create({ commit, getters }, { user_id, id }) {
    return new Promise((resolve, reject) => {
      let cart = getters["getApiPayload"];
      if (getters["getId"]) {
        cart["id"] = getters["getId"];
      }
      if (id) {
        cart["id"] = id;
      }
      if (user_id) {
        cart["user_id"] = user_id;
      }
      $http
        .post(`/cart`, { cart: cart })
        .then(
          (response) => {
            if (response.data.success) {
              commit("SET_LOCALSTORE", {
                type: "cart_id",
                items: response.data.id,
              });
            } else {
              commit("SET_LOCALSTORE", { type: "cart_id", items: 0 });
            }
            resolve(response.data);
          },
          (error) => {
            reject(error);
          }
        )
        .catch((err) => console.log(err.response));
    });
  },

  AddFile({ commit, getters }, item) {
    return new Promise((resolve, reject) => {
      let form = new FormData();
      if (item.files) {
        for (var i = item.files.length - 1; i >= 0; i--) {
          form.append(`attachments['${i}']`, item.files[i]);
        }
      }
      for (const [key, value] of Object.entries(item)) {
        form.append(key, value);
      }
      $http
        .put(`/cart/${item.id}/part/${item.part_id}`, form, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then(
          (response) => {
            commit("SET", { type: "item_saved", items: response.data.success });
            resolve(response.data);
          },
          (error) => {
            reject(error);
          }
        )
        .catch((err) => console.log(err.response));
    });
  },
  SetOrderStatus({ commit, getters }, item) {
    return new Promise((resolve, reject) => {
      $http
        .put(`/cart/${item.id}/set_status`, item)
        .then(
          (response) => {
            commit("SET", { type: "item_saved", items: response.data.success });
            resolve(response.data);
          },
          (error) => {
            reject(error);
          }
        )
        .catch((err) => console.log(err.response));
    });
  },
  CreatePackings({ commit, getters }, item) {
    return new Promise((resolve, reject) => {
      $http
        .post(`/packings/`, item)
        .then(
          (response) => {
            commit("SET", { type: "item_saved", items: response.data.success });
            resolve(response.data);
          },
          (error) => {
            reject(error);
          }
        )
        .catch((err) => console.log(err.response));
    });
  },
  UpdateDeliveryDetails({ commit }, item) {
    return new Promise((resolve, reject) => {
      $http
        .post(`/cart/update_delivery_details/`, item)
        .then(
          (response) => {
            commit("SET", { type: "item_saved", items: response.data.success });
            resolve(response.data);
          },
          (error) => {
            reject(error);
          }
        )
        .catch((err) => console.log(err.response));
    });
  },
  SavePaydesk({ commit, getters }, item) {
    return new Promise((resolve, reject) => {
      $http
        .post(`/cart/paydesk/`, item)
        .then(
          (response) => {
            commit("SET", { type: "item_saved", items: response.data.success });
            resolve(response.data);
          },
          (error) => {
            reject(error);
          }
        )
        .catch((err) => console.log(err.response));
    });
  },
  UpdatePaydesk( { commit, getters, rootGetters }, items ) {
    const newItems = {}
    const quantity = {}
    for(let product of items) {
      quantity[product.id] = quantity[product.id] || 0
      quantity[product.id] += Number(product.quantity)
      newItems[product.id] = {
        item: product,
        quantity: quantity[product.id],
        price: product.price,
        unit: product.selected_unit.unit,
        unit_measue: product.selected_unit.unit_measure,
        selected_unit: product.selected_unit,
        payment_type: product.payment_type ? product.payment_type.id : "cash",
        payment_parts: product.payment_parts
      };
    }
    commit("SET", { type: "paydesk_items", items: newItems });
  },
  Update({ commit, getters }, { id, cart }) {
    return new Promise((resolve, reject) => {
      let update_cart = cart || getters["getApiPayload"];
      $http
        .put(`/cart/${id || getters["getId"]}`, {
          cart: update_cart,
        })
        .then(
          (response) => {
            if (response.data.success) {
              commit("SET_LOCALSTORE", {
                type: "cart_id",
                items: response.data.id,
              });
            } else {
              commit("SET_LOCALSTORE", {
                type: "cart_id",
                items: getters["getId"],
              });
            }
            resolve(response.data);
          },
          (error) => {
            reject(error);
          }
        )
        .catch((err) => console.log(err.response));
    });
  },
  Reset({ commit, getters }) {
    commit("SET_LOCALSTORE", { type: "cart_id", items: 0 });
    commit("SET_LOCALSTORE", { type: "items", items: {} });
    commit("SET_LOCALSTORE", { type: "cart_items", items: {} });
  },

  Confirm({ commit, getters }, payload) {
    return new Promise((resolve, reject) => {
      $http
        .post(`/cart/${payload[0].id || getters["getId"]}/confirm`, {
          cart: payload,
        })
        .then(
          (response) => {
            if (response.data.success) {
              resolve(response.data);
              commit("SET", { type: "item_current", items: response.data });
            } else {
              commit("SET", {
                type: "item_current",
                items: getters["getItemCurrent"],
              });
            }
            resolve(response.data);
          },
          (error) => {
            reject(error);
          }
        )
        .catch((err) => console.log(err.response));
    });
  },
  Reject({ commit, getters }, payload) {
    return new Promise((resolve, reject) => {
      $http
        .post(`/cart/${payload.id || getters["getId"]}/reject`, payload)
        .then(
          (response) => {
            if (response.data.success) {
              resolve(response.data);
              commit("SET", { type: "item_current", items: response.data });
            } else {
              commit("SET", {
                type: "item_current",
                items: getters["getItemCurrent"],
              });
            }
            resolve(response.data);
          },
          (error) => {
            reject(error);
          }
        )
        .catch((err) => console.log(err.response));
    });
  },
  FindNotConfirmed({ commit, dispatch }) {
    return new Promise((resolve, reject) => {
      $http
        .get("/cart/not_confirmed")
        .then(
          (response) => {
            if (response.data.success && response.data.id) {
              commit("SET_LOCALSTORE", {
                type: "cart_id",
                items: response.data.id,
              });
              dispatch("Show", {}).then((response) => {
                if (response.success) {
                  let cart_items = {};
                  response.item.parts.forEach((part) => {
                    cart_items[part.point_id] = {};
                    cart_items[part.point_id][part.id] = {};
                    part.items.forEach((item) => {
                      cart_items[part.point_id][part.id][item.id] = {
                        payment_type: item.payment_type.id,
                        price: item.price,
                        quantity: item.quantity,
                        selected_unit: item.selected_unit,
                        item: {
                          bought_quantity: item.quantity,
                          prices: item.prices,
                          title: item.title,
                        },
                      };
                    });
                  });
                  commit("SET_LOCALSTORE", {
                    type: "cart_items",
                    items: cart_items,
                  });
                }
              });
            } else {
              commit("SET_LOCALSTORE", { type: "cart_id", items: 0 });
            }
            resolve(response.data);
          },
          (error) => {
            reject(error);
          }
        )
        .catch((err) => console.log(err));
    });
  },
  Template({ commit, getters }, title) {
    return new Promise((resolve, reject) => {
      $http
        .post(`/cart/${getters["getId"]}/template`, title)
        .then(
          (response) => {
            if (response.data.success) {
            } else {
              commit("SET", {
                type: "item_current",
                items: getters["getItemCurrent"],
              });
            }
            resolve(response.data);
          },
          (error) => {
            reject(error);
          }
        )
        .catch((err) => console.log(err.response));
    });
  },
  List({ commit, getters }) {
    let filters = getters.getFilters;
    let send_filters = {};
    for (let key of Object.keys(filters)) {
      if (filters[key] && typeof filters[key] == "object" && !Array.isArray(filters[key])) {
        send_filters[key] = filters[key].id;
      } else {
        send_filters[key] = filters[key];
      }
    }
    let params = {
      filter: send_filters,
      page: getters.getPage,
    };
    return new Promise((resolve, reject) => {
      $http
        .get(`/cart`, { params: params })
        .then(
          (response) => {
            if (response.data.success) {
              commit("SET", { type: "items", items: response.data.items });
              commit("SET", { type: "total", items: response.data.total });
              commit("SET", { type: "buttons", items: response.data.buttons });
            } else {
              commit("SET", { type: "items", items: [] });
            }
            resolve(response.data);
          },
          (error) => {
            reject(error);
          }
        )
        .catch((err) => console.log(err.response));
    });
  },
  New({ commit, dispatch }) {
    return new Promise((resolve, reject) => {
      $http
        .get(`/cart/new`)
        .then(
          (response) => {
            if (response.data.success) {
              commit("SET", {
                type: "item_new",
                items: response.data.item,
              });
            } else {
              commit("SET", { type: "item_new", items: {} });
              dispatch("marketplace/Failure", response.data.message, {
                root: true,
              });
            }
            resolve(response.data);
          },
          (error) => {
            reject(error);
          }
        )
        .catch((err) => console.log(err.response));
    });
  },
  Show({ commit, getters }, { id, part }) {
    const endpoint =
      id && part ? `/cart/${id}/part/${part}` : `/cart/${getters["getId"]}`;
    return new Promise((resolve, reject) => {
      $http
        .get(endpoint)
        .then(
          (response) => {
            if (response.data.success) {
              commit("SET", { type: "item_current", items: response.data });
            } else {
              commit("SET", {
                type: "item_current",
                items: { item: { parts: [] }, points: [] },
              });
            }
            resolve(response.data);
          },
          (error) => {
            reject(error);
          }
        )
        .catch((err) => console.log(err.response));
    });
  },
  SetFilters({ commit }, item) {
    commit("SET", { type: "filters", items: item });
  },
  SetPage({ commit }, item) {
    commit("SET", { type: "page", items: item });
  },
  setCart({ commit }, item) {
    commit("SET", { type: "item_unconfirmed", items: item });
  },
  setActivePoint({ commit }, item) {
    commit("SET_SESSIONSTORE", { type: "active_point", items: item });
  },
  setActiveCustomer({ commit }, item) {
    commit("SET_SESSIONSTORE", { type: "active_customer", items: item });
  },
  setActiveCompany({ commit }, item) {
    commit("SET_SESSIONSTORE", { type: "active_company", items: item });
  },
  SetItemCurrent({ commit }, item) {
    commit("SET", { type: "item_current", items: item });
  },
};
