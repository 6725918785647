import $http from "axios";
import crudActions from '@/store/crud/actions'

const user_defaults = {
  config:{
    points: [] 
  },
  is_client: true,
  layout: {
    can_buy: true
  },
}

const actions = {
  ...crudActions('members'),
  CheckMessages({ commit }) {
    return new Promise((resolve, reject) => {
      $http
        .get(`/new_messages_count`)
        .then(
          (response) => {
            if (response.data.success) {
              commit("SET", {
                type: "new_messages_count",
                items: response.data.count,
              });
            } else {
              commit("SET", { type: "item_current", items: user_defaults });
              commit("SET_LOCALSTORE", { type: "user", items: {} });
            }
          },
          (error) => {
            reject(error);
          }
        )
        .catch((err) => console.log(err.response));
    });
  },

  Login({ commit, dispatch }, params) {
    return new Promise((resolve, reject) => {
      $http
        .post(`/sign_in`, params)
        .then((response) => {
          commit("SET", {
            type: "item_current",
            items: response.data.item || user_defaults,
          });
          if (response.data.success) {
            commit("SET_LOCALSTORE", {
              type: "user",
              items: response.data.item || user_defaults,
            });
            resolve(response.data);
          } else {
            dispatch("marketplace/Failure", response.data.message, {
              root: true,
            });
          }
        })
        .catch((err) => console.log(err));
    });
  },
  async RefreshSession({ commit, dispatch }) {
    return await new Promise((resolve, reject) => {
      $http
        .get(`/refresh_session`)
        .then((response) => {
          commit("SET", {
            type: "item_current",
            items: response.data ? response.data.item : user_defaults,
          });
          if (response.data && response.data.success) {
            commit("SET_LOCALSTORE", {
              type: "user",
              items: response.data.item || user_defaults,
            });
            resolve(response.data);
          } else {
            commit("SET", {
              type: "item_current",
              items: user_defaults
            });
            commit("SET_LOCALSTORE", {
              type: "user",
              items: user_defaults
            });
          }
        })
        .catch((err) => console.log(err));
    });
  },
  Register({ commit, dispatch }, params) {
    return new Promise((resolve, reject) => {
      $http
        .post(`/sign_up`, params)
        .then((response) => {
          commit("SET", {
            type: "item_current",
            items: response.data.user || user_defaults,
          });
          if (response.data.success) {
            dispatch("marketplace/Success", response.data.message, {
              root: true,
            });
          } else {
            dispatch("marketplace/Failure", response.data.message, {
              root: true,
            });
          }
        })
        .catch((err) => console.log(err));
    });
  },
  Remind({ commit }, params) {
    return new Promise((resolve, reject) => {
      $http
        .post(`/remind/`, params)
        .then(
          (response) => {
            commit("SET", { type: "remind", items: response.data });
            resolve(response.data);
          },
          (error) => {
            reject(error);
          }
        )
        .catch((err) => console.log(err.response));
    });
  },
  List({ commit, dispatch }, with_fake) {
    with_fake = with_fake || false;
    return new Promise((resolve, reject) => {
      $http
        .get(`/members`, { params: { with_fake: with_fake } })
        .then(
          (response) => {
            if (response.data.success) {
              commit("SET", { type: "items", items: response.data.items });
            } else {
              commit("SET", { type: "items", items: [] });
              dispatch("marketplace/Failure", response.data.message, {
                root: true,
              });
            }
            resolve(response.data);
          },
          (error) => {
            reject(error);
          }
        )
        .catch((err) => console.log(err.response));
    });
  },
  ChangePassword({ commit }, params) {
    return new Promise((resolve, reject) => {
      $http
        .post(`/restore/${params.token}`, params)
        .then(
          (response) => {
            commit("SET", {
              type: "change_password",
              items: response.data.success,
            });
            resolve(response.data);
          },
          (error) => {
            reject(error);
          }
        )
        .catch((err) => console.log(err.response));
    });
  },
  Show({ commit, getters }, id) {
    return new Promise((resolve, reject) => {
      $http
        .get(`/members/${id}`)
        .then(
          (response) => {
            commit("SET", {
              type: "item_current",
              items: getters.getItemCurrent,
            });
            if (response.data.success) {
              commit("SET", {
                type: "item_current",
                items: response.data.item,
              });
              commit("SET", {
                type: "chart_in",
                items: response.data.item.chart_in,
              });
              commit("SET", {
                type: "chart_out",
                items: response.data.item.chart_out,
              });
            }
            resolve(response.data);
          },
          (error) => {
            reject(error);
          }
        )
        .catch((err) => console.log(err.response));
    });
  }
}
export default actions