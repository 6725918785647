import crudActions from '@/store/crud/actions'
import $http from "axios";

const actions = {
  ...crudActions('setting', false),
  Contacts({ commit, dispatch }) {
    return new Promise((resolve, reject) => {
      $http(`/setting/contacts`)
        .then(
          response => {
            if (response.data.success) {
              commit("SET", { type: "contacts", items: response.data.item });
            } else {
              commit("SET", { type: "contacts", items: {} });
              dispatch("marketplace/Failure", response.data.message, {
                root: true
              });
            }
            resolve(response.data);
          },
          error => {
            console.log(error);
          }
        )
        .catch(err => console.log(err.response));
    });
  },
  Save({ commit, dispatch }, item) {
    return new Promise((resolve, reject) => {
      $http.post(`/setting`, item)
        .then(
          response => {
            commit("SET", { type: "item_saved", items: response.data.success });
            if (response.data.success) {
            } else {
              dispatch("marketplace/Failure", response.data.message, {
                root: true
              });
            }
            resolve(response.data);
          },
          error => {
            reject(error);
          }
        )
        .catch(err => console.log(err.response));
    });
  },
  ActivateModule({ commit, dispatch }, code) {
    return new Promise((resolve, reject) => {
      $http.post(`/setting/activate_module`, {code: code})
        .then(
          response => {
            commit("SET", { type: "item_saved", items: response.data.success });
            if (response.data.success) {
            } else {
              dispatch("marketplace/Failure", response.data.message, {
                root: true
              });
            }
            resolve(response.data);
          },
          error => {
            reject(error);
          }
        )
        .catch(err => console.log(err.response));
    });
  },
  New({ commit, dispatch }) {
    return new Promise((resolve, reject) => {
      $http
        .get(`/setting/new`)
        .then(
          (response) => {
            if (response.data.success) {
              commit("SET", {
                type: "item_new",
                items: response.data.item,
              });
            } else {
              commit("SET", { type: "item_new", items: {} });
              dispatch("marketplace/Failure", response.data.message, {
                root: true,
              });
            }
            resolve(response.data);
          },
          (error) => {
            reject(error);
          }
        )
        .catch((err) => console.log(err.response));
    });
  },
  ModulesAvailable({ commit, dispatch }) {
    return new Promise((resolve, reject) => {
      $http
        .get(`/setting/modules_available`)
        .then(
          (response) => {
            if (response.data.success) {
              commit("SET", {
                type: "modules_available",
                items: response.data.item,
              });
            } else {
              commit("SET", { type: "modules_available", items: {} });
              dispatch("marketplace/Failure", response.data.message, {
                root: true,
              });
            }
            resolve(response.data);
          },
          (error) => {
            reject(error);
          }
        )
        .catch((err) => console.log(err.response));
    });
  },
  ContactSave({ commit,dispatch }, item ) {
    return new Promise((resolve, reject) => {
      $http.post(`/setting/save_contact`, item).then(response => {
        commit('SET', { type: 'item_saved', items: response.data.success })
        if (!response.data.success) {
          dispatch('marketplace/Failure', response.data.message, { root: true })
        }
        resolve(response.data);
      }, error => {
          reject(error);
        }
      ).catch(err => console.log(err.response))
    })
  }
}
export default actions
