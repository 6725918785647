import axios from 'axios'
import router from '@/apps/marketplace/router'

const remote = axios.create({
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Credentials': true,
    'Access-Control-Allow-Methods': 'GET, POST',
    'Accept': 'application/json, text/javascript, */*; q=0.01',
    'Content-Type': 'application/json; charset=UTF-8',
    'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute("content")
  }
})
axios.defaults.baseURL = `/${window.gon.config.locale}`

remote.interceptors.response.use(response => {
  if (response.data && response.data.status === 401) {
    window.localStorage.clear();
    if (!router.app._route.meta.withoutAuth) {
      window.location.href = '/login'
    }
  }
  return response
}, error => {
  if (error.response.status === 401) {
    window.localStorage.clear();
    if (!router.app._route.meta.withoutAuth) {
      window.location.href = '/login'
    }
  }
  return error;
})

const api = remote

export default api