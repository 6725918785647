export default {
  props:["item"],
  data() {
    return { checked: this.value }
  },
  methods:{
    click(){
      this.checked = !this.checked
    }
  },
  
  watch: {
    checked(val) {
      this.$emit('input', val);
    },
    value(val) {
      this.checked = val
    }
  }
}