import crudActions from '@/store/crud/actions'
const actions = {
  ...crudActions('polygons'),
  ActiveCategory({ commit },item){
    commit('SET', { type: 'active_category', items: item })
  },
  ClearActiveCategory({ commit },item){
    commit('SET', { type: 'active_category', items: {} })
  },
  setActiveType({ commit },item){
    commit('SET', { type: 'active_type', items: item })
  },
  SetAssign({ commit },item){
    commit('SET', { type: 'choose_is_active', items: item })
  }
}
export default actions