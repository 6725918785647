export default {
  computed:{
    previous(){
      return this.breadcrumbs.length ? this.breadcrumbs[(this.breadcrumbs.length - 1)] : null;
    },
    back(){
      this.$router.push({path: this.previous.path, params: {back: true} })
      this.$store.dispatch("marketplace/RemoveBreadcrumps")
    },
    current(){
      return { title: this.$route.meta.breadcrump }
    },
    breadcrumbs(){
      return this.$store.getters['marketplace/breadcrumbs'] || [];
    }
  },
}