import Vue from "vue";
import Router from "vue-router";
import Marketplace from "@/views/Marketplace";
import store from "@/store/index";
import i18n from "@/mixins/i18n";

Vue.use(Router);
let router = new Router({
  mode: 'history',
  routes: [
    // Marketplace
    {
      path: "/paydesk",
      name: "paydesk",
      // meta: {
      //   reload: true,
      //   breadcrump: i18n.methods.translate("common.home"),
      // },
      component: () => import("@/views/Paydesk.vue"),
    },

    {
      path: "/",
      name: "marketplace",
      component: Marketplace,
      meta: {
        productType: "",
        reload: true,
        withoutAuth: true,
        breadcrump: i18n.methods.translate("common.home"),
      },
    },
    {
      path: "/email",
      name: "add_mailings",
      props: true,
      meta: {
        // reload: true,
        // breadcrump: i18n.methods.translate('common.cart')
      },
      component: () => import("@/views/cabinet/Email.vue"),
    },
    {
      path: "/email/:id",
      name: "edit_mailings",
      props: true,
      meta: {
        // reload: true,
        // breadcrump: i18n.methods.translate('common.cart')
      },
      component: () => import("@/views/cabinet/Email.vue"),
    },
    {
      path: "/product/:id",
      name: "product",
      props: true,
      meta: {
        reload: true,
        withoutAuth: true,
        productType: "products",
        breadcrump: i18n.methods.translate("common.offer"),
      },
      component: () => import("@/views/Product-details-offer.vue"),
    },
    {
      path: "/my-offer/:id",
      name: "edit-offer",
      props: true,
      meta: {
        additional_offer: true,
        requiresAuth: true,
        breadcrump: i18n.methods.translate("common.chats"),
      },
      component: () => import("@/views/Add-additional-offer.vue"),
    },
    {
      path: "/my-product/:id",
      name: "edit-product",
      props: true,
      meta: {
        requiresAuth: true,
        breadcrump: i18n.methods.translate("common.chats"),
      },
      component: () => import("@/views/Add-new-offer.vue"),
    },
    {
      path: "/add-subproduct/:id",
      name: "add-new-subproduct",
      props: true,
      meta: {
        requiresAuth: true,
        breadcrump: i18n.methods.translate("common.chats"),
      },
      component: () => import("@/views/Add-sub-product.vue"),
    },
    {
      path: "/add-suboffer/:id",
      name: "add-new-suboffer",
      props: true,
      meta: {
        additional_offer: true,
        requiresAuth: true,
        breadcrump: i18n.methods.translate("common.chats"),
      },
      component: () => import("@/views/Add-sub-offer.vue"),
    },
    
    {
      path: "/my-cabinet",
      name: "cabinet",
      meta: {
        requiresAuth: true,
        // breadcrump: i18n.methods.translate("common.chats"),
      },
      component: () => import("@/views/cabinet/Cabinet.vue"),
      children: [
        {
          path: "/landing-builder",
          name: "landing_builder",
          props: true,
          meta: {
            requiresAuth: true,
            // breadcrump: i18n.methods.translate("common.edit_info"),
          },
          component: () => import("@/views/LandingBuilder.vue"),
        },
        {
          path: "/sales-funnel",
          name: "sales_funnel",
          props: true,
          meta: {
            requiresAuth: true,
            // breadcrump: i18n.methods.translate("common.edit_info"),
          },
          component: () => import("@/views/SalesFunnel.vue"),
        },
        {
          path: "/dashboard",
          name: "dashboard",
          props: true,
          meta: {
            requiresAuth: true,
            // breadcrump: i18n.methods.translate("common.edit_info"),
          },
          component: () => import("@/views/Dashboard.vue"),
        },
        {
          path: "/profile/edit/:id",
          name: "edit-profile",
          props: true,
          meta: {
            requiresAuth: true,
            breadcrump: i18n.methods.translate("common.edit_info"),
          },
          component: () => import("@/views/cabinet/Edit-profile.vue"),
        },
        {
          path: "/",
          name: "profile",
          meta: {
            // requiresAuth: true,
            breadcrump: i18n.methods.translate("common.my_profile"),
          },
          component: () => import("@/views/cabinet/User-profile.vue"),
        },
        {
          path: "/cart",
          name: "cart",
          meta: {
            withoutAuth: true,
            // reload: true,
            // breadcrump: i18n.methods.translate('common.cart')
          },
          component: () => import("@/views/Cart.vue"),
        },
        {
          path: "/cart/:id/:part",
          name: "cart-details",
          props: true,
          meta: {
            // reload: true,
            // breadcrump: i18n.methods.translate('common.cart')
          },
          component: () => import("@/views/Cart.vue"),
        },
        {
          path: "/my-tasks",
          name: "crm_tasks",
          props: true,
          meta: {
            // reload: true,
            // breadcrump: i18n.methods.translate('common.cart')
          },
          component: () => import("@/views/cabinet/Tasks.vue"),
        },
        {
          path: "/add-task",
          name: "add_task",
          props: true,
          meta: {
            // reload: true,
            // breadcrump: i18n.methods.translate('common.cart')
          },
          component: () => import("@/views/cabinet/Add-task-form.vue"),
        },
        {
          path: "/task/:id",
          name: "edit_task",
          props: true,
          meta: {
            // reload: true,
            // breadcrump: i18n.methods.translate('common.cart')
          },
          component: () => import("@/views/cabinet/Add-task-form.vue"),
        },
        {
          path: "/about-page",
          name: "my_about",
          props: true,
          meta: {
            // reload: true,
            // breadcrump: i18n.methods.translate('common.cart')
          },
          component: () => import("@/views/cabinet/About.vue"),
        },
        {
          path: "/add-about",
          name: "add_about",
          props: true,
          meta: {
            type: "about",
          },
          component: () => import("@/views/cabinet/ContentForm.vue"),
        },
        {
          path: "/my-about/:id",
          name: "edit_about",
          props: true,
          meta: {
            type: "about",
          },
          component: () => import("@/views/cabinet/ContentForm.vue"),
        },
        
        {
          path: "/my-articles",
          name: "my_articles",
          props: true,
          meta: {
            // reload: true,
            // breadcrump: i18n.methods.translate('common.cart')
          },
          component: () => import("@/views/cabinet/Articles.vue"),
        },
        {
          path: "/add-articles",
          name: "add_articles",
          props: true,
          meta: {
            type: "articles",
          },
          component: () => import("@/views/cabinet/ContentForm.vue"),
        },
        {
          path: "/my-articles/:id",
          name: "edit_articles",
          props: true,
          meta: {
            type: "articles",
          },
          component: () => import("@/views/cabinet/ContentForm.vue"),
        },
        {
          path: "/my-faq",
          name: "my_faq",
          props: true,
          meta: {
            // reload: true,
            // breadcrump: i18n.methods.translate('common.cart')
          },
          component: () => import("@/views/cabinet/Faq.vue"),
        },
        {
          path: "/add-faq",
          name: "add_faq",
          props: true,
          meta: {
            type: "faq",
          },
          component: () => import("@/views/cabinet/ContentForm.vue"),
        },
        {
          path: "/my-faq/:id",
          name: "edit_faq",
          props: true,
          meta: {
            type: "faq",
          },
          component: () => import("@/views/cabinet/ContentForm.vue"),
        },
        {
          path: "/shop-products",
          name: "exhibition_products",
          props: true,
          meta: {
            // reload: true,
            // breadcrump: i18n.methods.translate('common.cart')
          },
          component: () => import("@/views/cabinet/ExhibitionProducts.vue"),
        },
        {
          path: "/add-shop-products",
          name: "add_exhibition",
          props: true,
          meta: {
            // reload: true,
            // breadcrump: i18n.methods.translate('common.cart')
          },
          component: () => import("@/views/cabinet/ExhibitionProductForm.vue"),
        },
        {
          path: "/shop-products/:id",
          name: "edit_exhibition",
          props: true,
          meta: {
            // reload: true,
            // breadcrump: i18n.methods.translate('common.cart')
          },
          component: () => import("@/views/cabinet/ExhibitionProductForm.vue"),
        },
        {
          path: "/my-statuses",
          name: "crm_statuses",
          props: true,
          meta: {
            // reload: true,
            // breadcrump: i18n.methods.translate('common.cart')
          },
          component: () => import("@/views/cabinet/Statuses.vue"),
        },
        {
          path: "/add-status",
          name: "add_status",
          props: true,
          meta: {
            // reload: true,
            // breadcrump: i18n.methods.translate('common.cart')
          },
          component: () => import("@/views/cabinet/StatusForm.vue"),
        },
        {
          path: "/status/:id",
          name: "edit_status",
          props: true,
          meta: {
            // reload: true,
            // breadcrump: i18n.methods.translate('common.cart')
          },
          component: () => import("@/views/cabinet/StatusForm.vue"),
        },
        {
          path: "/my-groups",
          name: "crm_groups",
          props: true,
          meta: {},
          component: () => import("@/views/cabinet/Groups.vue"),
        },
        {
          path: "/add-group",
          name: "add_group",
          props: true,
          meta: {},
          component: () => import("@/views/cabinet/GroupForm.vue"),
        },
        {
          path: "/group/:id",
          name: "edit_group",
          props: true,
          meta: {},
          component: () => import("@/views/cabinet/GroupForm.vue"),
        },
        {
          path: "/decline-statuses",
          name: "crm_decline_statuses",
          props: true,
          meta: {},
          component: () => import("@/views/cabinet/DeclineStatuses.vue"),
        },
        {
          path: "/add-decline_status",
          name: "add_decline_status",
          props: true,
          meta: {},
          component: () => import("@/views/cabinet/DeclineStatusForm.vue"),
        },
        {
          path: "/decline-status/:id",
          name: "edit_decline_status",
          props: true,
          meta: {},
          component: () => import("@/views/cabinet/DeclineStatusForm.vue"),
        },
        {
          path: "/activity-types",
          name: "crm_activity_types",
          props: true,
          meta: {},
          component: () => import("@/views/cabinet/ActivityTypes.vue"),
        },
        {
          path: "/add-activity-type",
          name: "add_activity_type",
          props: true,
          meta: {},
          component: () => import("@/views/cabinet/ActivityTypeForm.vue"),
        },
        {
          path: "/activity-type/:id",
          name: "edit_activity_type",
          props: true,
          meta: {},
          component: () => import("@/views/cabinet/ActivityTypeForm.vue"),
        },
        {
          path: "/my-sources",
          name: "crm_sources",
          props: true,
          meta: {},
          component: () => import("@/views/cabinet/Sources.vue"),
        },
        {
          path: "/add-source",
          name: "add_source",
          props: true,
          meta: {},
          component: () => import("@/views/cabinet/SourceForm.vue"),
        },
        {
          path: "/source/:id",
          name: "edit_source",
          props: true,
          meta: {},
          component: () => import("@/views/cabinet/SourceForm.vue"),
        },
        {
          path: "/my-tags",
          name: "crm_tags",
          props: true,
          meta: {},
          component: () => import("@/views/cabinet/Tags.vue"),
        },
        {
          path: "/add-tag",
          name: "add_tag",
          props: true,
          meta: {},
          component: () => import("@/views/cabinet/TagForm.vue"),
        },
        {
          path: "/tag/:id",
          name: "edit_tag",
          props: true,
          meta: {},
          component: () => import("@/views/cabinet/TagForm.vue"),
        },
        {
          path: "/loyalty-cards",
          name: "loyalty_cards",
          props: true,
          meta: {
            // reload: true,
            // breadcrump: i18n.methods.translate('common.cart')
          },
          component: () => import("@/views/cabinet/LoyaltyCards.vue"),
        },
        {
          path: "/loyalty-card/:id",
          name: "edit_loyalty_card",
          props: true,
          meta: {
            // reload: true,
            // breadcrump: i18n.methods.translate('common.cart')
          },
          component: () => import("@/views/cabinet/Edit-loyalty-cart.vue"),
        },
        {
          path: "/my-loyalty",
          name: "my-loyalty",
          props: true,
          meta: {
            // reload: true,
            // breadcrump: i18n.methods.translate('common.cart')
          },
          component: () => import("@/views/cabinet/Loyalty.vue"),
        },
        
        {
          path: "/my-contacts",
          name: "my_contacts",
          props: true,
          meta: {
            // reload: true,
            // breadcrump: i18n.methods.translate('common.cart')
          },
          component: () => import("@/views/cabinet/Contacts.vue"),
        },
        {
          path: "/nova-post",
          name: "nova_post",
          props: true,
          meta: {
            // reload: true,
            // breadcrump: i18n.methods.translate('common.cart')
          },
          component: () => import("@/views/cabinet/NovaPost.vue"),
        },
        {
          path: "/np-invoice-list",
          name: "np_invoice_list",
          props: true,
          meta: {
            // reload: true,
            // breadcrump: i18n.methods.translate('common.cart')
          },
          component: () => import("@/views/cabinet/NpInvoiceList.vue"),
        },
        {
          path: "/sms",
          name: "sms",
          props: true,
          meta: {
            // reload: true,
            // breadcrump: i18n.methods.translate('common.cart')
          },
          component: () => import("@/views/cabinet/Sms.vue"),
        },
        {
          path: "/telegram",
          name: "telegram",
          props: true,
          meta: {
            // reload: true,
            // breadcrump: i18n.methods.translate('common.cart')
          },
          component: () => import("@/views/cabinet/Telegram.vue"),
        },
        {
          path: "/instagram",
          name: "instagram",
          props: true,
          meta: {
            // reload: true,
            // breadcrump: i18n.methods.translate('common.cart')
          },
          component: () => import("@/views/cabinet/Instagram.vue"),
        },
        {
          path: "/facebook",
          name: "facebook",
          props: true,
          meta: {
            // reload: true,
            // breadcrump: i18n.methods.translate('common.cart')
          },
          component: () => import("@/views/cabinet/Facebook.vue"),
        },
        {
          path: "/my-settings",
          name: "my_settings",
          props: true,
          meta: {
            // reload: true,
            // breadcrump: i18n.methods.translate('common.cart')
          },
          component: () => import("@/views/cabinet/Settings.vue"),
        },
        {
          path: "/liqpay",
          name: "liqpay",
          props: true,
          meta: {
            // reload: true,
            // breadcrump: i18n.methods.translate('common.cart')
          },
          component: () => import("@/views/cabinet/Liqpay.vue"),
        },
        {
          path: "/binotel",
          name: "binotel",
          props: true,
          meta: {
            // reload: true,
            // breadcrump: i18n.methods.translate('common.cart')
          },
          component: () => import("@/views/cabinet/Binotel.vue"),
        },
        {
          path: "/my-leads",
          name: "my_leads",
          props: true,
          meta: {
            // reload: true,
            // breadcrump: i18n.methods.translate('common.cart')
          },
          component: () => import("@/views/cabinet/Leads.vue"),
        },
        
        {
          path: "/add-lead",
          name: "add_lead",
          props: true,
          meta: {
            // reload: true,
            // breadcrump: i18n.methods.translate('common.cart')
          },
          component: () => import("@/views/cabinet/Add-lead-form.vue"),
        },
        {
          path: "/lead/:id",
          name: "edit_lead",
          props: true,
          meta: {
            // reload: true,
            // breadcrump: i18n.methods.translate('common.cart')
          },
          component: () => import("@/views/cabinet/Edit-lead.vue"),
        },
        {
          path: "/products",
          name: "products",
          meta: {
            requiresAuth: true,
            breadcrump: i18n.methods.translate("common.company_profile"),
          },
          component: () => import("@/views/cabinet/User-offers.vue"),
        },
        {
          path: "/additional_offers",
          name: "additional_offers",
          meta: {
            additional_offer: true,
            requiresAuth: true,
            breadcrump: i18n.methods.translate("common.company_profile"),
          },
          component: () => import("@/views/cabinet/User-offers.vue"),
        },
        {
          path: "/sub-offers/:id",
          name: "sub-offers",
          props: true,
          meta: {
            additional_offer: true,
            requiresAuth: true,
            breadcrump: i18n.methods.translate("common.company_profile"),
          },
          component: () => import("@/views/cabinet/User-offers.vue"),
        },
        {
          path: "/sub-products/:id",
          name: "sub-products",
          props: true,
          meta: {
            requiresAuth: true,
            breadcrump: i18n.methods.translate("common.company_profile"),
          },
          component: () => import("@/views/cabinet/User-offers.vue"),
        },

        {
          path: "/user-calendar",
          name: "user-calendar",
          meta: {
            requiresAuth: true,
            // breadcrump: i18n.methods.translate('common.process')
          },
          component: () => import("@/views/cabinet/User-calendar.vue"),
        },
        {
          path: "/orders",
          name: "orders",
          meta: {
            // requiresAuth: true,
            breadcrump: i18n.methods.translate("common.my_offers"),
          },
          component: () => import("@/views/cabinet/Order-list.vue"),
        },
        {
          path: "/my-payments",
          name: "payments",
          meta: {
            // requiresAuth: true,
            breadcrump: i18n.methods.translate("common.my_offers"),
          },
          component: () => import("@/views/cabinet/Payments.vue"),
        },
        {
          path: "/my-balances",
          name: "balances",
          meta: {
            requiresAuth: true,
            breadcrump: i18n.methods.translate("common.my_offers"),
          },
          component: () => import("@/views/Balances.vue"),
        },
        {
          path: "/conversations",
          name: "cabinet-chats",
          meta: {
            requiresAuth: true,
            breadcrump: i18n.methods.translate("common.chats"),
          },
          component: () => import("@/views/cabinet/Chat.vue"),
        },
        {
          path: "/my-notifications",
          name: "cabinet-notifications",
          meta: {
            requiresAuth: true,
            breadcrump: i18n.methods.translate("common.notifications"),
          },
          component: () => import("@/views/cabinet/Notifications-list.vue"),
        },
        {
          path: "/conversations/:id",
          name: "cabinet-chat",
          props: true,
          meta: {
            requiresAuth: true,
            breadcrump: i18n.methods.translate("common.chats"),
          },
          component: () => import("@/views/cabinet/Chat.vue"),
        },
        {
          path: "/members/add",
          name: "add-member",
          meta: {
            requiresAuth: true,
            breadcrump: i18n.methods.translate("common.edit_info"),
          },
          component: () => import("@/views/cabinet/Edit-profile.vue"),
        },
        {
          path: "/members/edit/:id",
          name: "edit-member",
          props: true,
          meta: {
            requiresAuth: true,
            breadcrump: i18n.methods.translate("common.edit_info"),
          },
          component: () => import("@/views/cabinet/Edit-profile.vue"),
        },
        {
          path: "/product_bonuses",
          name: "product_bonuses",
          meta: {
            requiresAuth: true,
            breadcrump: i18n.methods.translate("common.bonuses"),
          },
          component: () => import("@/views/cabinet/ProductBonus.vue"),
        },
        {
          path: "/add-bonus",
          name: "add-bonus",
          meta: {
            requiresAuth: true,
            breadcrump: i18n.methods.translate("common.add_bonus"),
          },
          component: () => import("@/views/cabinet/Add-bonus-form.vue"),
        },
        {
          path: "/product_bonus/:id",
          name: "edit-bonus",
          props: true,
          meta: {
            requiresAuth: true,
            breadcrump: i18n.methods.translate("common.add_bonus"),
          },
          component: () => import("@/views/cabinet/Add-bonus-form.vue"),
        },
        {
          path: "/transactions",
          name: "cabinet-transactions",
          meta: {
            requiresAuth: true,
            breadcrump: i18n.methods.translate("common.transactions"),
          },
          component: () => import("@/views/cabinet/Transactions-list.vue"),
        },
        {
          path: "/company",
          name: "company",
          meta: {
            requiresAuth: true,
            breadcrump: i18n.methods.translate("common.company_profile"),
          },
          component: () => import("@/views/cabinet/CompanyCustomerProfile.vue"),
        },
        {
          path: "/cabinet/company/edit",
          name: "edit-company",
          meta: {
            requiresAuth: true,
            breadcrump: i18n.methods.translate("common.edit_info"),
          },
          component: () => import("@/views/cabinet/Edit-company.vue"),
        },
        {
          path: "/my-prices",
          name: "prices",
          meta: {
            requiresAuth: true,
            breadcrump: i18n.methods.translate("common.company_profile"),
          },
          component: () => import("@/views/cabinet/Prices.vue"),
        },
        {
          path: "/add-prices",
          name: "add-prices",
          meta: {
            requiresAuth: true,
            breadcrump: i18n.methods.translate("common.company_profile"),
          },
          component: () => import("@/views/cabinet/PriceForm.vue"),
        },
        {
          path: "/price/:id",
          name: "price",
          props: true,
          meta: {
            requiresAuth: true,
            breadcrump: i18n.methods.translate("common.company_profile"),
          },
          component: () => import("@/views/cabinet/PriceForm.vue"),
        },
        {
          path: "/my-roles",
          name: "roles",
          meta: {
            requiresAuth: true,
            breadcrump: i18n.methods.translate("common.company_profile"),
          },
          component: () => import("@/views/cabinet/Roles.vue"),
        },
        {
          path: "/add-roles",
          name: "add-roles",
          meta: {
            requiresAuth: true,
            breadcrump: i18n.methods.translate("common.company_profile"),
          },
          component: () => import("@/views/cabinet/RoleForm.vue"),
        },
        {
          path: "/role/:id",
          name: "role",
          props: true,
          meta: {
            requiresAuth: true,
            breadcrump: i18n.methods.translate("common.company_profile"),
          },
          component: () => import("@/views/cabinet/RoleForm.vue"),
        },
        {
          path: "/my-workers",
          name: "workers",
          meta: {
            requiresAuth: true,
            breadcrump: i18n.methods.translate("common.company_profile"),
          },
          component: () => import("@/views/cabinet/Workers.vue"),
        },
        {
          path: "/add-workers",
          name: "add-workers",
          meta: {
            requiresAuth: true,
            breadcrump: i18n.methods.translate("common.company_profile"),
          },
          component: () => import("@/views/cabinet/WorkerForm.vue"),
        },
        {
          path: "/worker/:id",
          name: "edit-workers",
          props: true,
          meta: {
            requiresAuth: true,
            breadcrump: i18n.methods.translate("common.company_profile"),
          },
          component: () => import("@/views/cabinet/WorkerForm.vue"),
        },
        {
          path: "/my_invoices",
          name: "invoices",
          meta: {
            requiresAuth: true,
            breadcrump: i18n.methods.translate("common.company_profile"),
          },
          component: () => import("@/views/cabinet/InvoicesList.vue"), 
        },
        {
          path: "/telegram-users",
          name: "telegram-users",
          meta: {
            requiresAuth: true,
            breadcrump: i18n.methods.translate("common.company_profile"),
          },
          component: () => import("@/views/cabinet/TelegramUsers.vue"),
        },
        {
          path: "/add-telegram-users",
          name: "add-telegram-users",
          meta: {
            requiresAuth: true,
            breadcrump: i18n.methods.translate("common.company_profile"),
          },
          component: () => import("@/views/cabinet/TelegramUserForm.vue"),
        },
        {
          path: "/telegram-user/:id",
          name: "edit-telegram-users",
          props: true,
          meta: {
            requiresAuth: true,
            breadcrump: i18n.methods.translate("common.company_profile"),
          },
          component: () => import("@/views/cabinet/TelegramUserForm.vue"),
        },
        
        {
          path: "/credit-cards",
          name: "credit_cards",
          meta: {
            requiresAuth: true,
            breadcrump: i18n.methods.translate("common.company_profile"),
          },
          component: () => import("@/views/cabinet/CreditCards.vue"),
        },
        {
          path: "/add-credit-cards",
          name: "add-credit_cards",
          meta: {
            requiresAuth: true,
            breadcrump: i18n.methods.translate("common.company_profile"),
          },
          component: () => import("@/views/cabinet/Add-credit-card-form.vue"),
        },
        {
          path: "/credit-card/:id",
          name: "edit-credit_cards",
          props: true,
          meta: {
            requiresAuth: true,
            breadcrump: i18n.methods.translate("common.company_profile"),
          },
          component: () => import("@/views/cabinet/Add-credit-card-form.vue"),
        },
        {
          path: "/integration-drivers",
          name: "integration_drivers",
          meta: {
            requiresAuth: true,
            breadcrump: i18n.methods.translate("common.company_profile"),
          },
          component: () => import("@/views/cabinet/IntegrationDrivers.vue"),
        },
        {
          path: "/add-integration-drivers",
          name: "add-integration_drivers",
          meta: {
            requiresAuth: true,
            breadcrump: i18n.methods.translate("common.company_profile"),
          },
          component: () => import("@/views/cabinet/Add-integration-form.vue"),
        },
        {
          path: "/integration-driver/:id",
          name: "edit-integration_drivers",
          props: true,
          meta: {
            requiresAuth: true,
            breadcrump: i18n.methods.translate("common.company_profile"),
          },
          component: () => import("@/views/cabinet/Add-integration-form.vue"),
        },
        {
          path: "/my-features",
          name: "features",
          meta: {
            requiresAuth: true,
            breadcrump: i18n.methods.translate("common.company_profile"),
          },
          component: () => import("@/views/cabinet/Features.vue"),
        },
        {
          path: "/add-features",
          name: "add-features",
          meta: {
            requiresAuth: true,
            breadcrump: i18n.methods.translate("common.company_profile"),
          },
          component: () => import("@/views/cabinet/FeatureForm.vue"),
        },
        {
          path: "/feature/:id",
          name: "feature",
          props: true,
          meta: {
            requiresAuth: true,
            breadcrump: i18n.methods.translate("common.company_profile"),
          },
          component: () => import("@/views/cabinet/FeatureForm.vue"),
        },

        {
          path: "/my-characteristics",
          name: "characteristics",
          meta: {
            requiresAuth: true,
            breadcrump: i18n.methods.translate("common.company_profile"),
          },
          component: () => import("@/views/cabinet/Characteristics.vue"),
        },
        {
          path: "/add-characteristics",
          name: "add-characteristics",
          meta: {
            requiresAuth: true,
            breadcrump: i18n.methods.translate("common.company_profile"),
          },
          component: () => import("@/views/cabinet/CharacteristicForm.vue"),
        },
        {
          path: "/characteristic/:id",
          name: "characteristic",
          props: true,
          meta: {
            requiresAuth: true,
            breadcrump: i18n.methods.translate("common.company_profile"),
          },
          component: () => import("@/views/cabinet/CharacteristicForm.vue"),
        },
        {
          path: "/my-categories",
          name: "categories",
          meta: {
            requiresAuth: true,
            breadcrump: i18n.methods.translate("common.company_profile"),
          },
          component: () => import("@/views/cabinet/Categories.vue"),
        },
        {
          path: "/add-categories",
          name: "add-categories",
          meta: {
            requiresAuth: true,
            breadcrump: i18n.methods.translate("common.company_profile"),
          },
          component: () => import("@/views/cabinet/CategoriesForm.vue"),
        },
        {
          path: "/category/:id",
          name: "edit-categories",
          props: true,
          meta: {
            requiresAuth: true,
            breadcrump: i18n.methods.translate("common.company_profile"),
          },
          component: () => import("@/views/cabinet/CategoriesForm.vue"),
        },
        {
          path: "/colors",
          name: "colors",
          props: true,
          meta: {
            requiresAuth: true,
            breadcrump: i18n.methods.translate("common.colors"),
          },
          component: () => import("@/views/ColorSettings.vue"),
        },
        {
          path: "/my-warehouses",
          name: "warehouses",
          meta: {
            requiresAuth: true,
            breadcrump: i18n.methods.translate("common.company_profile"),
          },
          component: () => import("@/views/cabinet/Warehouses.vue"),
        },
        {
          path: "/warehouse/:id",
          name: "edit-warehouses",
          props: true,
          meta: {
            requiresAuth: true,
            breadcrump: i18n.methods.translate("common.chats"),
          },
          component: () => import("@/views/cabinet/Add-warehouse-form.vue"),
        },
        {
          path: "/add-warehouse",
          name: "add-warehouses",
          meta: {
            requiresAuth: true,
            breadcrump: i18n.methods.translate("common.chats"),
          },
          component: () => import("@/views/cabinet/Add-warehouse-form.vue"),
        },
        {
          path: "/my-outlets",
          name: "outlets",
          meta: {
            requiresAuth: true,
            breadcrump: i18n.methods.translate("common.company_profile"),
          },
          component: () => import("@/views/cabinet/Outlets.vue"),
        },
        {
          path: "/outlet/:id",
          name: "edit-outlets",
          props: true,
          meta: {
            requiresAuth: true,
            breadcrump: i18n.methods.translate("common.chats"),
          },
          component: () => import("@/views/cabinet/Add-outlet-form.vue"),
        },
        {
          path: "/add-outlet",
          name: "add-outlets",
          meta: {
            requiresAuth: true,
            breadcrump: i18n.methods.translate("common.chats"),
          },
          component: () => import("@/views/cabinet/Add-outlet-form.vue"),
        },
        {
          path: "/my-storages",
          name: "storages",
          meta: {
            requiresAuth: true,
            breadcrump: i18n.methods.translate("common.company_profile"),
          },
          component: () => import("@/views/StorageItems.vue"),
        },
        {
          path: "/storage_logs/:id",
          name: "storage-logs",
          props: true,
          meta: {
            requiresAuth: true,
            breadcrump: i18n.methods.translate("common.company_profile"),
          },
          component: () => import("@/views/cabinet/StorageLogs.vue"),
        },
        {
          path: "/my-clients",
          name: "clients",
          meta: {
            // reload: true,
            // breadcrump: i18n.methods.translate('common.cart')
          },
          component: () => import("@/views/cabinet/Clients.vue"),
        },
        {
          path: "/add-clients",
          name: "add-clients",
          meta: {
            requiresAuth: true,
            breadcrump: i18n.methods.translate("common.chats"),
          },
          component: () => import("@/views/cabinet/Add-client-form.vue"),
        },
        {
          path: "/client-info/:id",
          name: "client-info",
          props: true,
          meta: {
            requiresAuth: true,
            breadcrump: i18n.methods.translate("common.chats"),
          },
          component: () => import("@/views/cabinet/Client-info.vue"),
        },
        {
          path: "/edit-client/:id",
          name: "edit-clients",
          props: true,
          meta: {
            requiresAuth: true,
            breadcrump: i18n.methods.translate("common.chats"),
          },
          component: () => import("@/views/cabinet/Add-client-form.vue"),
        },
        {
          path: "/my-customers",
          name: "customers",
          meta: {
            // reload: true,
            // breadcrump: i18n.methods.translate('common.cart')
          },
          component: () => import("@/views/cabinet/Customers.vue"),
        },
        {
          path: "/add-customers",
          name: "add-customers",
          meta: {
            requiresAuth: true,
            breadcrump: i18n.methods.translate("common.chats"),
          },
          component: () => import("@/views/cabinet/Add-customer-form.vue"),
        },
        {
          path: "/customer-info/:id",
          name: "customer-info",
          props: true,
          meta: {
            requiresAuth: true,
            breadcrump: i18n.methods.translate("common.chats"),
          },
          component: () => import("@/views/cabinet/Customer-info.vue"),
        },
        {
          path: "/customers/:id",
          name: "edit-customers",
          props: true,
          meta: {
            requiresAuth: true,
            breadcrump: i18n.methods.translate("common.chats"),
          },
          component: () => import("@/views/cabinet/Add-customer-form.vue"),
        },
        {
          path: "/deliverymans",
          name: "deliverymans",
          meta: {
            requiresAuth: true,
            position: "deliverymans",
            breadcrump: i18n.methods.translate("common.company_profile"),
          },
          component: () => import("@/views/cabinet/Staff.vue"),
        },
        {
          path: "/add-deliverymans",
          name: "add-deliverymans",
          props: true,
          meta: {
            requiresAuth: true,
            position: "deliverymans",
            breadcrump: i18n.methods.translate("common.chats"),
          },
          component: () => import("@/views/cabinet/Add-staff-form.vue"),
        },
        {
          path: "/deliverymans/:id",
          name: "edit-deliverymans",
          props: true,
          meta: {
            requiresAuth: true,
            position: "deliverymans",
            breadcrump: i18n.methods.translate("common.chats"),
          },
          component: () => import("@/views/cabinet/Add-staff-form.vue"),
        },
        {
          path: "/my-managers",
          name: "managers",
          meta: {
            requiresAuth: true,
            position: "managers",
            breadcrump: i18n.methods.translate("common.company_profile"),
          },
          component: () => import("@/views/cabinet/Staff.vue"),
        },
        {
          path: "/add-managers",
          name: "add-managers",
          props: true,
          meta: {
            requiresAuth: true,
            position: "managers",
            breadcrump: i18n.methods.translate("common.chats"),
          },
          component: () => import("@/views/cabinet/Add-staff-form.vue"),
        },
        {
          path: "/managers/:id",
          name: "edit-managers",
          props: true,
          meta: {
            requiresAuth: true,
            position: "managers",
            breadcrump: i18n.methods.translate("common.chats"),
          },
          component: () => import("@/views/cabinet/Add-staff-form.vue"),
        },
        {
          path: "/my-coupons",
          name: "coupons",
          meta: {
            requiresAuth: true,
            breadcrump: i18n.methods.translate("common.company_profile"),
          },
          component: () => import("@/views/cabinet/Coupons.vue"),
        },
        {
          path: "/add-coupons",
          name: "add-coupons",
          props: true,
          meta: {
            requiresAuth: true,
            breadcrump: i18n.methods.translate("common.chats"),
          },
          component: () => import("@/views/cabinet/Add-coupons-form.vue"),
        },
        {
          path: "/coupon/:id",
          name: "edit-coupons",
          props: true,
          meta: {
            requiresAuth: true,
            breadcrump: i18n.methods.translate("common.chats"),
          },
          component: () => import("@/views/cabinet/Add-coupons-form.vue"),
        },
        {
          path: "/transaction-types",
          name: "transaction_types",
          meta: {
            requiresAuth: true,
            breadcrump: i18n.methods.translate("common.company_profile"),
          },
          component: () => import("@/views/cabinet/TransactionsTypes.vue"),
        },
        {
          path: "/add-transaction-types",
          name: "add-transaction-types",
          props: true,
          meta: {
            requiresAuth: true,
            breadcrump: i18n.methods.translate("common.chats"),
          },
          component: () => import("@/views/cabinet/Add-transaction-type-form.vue"),
        },
        {
          path: "/transaction-type/:id",
          name: "edit-transaction-types",
          props: true,
          meta: {
            requiresAuth: true,
            breadcrump: i18n.methods.translate("common.chats"),
          },
          component: () => import("@/views/cabinet/Add-transaction-type-form.vue"),
        },

        {
          path: "/my-templates",
          name: "templates",
          meta: {
            requiresAuth: true,
            breadcrump: i18n.methods.translate("common.company_profile"),
          },
          component: () => import("@/views/cabinet/Templates.vue"),
        },
        {
          path: "/template/:id",
          name: "edit-template",
          props: true,
          meta: {
            requiresAuth: true,
            position: "templates",
            breadcrump: i18n.methods.translate("common.chats"),
          },
          component: () => import("@/views/cabinet/Edit-template.vue"),
        },
        {
          path: "/warehousemen",
          name: "warehousemen",
          meta: {
            requiresAuth: true,
            position: "warehousemen",
            breadcrump: i18n.methods.translate("common.company_profile"),
          },
          component: () => import("@/views/cabinet/Staff.vue"),
        },
        {
          path: "/add-warehousemen",
          name: "add-warehousemen",
          props: true,
          meta: {
            requiresAuth: true,
            position: "warehousemen",
            breadcrump: i18n.methods.translate("common.chats"),
          },
          component: () => import("@/views/cabinet/Add-staff-form.vue"),
        },
        {
          path: "/warehousemen/:id",
          name: "edit-warehousemen",
          props: true,
          meta: {
            requiresAuth: true,
            position: "warehousemen",
            breadcrump: i18n.methods.translate("common.chats"),
          },
          component: () => import("@/views/cabinet/Add-staff-form.vue"),
        },
        {
          path: "/my-sellers",
          name: "sellers",
          meta: {
            requiresAuth: true,
            position: "sellers",
            breadcrump: i18n.methods.translate("common.company_profile"),
          },
          component: () => import("@/views/cabinet/Staff.vue"),
        },
        {
          path: "/add-sellers",
          name: "add-sellers",
          props: true,
          meta: {
            requiresAuth: true,
            position: "sellers",
            breadcrump: i18n.methods.translate("common.chats"),
          },
          component: () => import("@/views/cabinet/Add-staff-form.vue"),
        },
        {
          path: "/seller/:id",
          name: "edit-sellers",
          props: true,
          meta: {
            requiresAuth: true,
            position: "sellers",
            breadcrump: i18n.methods.translate("common.chats"),
          },
          component: () => import("@/views/cabinet/Add-staff-form.vue"),
        },
        {
          path: "/my-suppliers",
          name: "suppliers",
          meta: {
            requiresAuth: true,
            breadcrump: i18n.methods.translate("common.company_profile"),
          },
          component: () => import("@/views/cabinet/Suppliers.vue"),
        },
        {
          path: "/add-suppliers",
          name: "add-suppliers",
          props: true,
          meta: {
            requiresAuth: true,
            breadcrump: i18n.methods.translate("common.chats"),
          },
          component: () => import("@/views/cabinet/Add-supplier-form.vue"),
        },
        {
          path: "/supplier/:id",
          name: "edit-suppliers",
          props: true,
          meta: {
            requiresAuth: true,
            breadcrump: i18n.methods.translate("common.chats"),
          },
          component: () => import("@/views/cabinet/Add-supplier-form.vue"),
        },
        {
          path: "/supplier-orders",
          name: "supplier_orders",
          meta: {
            requiresAuth: true,
            breadcrump: i18n.methods.translate("common.company_profile"),
          },
          component: () => import("@/views/cabinet/SupplierOrders.vue"),
        },
        {
          path: "/add-supplier-orders",
          name: "add-supplier_orders",
          props: true,
          meta: {
            requiresAuth: true,
            breadcrump: i18n.methods.translate("common.chats"),
          },
          component: () =>
            import("@/views/cabinet/Add-supplier-order-form.vue"),
        },
        {
          path: "/supplier-orders/:id",
          name: "supplier_orders-details",
          props: true,
          meta: {
            requiresAuth: true,
            breadcrump: i18n.methods.translate("common.chats"),
          },
          component: () => import("@/views/cabinet/Supplier-order-details.vue"),
        },

        {
          path: "/agents",
          name: "agents",
          meta: {
            requiresAuth: true,
            position: "agents",
            breadcrump: i18n.methods.translate("common.company_profile"),
          },
          component: () => import("@/views/cabinet/Staff.vue"),
        },
        {
          path: "/add-agents",
          name: "add-agents",
          meta: {
            requiresAuth: true,
            position: "agents",
            breadcrump: i18n.methods.translate("common.chats"),
          },
          component: () => import("@/views/cabinet/Add-staff-form.vue"),
        },
        {
          path: "/agents/:id",
          name: "edit-agents",
          props: true,
          meta: {
            requiresAuth: true,
            position: "agents",
            breadcrump: i18n.methods.translate("common.chats"),
          },
          component: () => import("@/views/cabinet/Add-staff-form.vue"),
        },
        {
          path: "/my-points",
          name: "points",
          meta: {
            // reload: true,
            // breadcrump: i18n.methods.translate('common.cart')
          },
          component: () => import("@/views/cabinet/Points.vue"),
        },
        {
          path: "/add-points",
          name: "points-add",
          meta: {
            requiresAuth: true,
            breadcrump: i18n.methods.translate("common.my_points"),
          },
          component: () => import("@/views/cabinet/Add-shop-form.vue"),
        },
        {
          path: "/point/:id",
          name: "point",
          props: true,
          meta: {
            requiresAuth: true,
            breadcrump: i18n.methods.translate("common.my_points"),
          },
          component: () => import("@/views/cabinet/Add-shop-form.vue"),
        },
        {
          path: "/my-payers",
          name: "payers",
          meta: {
            // reload: true,
            // breadcrump: i18n.methods.translate('common.cart')
          },
          component: () => import("@/views/cabinet/Payers.vue"),
        },
        {
          path: "/payer/:id",
          name: "payer",
          props: true,
          meta: {
            requiresAuth: true,
            breadcrump: i18n.methods.translate("common.edit_info"),
          },
          component: () => import("@/views/cabinet/Edit-payer.vue"),
        },
        {
          path: "/add-payers",
          name: "payers-add",
          meta: {
            requiresAuth: true,
            breadcrump: i18n.methods.translate("common.my_payers"),
          },
          component: () => import("@/views/cabinet/Add-payer-form.vue"),
        },
        {
          path: "/my-mailings",
          name: "mailings",
          meta: {
            // reload: true,
            // breadcrump: i18n.methods.translate('common.cart')
          },
          component: () => import("@/views/cabinet/Mailings.vue"),
        },
        {
          path: "/payment-receivers",
          name: "payment_receivers",
          meta: {
            // reload: true,
            // breadcrump: i18n.methods.translate('common.cart')
          },
          component: () => import("@/views/cabinet/PaymentReceivers.vue"),
        },
        {
          path: "/payment-receivers/:id",
          name: "edit_payment_receivers",
          props: true,
          meta: {
            requiresAuth: true,
            breadcrump: i18n.methods.translate("common.edit_info"),
          },
          component: () =>
            import("@/views/cabinet/Add-payment_receiver-form.vue"),
        },
        {
          path: "/add-payment-receivers",
          name: "add_payment_receivers",
          meta: {
            requiresAuth: true,
            breadcrump: i18n.methods.translate("common.my_payers"),
          },
          component: () =>
            import("@/views/cabinet/Add-payment_receiver-form.vue"),
        },
        {
          path: "/order-details/:id/:part",
          name: "order-details",
          props: true,
          meta: {
            // requiresAuth: true,
            breadcrump: i18n.methods.translate("common.my_offers"),
          },
          component: () => import("@/views/cabinet/Order-details.vue"),
        },
        {
          path: "/packings-details/:cart_id/:part_id/:id",
          name: "packings-details",
          props: true,
          meta: {
            // requiresAuth: true,
            breadcrump: i18n.methods.translate("common.my_offers"),
          },
          component: () => import("@/views/cabinet/Packings.vue"),
        },
        {
          path: "/routes-details/:cart_id/:part_id/:id",
          name: "routes-details",
          props: true,
          meta: {
            // requiresAuth: true,
            breadcrump: i18n.methods.translate("common.my_offers"),
          },
          component: () => import("@/views/cabinet/Routes.vue"),
        },
        {
          path: "/my-routes",
          name: "routes",
          // meta: {
          //   requiresAuth: true,
          //   breadcrump: i18n.methods.translate('common.process')
          // },
          component: () => import("@/views/Routes.vue"),
        },
        {
          path: "/statistic",
          name: "statistic",
          // meta: {
          //   requiresAuth: true,
          //   breadcrump: i18n.methods.translate('common.process')
          // },
          component: () => import("@/views/Statistic.vue"),
        },
        {
          path: "/statistic/:active_type",
          name: "statistic_show",
          props: true,
          // meta: {
          //   requiresAuth: true,
          //   breadcrump: i18n.methods.translate('common.process')
          // },
          component: () => import("@/views/Statistic.vue"),
        },
        {
          path: "/warehouse",
          name: "warehouse",
          props: true,
          // meta: {
          //   requiresAuth: true,
          //   breadcrump: i18n.methods.translate('common.process')
          // },
          component: () => import("@/views/StorageItems.vue"),
        },
        {
          path: "/outlet",
          name: "outlet",
          props: true,
          // meta: {
          //   requiresAuth: true,
          //   breadcrump: i18n.methods.translate('common.process')
          // },
          component: () => import("@/views/StorageItems.vue"),
        },
        {
          path: "/warehouse/:warehouse_id",
          name: "warehouse_show",
          props: true,
          // meta: {
          //   requiresAuth: true,
          //   breadcrump: i18n.methods.translate('common.process')
          // },
          component: () => import("@/views/StorageItems.vue"),
        },
        {
          path: "/my-bonuses",
          name: "my-bonuses",
          // meta: {
          //   requiresAuth: true,
          //   breadcrump: i18n.methods.translate('common.process')
          // },
          component: () => import("@/views/cabinet/UserBonus.vue"),
        },
        {
          path: "/bonus-details/:id",
          name: "bonus-details",
          props: true,
          // meta: {
          //   requiresAuth: true,
          //   breadcrump: i18n.methods.translate('common.process')
          // },
          component: () => import("@/views/cabinet/BonusDetails.vue"),
        },
        {
          path: "/my-packings",
          name: "packings",
          // meta: {
          //   requiresAuth: true,
          //   breadcrump: i18n.methods.translate('common.process')
          // },
          component: () => import("@/views/Packings.vue"),
        },
        //trading agent points
        {
          path: "/agent_routes",
          name: "agent_routes",
          // meta: {
          //   requiresAuth: true,
          //   breadcrump: i18n.methods.translate('common.process')
          // },
          component: () =>
            import("@/views/cabinet/Trading-agent/Route-list.vue"),
        },
        {
          path: "/agent_route/add",
          name: "agent_route_add",
          // meta: {
          //   requiresAuth: true,
          //   breadcrump: i18n.methods.translate("common.my_points"),
          // },
          component: () =>
            import("@/views/cabinet/Trading-agent/Add-route-form.vue"),
        },
        {
          path: "/route_details/:id",
          name: "route_details",
          props: true,
          // meta: {
          //   requiresAuth: true,
          //   breadcrump: i18n.methods.translate("common.my_points"),
          // },
          component: () =>
            import("@/views/cabinet/Trading-agent/Route-details.vue"),
        },

        {
          path: "/my-movements",
          name: "movements",
          props: true,
          // meta: {
          //   requiresAuth: true,
          //   breadcrump: i18n.methods.translate("common.my_points"),
          // },
          component: () => import("@/views/movements/Movements.vue"),
        },
        {
          path: "/add-movement",
          name: "add_movement",
          props: true,
          meta: {
            // reload: true,
            // breadcrump: i18n.methods.translate('common.cart')
          },
          component: () => import("@/views/movements/Add.vue"),
        },
        {
          path: "/movement/:id",
          name: "movements_edit",
          props: true,
          // meta: {
          //   requiresAuth: true,
          //   breadcrump: i18n.methods.translate("common.my_points"),
          // },
          component: () => import("@/views/movements/Edit.vue"),
        },
        {
          path: "/qr-scanner",
          name: "qr-scanner",
          // meta: {
          //   requiresAuth: true,
          //   breadcrump: i18n.methods.translate('common.process')
          // },
          component: () => import("@/views/QrScanner.vue"),
        },
        //Inventory
        {
          path: "/inventory",
          name: "inventory",
          props: true,
          // meta: {
          //   requiresAuth: true,
          //   breadcrump: i18n.methods.translate("common.my_points"),
          // },
          component: () => import("@/views/inventory/Inventory.vue"),
        },
        {
          path: "/inventory/:id",
          name: "inventory_edit",
          props: true,
          // meta: {
          //   requiresAuth: true,
          //   breadcrump: i18n.methods.translate("common.my_points"),
          // },
          component: () => import("@/views/inventory/Edit.vue"),
        },
        //Warehouse invoice
        {
          path: "/incoming-invoices",
          name: "incoming_invoices",
          props: true,
          // meta: {
          //   requiresAuth: true,
          //   breadcrump: i18n.methods.translate("common.my_points"),
          // },
          component: () =>
            import("@/views/incoming_invoice/IncomingInvoice.vue"),
        },
        {
          path: "/incoming-invoice/:id",
          name: "incoming_invoice_edit",
          props: true,
          // meta: {
          //   requiresAuth: true,
          //   breadcrump: i18n.methods.translate("common.my_points"),
          // },
          component: () => import("@/views/incoming_invoice/Edit.vue"),
        },
      ],
    },
    // {
    //   path: "/cabinet/offer/:id",
    //   name: "cabinet-offer",
    //   props: true,
    //   meta: {
    //     requiresAuth: true,
    //     breadcrump: i18n.methods.translate("common.my_offers"),
    //     productType: "offers",
    //   },
    //   component: () => import("@/views/Add-new-offer.vue"),
    // },
    {
      path: "/cabinet/offers/add",
      name: "add-new-offer",
      meta: {
        productType: "products",
        requiresAuth: true,
        breadcrump: i18n.methods.translate("common.add_offer"),
      },
      component: () => import("@/views/Add-new-offer.vue"),
    },
    {
      path: "/cabinet/offers/add",
      name: "add-new-additional-offer",
      meta: {
        additional_offer: true,
        requiresAuth: true,
        breadcrump: i18n.methods.translate("common.add_offer"),
      },
      component: () => import("@/views/Add-additional-offer.vue"),
    },
    {
      path: "/map",
      name: "map",
      meta: {
        reload: true,
        is_map: true,
        breadcrump: i18n.methods.translate("common.map"),
      },
      component: () => import("@/views/Map.vue"),
    },

    {
      path: "/logistic",
      name: "logistic",
      meta: {
        reload: true,
        is_map: true,
        breadcrump: i18n.methods.translate("common.map"),
      },
      component: () => import("@/views/LogisticMap.vue"),
    },

    {
      path: "/map/:id",
      name: "map_item",
      props: true,
      meta: {
        reload: true,
        is_map: true,
        breadcrump: i18n.methods.translate("common.map"),
      },
      component: () => import("@/views/Map.vue"),
    },
    {
      path: "/login",
      name: "login",
      component: Marketplace,
      meta: {
        withoutAuth: true,
        reload: true,
        showLogin: true,
        productType: "products",
        breadcrump: i18n.methods.translate("common.home"),
      },
    },
    {
      path: "/logout",
      name: "logout",
      beforeEnter(to, from, next) {
        window.localStorage.clear();
        window.sessionStorage.clear();
        window.location = "/logout";
      },
    },
    {
      path: "/pricing",
      name: "pricing",
      beforeEnter(to, from, next) {
        window.location = "/pricing";
      },
    },
    // {
    //   path: "/profile",
    //   name: "cabinet",
    //   meta: {
    //     // requiresAuth: true,
    //     breadcrump: i18n.methods.translate("common.my_profile"),
    //   },
    //   component: () => import("@/views/cabinet/User-profile.vue"),
    // },

    // {
    //   path: "/cabinet/members",
    //   name: "cabinet-members",
    //   meta: {
    //     requiresAuth: true,
    //     breadcrump: i18n.methods.translate("common.team_members"),
    //   },
    //   component: () => import("@/views/cabinet/Team-members.vue"),
    // },
    {
      path: "/cabinet/member/:id",
      name: "member",
      props: true,
      meta: {
        requiresAuth: true,
        breadcrump: i18n.methods.translate("common.edit_info"),
      },
      component: () => import("@/views/cabinet/Edit-profile.vue"),
    },
    {
      path: "/cabinet/transactions/all",
      name: "cabinet-company-transactions",
      meta: {
        requiresAuth: true,
        breadcrump: i18n.methods.translate("common.transactions"),
      },
      component: () => import("@/views/cabinet/Transactions-list.vue"),
    },
    // {
    //   path: "/cabinet/invoices/all",
    //   name: "cabinet-company-invoices",
    //   meta: {
    //     requiresAuth: true,
    //     breadcrump: i18n.methods.translate('common.invoices')
    //   },
    //   component: () =>
    //     import("@/views/cabinet/Invoices-list.vue")
    // },
    {
      path: "/faq",
      name: "faq",
      meta: {
        withoutAuth: true,
        breadcrump: i18n.methods.translate("common.faq"),
      },
      component: () => import("@/views/Faq.vue"),
    },
    {
      path: "/about",
      name: "about",
      meta: {
        withoutAuth: true,
        breadcrump: i18n.methods.translate("common.about"),
      },
      component: () => import("@/views/About.vue"),
    },
    {
      path: "/cabinet-menu",
      name: "cabinet-menu",
      meta: {
        requiresAuth: true,
      },
      component: () => import("@/views/cabinet/Cabinet-menu.vue"),
    },
    {
      path: "/cabinet/processes",
      name: "cabinet-processes",
      meta: {
        requiresAuth: true,
        breadcrump: i18n.methods.translate("common.process"),
      },
      component: () => import("@/views/cabinet/User-processes.vue"),
    },
    {
      path: "/cabinet/add-shop",
      name: "add-shop",
      meta: {
        // requiresAuth: true,
        // breadcrump: i18n.methods.translate('common.process')
      },
      component: () => import("@/views/cabinet/Add-shop-form.vue"),
    },
    {
      path: "/cabinet/process/all",
      name: "cabinet-company-process",
      meta: {
        requiresAuth: true,
        breadcrump: i18n.methods.translate("common.process"),
      },
      component: () => import("@/views/cabinet/User-processes.vue"),
    },
    {
      path: "/cabinet/processes/:id",
      props: true,
      name: "process",
      meta: {
        requiresAuth: true,
        breadcrump: i18n.methods.translate("common.process"),
      },
      component: () => import("@/views/business-process/Bp-item.vue"),
    },
    {
      path: "/thanks-for-order",
      name: "ThankYouPage",
      component: () => import("@/views/ThankYouPage.vue"),
      meta: {
        withoutAuth: true
      }
    },
    // { 
    //   path: '*', 
    //   beforeEnter: (to, from, next) => {
    //     if(to.)
    //     window.location.href = '/404'
    //   }
    // }
      // {
    //   path: "/warehouse/product/:sku",
    //   name: "warehouse-product",
    //   // meta: {
    //   //   requiresAuth: true,
    //   //   breadcrump: i18n.methods.translate('common.process')
    //   // },
    //   component: () => import("@/views/cabinet/StorageItem.vue"),
    // },
  ],
});

router.beforeEach((to, from, next) => {
  if (false && to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.getters["users/getItemCurrent"].id) {
      next();
      return;
    }
    window.localStorage.clear();
    window.location = "/login";
  } else {
    if (!to.params || (to.params && !to.params.skip_top)) {
      window.scrollTo(0, 0);
    }

    next();
  }
});

export default router;
