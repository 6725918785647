import Vue from "vue";
import App from "./app.vue";
import BootstrapVue from "bootstrap-vue";
import { VTooltip, VPopover, VClosePopover } from 'v-tooltip'
import router from "./router";
import store from "@/store";
import vSelect from "vue-select";
import VueAwesomeSwiper from "vue-awesome-swiper";
import SvgIcons from "@/components/ui/SvgIcons";
import VueSlider from "vue-slider-component";
import i18n from "@/mixins/i18n";
import gon from "@/mixins/gon";
import logger from "@/mixins/logger";
import user from "@/mixins/user";
import config from "@/mixins/config";
import messages from "@/mixins/messages";
import helper from "@/mixins/helper";
import cart from "@/mixins/cart";
import theme from "@/mixins/theme";
import VueConfirmDialog from 'vue-confirm-dialog';
import moment from "moment";
import axios from "axios";
import BackToTop from "vue-backtotop";
import vueKanban from 'vue-kanban'
import NProgress from 'vue-nprogress'
import linkify from 'vue-linkify'
 
Vue.use(NProgress)
const nprogress = new NProgress()
Vue.use(vueKanban)

Vue.use(BackToTop);
import VuePhoneNumberInput from "vue-phone-number-input";
import * as VueGoogleMaps from "vue2-google-maps";
import GmapCluster from "vue2-google-maps/dist/components/cluster"; // replace src with dist if you have Babel issues

import Geocoder from "@pderas/vue2-geocoder";

Vue.use(Geocoder, {
  defaultCountryCode: null, // e.g. 'CA'
  defaultLanguage: null, // e.g. 'en'
  defaultMode: "address", // or 'lat-lng'
  googleMapsApiKey: "AIzaSyAKgP1t4iotXp9yQfQbONtxmMsTAFV0uQs",
});

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading";
import VueRecaptcha from "vue-recaptcha";

import VueToast from "vue-toast-notification";
import vBlur from "v-blur";

Vue.use(vBlur);
Vue.use(VueToast);

Vue.use(VueConfirmDialog);
Vue.component('vue-confirm-dialog', VueConfirmDialog.default);

Vue.component("vue-recaptcha", VueRecaptcha);

Vue.use(
  Loading,
  {
    // props
    color: "red",
  },
  {
    // slots
  }
);

import VueMixpanelBrowser from 'vue-mixpanel-browser'

if (window.gon.config.mixpanel_token) {
  Vue.use(VueMixpanelBrowser, {
    token: window.gon.config.mixpanel_token,
    config: {}
  })
}

import VueMoment from "vue-moment";

Vue.use(VueMoment, {
  moment,
});

Vue.component("loading", Loading);
Vue.component("GmapCluster", GmapCluster);

import loaderMixin from "@/mixins/loader";
Vue.mixin(loaderMixin);

Vue.component("vue-phone-number-input", VuePhoneNumberInput);

import VeeValidate, { Validator } from "vee-validate";
import ru from "vee-validate/dist/locale/ru";
import VueGeolocation from 'vue-browser-geolocation';
Vue.use(VueGeolocation);

Validator.localize("ru", ru);
Vue.use(VeeValidate, {
  locale: "ru",
  classNames: {
    valid: "is-valid",
    invalid: "error",
  },
  validity: true,
});
Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyApKxx6WJb2xECnnUwJJ7t_1ELGKzLcnEY",
    libraries: 'places'
  },
});
Vue.component("gmap-cluster", VueGoogleMaps.Cluster);

Vue.use(BootstrapVue);

Vue.use(VueAwesomeSwiper);
Vue.mixin(i18n);
Vue.mixin(gon);
Vue.mixin(logger);
Vue.mixin(user);
Vue.mixin(config);
Vue.mixin(messages);
Vue.mixin(helper);
Vue.mixin(cart);
Vue.mixin(theme);

// Vue.use(DisableAutocomplete);

Vue.component("v-select", vSelect);
Vue.component("svg-icon", SvgIcons);
Vue.component("VueSlider", VueSlider);

Vue.directive('tooltip', VTooltip)
Vue.directive('linkified', linkify)
Vue.directive('close-popover', VClosePopover)
Vue.component('v-popover', VPopover)

Vue.directive("btn-loading", {
  // bind(el, binding, vnode) {
  //   console.log("bind");
  // },
  // inserted(el, binding, vndoe) {
  //   console.log("inserted");
  // },
  // updated(el, binding, vnode) {
  //   console.log("updated");
  // },
  componentUpdated(el, binding) {
    el.disabled = "true"
    let spinner = document.createElement("span")
    spinner.classList.add("btn-spinner", "spinner-border", "spinner-border-sm")
    el.insertBefore(spinner, el.firstChild)
  }
});

Vue.config.productionTip = false;

// Vue.config.errorHandler = function(err, vm, info) {
//   // logger.exception(`Exception: ${err.toString()}\nInfo: ${info}`);
// }

// Vue.config.warnHandler = function(err, vm, info) {
//   // logger.error(`Error: ${err.toString()}\nInfo: ${info}`);
// }

// window.onerror = function myErrorHandler(errorMsg, url, lineNumber) {
//   console.log( errorMsg )
// }

const eventHub = new Vue(); // Single event hub

// Distribute to components using global mixin
Vue.mixin({
  data: function() {
    return {
      eventHub: eventHub,
    };
  },
});

import ActionCableVue from "actioncable-vue";

Vue.use(ActionCableVue, {
  debug: true,
  debugLevel: "error",
  connectionUrl: window.gon.config.ws_protocol + "://" + window.gon.config.host + "/cable",
  connectImmediately: true
});

I18n.locale = window.gon.config.locale

const setupAxios = () => {
  axios.defaults.headers.common["X-CSRF-TOKEN"] = document
    .querySelector('meta[name="csrf-token"]')
    .getAttribute("content");
  axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
  axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
  axios.defaults.headers.common["Access-Control-Allow-Credentials"] = true;
  axios.defaults.headers.common["Access-Control-Allow-Methods"] = "GET, POST";
  axios.defaults.headers.common["Accept"] = "application/json, text/javascript, */*; q=0.01";
  axios.defaults.headers.common["Content-Type"] = "application/json; charset=UTF-8";

  axios.interceptors.response.use(response => {
    if (response.data && response.data.status === 401) {
      window.localStorage.clear();
      if (!router.app._route.meta.withoutAuth) {
        window.location.href = '/login'
      }
    }
    return response
  }, error => {
    if (error.response.status === 401) {
      window.localStorage.clear();
      if (!router.app._route.meta.withoutAuth) {
        window.location.href = '/login'
      }
      
    }
    return error;
  })
}

document.addEventListener("DOMContentLoaded", () => {
  setupAxios()
  window.authApp = new Vue({
    nprogress,
    router,
    store,
    render: (h) => h(App),
  }).$mount("#app");
});

// Array.prototype.remove = function() {
//     var what, a = arguments, L = a.length, ax;
//     while (L && this.length) {
//         what = a[--L];
//         while ((ax = this.indexOf(what)) !== -1) {
//             this.splice(ax, 1);
//         }
//     }
//     return this;
// }
