import crudGetters from '@/store/crud/getters'
const getters = {
  ...crudGetters(),
  getEditedItem(state){
    return state.item_edited;
  },
  getMessages(state){
    return state.messages;
  }
}
export default getters
