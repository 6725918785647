import $http from "axios";
import crudActions from '@/store/crud/actions'

const actions = {
  ...crudActions('notifications'),
  SetSideNotifications({ commit }, item) {
    commit("SET", { type: "notifications", items: item });
  },
  List({ commit, getters }) {
    let filters = getters.getFilters;
    let send_filters = {};

    for (let key of Object.keys(filters)) {
      if (key == "from_to" && filters[key] && filters.from_to[0]) {
        send_filters.start_day = filters.from_to[0];
        send_filters.end_day = filters.from_to[1];
      } else if (filters[key] && typeof filters[key] == "object") {
        send_filters[key] = filters[key].id;
      } else {
        send_filters[key] = filters[key];
      }
    }
    let params = {
      page: getters.getPage,
      filter: send_filters,
    };
    return new Promise((resolve, reject) => {
      $http
        .get(`/notifications`, { params: params })
        .then(
          (response) => {
            if (response.data.success) {
              commit("SET", { type: "items", items: response.data.items });
              commit("SET", { type: "total", items: response.data.total });
            } else {
              commit("SET", { type: "items", items: [] });
            }
            resolve(response.data);
          },
          (error) => {
            reject(error);
          }
        )
        .catch((err) => console.log(err.response));
    });
  },
  New({ commit, dispatch }) {
    return new Promise((resolve, reject) => {
      $http
        .get(`/notifications/new`)
        .then(
          (response) => {
            if (response.data.success) {
              commit("SET", {
                type: "item_new",
                items: response.data.item,
              });
            } else {
              commit("SET", { type: "item_new", items: {} });
              dispatch("marketplace/Failure", response.data.message, {
                root: true,
              });
            }
            resolve(response.data);
          },
          (error) => {
            reject(error);
          }
        )
        .catch((err) => console.log(err.response));
    });
  }
}
export default actions

