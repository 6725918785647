import ProductName from "@/components/layout/product-name/ProductName";

export default {
  props: ['item','cls', 'name', 'value'],
  components: {
    ProductName
  },
  created(){
    this.selected = this.value
  },
  data(){
    return {
      selected: 0
    }
  },
  methods: {
    update(){
      if (this.checkAvailability()) {
        this.$store.dispatch('cart/UpdateFullCart', {product: this.item, quantity: Number(this.selected) });
      }
    }
  },
  watch:{
    value(val) {
      this.selected = val || 0;
    },
    selected(val){
      if (this.checkAvailability()) {
        this.$store.dispatch('cart/UpdateFullCart', {product: this.item, quantity: Number(val) });
      }
    },
  }
}