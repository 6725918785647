import $http from "axios";
import crudActions from '@/store/crud/actions'
const actions = {
  ...crudActions('statuses'),
  Up({ commit, dispatch }, id) {
    return new Promise((resolve, reject) => {
      $http(`/statuses/${id}/up`)
        .then(
          (response) => {
            if (response.data.success) {
              commit("SET", { type: "item_edited", items: {} });
              resolve(response.data);
            } else {
              commit("SET", { type: "item_edited", items: {} });
              dispatch("marketplace/Failure", response.data.message, {
                root: true,
              });
            }
          },
          (error) => {
            console.log(error);
          }
        )
        .catch((err) => console.log(err.response));
    });
  },
  Down({ commit, dispatch }, id) {
    return new Promise((resolve, reject) => {
      $http(`/statuses/${id}/down`)
        .then(
          (response) => {
            if (response.data.success) {
              commit("SET", { type: "item_edited", items: {} });
              resolve(response.data);
            } else {
              commit("SET", { type: "item_edited", items: {} });
              dispatch("marketplace/Failure", response.data.message, {
                root: true,
              });
            }
          },
          (error) => {
            console.log(error);
          }
        )
        .catch((err) => console.log(err.response));
    });
  },
  New({ commit, dispatch }) {
    return new Promise((resolve, reject) => {
      $http
        .get(`/statuses/new`)
        .then(
          (response) => {
            if (response.data.success) {
              commit("SET", {
                type: "item_new",
                items: response.data.item,
              });
            } else {
              commit("SET", { type: "item_new", items: {} });
              dispatch("marketplace/Failure", response.data.message, {
                root: true,
              });
            }
            resolve(response.data);
          },
          (error) => {
            reject(error);
          }
        )
        .catch((err) => console.log(err.response));
    });
  },
  List({ commit, getters, dispatch }) {
    let filters = getters.getFilters;
    let send_filters = {};
    for (let key of Object.keys(filters)) {
      if (filters[key] && typeof filters[key] == "object") {
        send_filters[key] = filters[key].id;
      } else {
        send_filters[key] = filters[key];
      }
    }
    let params = {
      filters: send_filters,
      page: getters.getPage,
    };
    return new Promise((resolve, reject) => {
      $http
        .get(`/statuses`, { params: params })
        .then(
          (response) => {
            if (response.data.success) {
              commit("SET", { type: "items", items: response.data.items });
              commit("SET", { type: "total", items: response.data.total });
            } else {
              commit("SET", { type: "items", items: [] });
              dispatch("marketplace/Failure", response.data.message, {
                root: true,
              });
            }
            resolve(response.data);
          },
          (error) => {
            reject(error);
          }
        )
        .catch((err) => console.log(err.response));
    });
  },


  setColorPalette({ commit }, item) {
    commit("SET_LOCALSTORE", { type: "colorPalette", items: item });
  }
}
export default actions