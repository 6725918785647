import $http from "axios";
import crudActions from '@/store/crud/actions'
const actions = {
  ...crudActions('supplier_orders'),
  Items({ commit, getters, dispatch }) {
    return new Promise((resolve, reject) => {
      $http
        .get(`/supplier_orders/items`)
        .then(
          (response) => {
            if (response.data.success) {
            } else {
              dispatch("marketplace/Failure", response.data.message, {
                root: true,
              });
            }
            resolve(response.data);
          },
          (error) => {
            reject(error);
          }
        )
        .catch((err) => console.log(err.response));
    });
  },
  New({ commit, dispatch }) {
    return new Promise((resolve, reject) => {
      $http
        .get(`/supplier_orders/new`)
        .then(
          (response) => {
            if (response.data.success) {
              commit("SET", {
                type: "item_new",
                items: response.data.item,
              });
            } else {
              commit("SET", { type: "item_new", items: {} });
              dispatch("marketplace/Failure", response.data.message, {
                root: true,
              });
            }
            resolve(response.data);
          },
          (error) => {
            reject(error);
          }
        )
        .catch((err) => console.log(err.response));
    });
  },
  AddFile({ commit, getters }, item) {
    return new Promise((resolve, reject) => { 
      let form = new FormData();
      if (item.files) {
        for (var i = item.files.length - 1; i >= 0; i--) {
          form.append(`attachments['${i}']`, item.files[i]);
        }
      }
      for (const [key, value] of Object.entries(item)) {
        form.append(key, value);
      }
      $http
        .put(`/supplier_orders/${item.id}/attach`, form, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then(
          (response) => {
            commit("SET", { type: "item_saved", items: response.data.success });
            resolve(response.data);
          },
          (error) => {
            reject(error);
          }
        )
        .catch((err) => console.log(err.response));
    });
  },
  SendToSupplier({ commit, getters, dispatch }, id) {
    return new Promise((resolve, reject) => {
      $http
        .get(`/supplier_orders/${id}/send_to_suplier`)
        .then(
          (response) => {
            if (response.data.success) {
            } else {
              dispatch("marketplace/Failure", response.data.message, {
                root: true,
              });
            }
            resolve(response.data);
          },
          (error) => {
            reject(error);
          }
        )
        .catch((err) => console.log(err.response));
    });
  },
  AttachBlank({ dispatch }, id) {
    return new Promise((resolve, reject) => {
      $http
        .get(`/supplier_orders/${id}/attach_blank`)
        .then(
          (response) => {
            if (response.data.success) {
            } else {
              dispatch("marketplace/Failure", response.data.message, {
                root: true,
              });
            }
            resolve(response.data);
          },
          (error) => {
            reject(error);
          }
        )
        .catch((err) => console.log(err.response));
    });
  },
  
  List({ commit, getters, dispatch }) {
    let filters = getters.getFilters;
    let send_filters = {};
    for (let key of Object.keys(filters)) {
      if (filters[key] && typeof filters[key] == "object") {
        send_filters[key] = filters[key].id;
      } else {
        send_filters[key] = filters[key];
      }
    }
    let params = {
      filters: send_filters,
      page: getters.getPage,
    };
    return new Promise((resolve, reject) => {
      $http
        .get(`/supplier_orders`, { params: params })
        .then(
          (response) => {
            if (response.data.success) {
              commit("SET", { type: "items", items: response.data.items });
              commit("SET", { type: "total", items: response.data.total });
            } else {
              commit("SET", { type: "items", items: [] });
              dispatch("marketplace/Failure", response.data.message, {
                root: true,
              });
            }
            resolve(response.data);
          },
          (error) => {
            reject(error);
          }
        )
        .catch((err) => console.log(err.response));
    });
  },
  ConfirmStep({ commit, getters, dispatch }, id) {
    return new Promise((resolve, reject) => {
      $http
        .get(`/supplier_orders/${id}/confirm_step`)
        .then(
          (response) => {
            if (response.data.success) {
            } else {
              dispatch("marketplace/Failure", response.data.message, {
                root: true,
              });
            }
            resolve(response.data);
          },
          (error) => {
            reject(error);
          }
        )
        .catch((err) => console.log(err.response));
    });
  }
}
export default actions