import crudState from '@/store/crud/state'
const state = {
  ...crudState(),
  item_current: {
    config:{
      points: [] 
    },
    is_client: true,
    layout: {
      can_buy: true
    },
  },
  item_deleted: false,
  chart_in: [],
  chart_out: [],
  new_messages_count: 0
}
export default state