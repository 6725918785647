import logger from '@/mixins/logger';

export default {
  computed:{
    error(){
      if(this.success){
        return null;
      }
      else{
        return this.$store.getters['account/getRegister'].message;
      }
    },
    success(){
      return this.$store.getters['users/getItemCurrent'].user_name
    }
  },
  watch: {
    success (newValue, oldValue) {
      if(newValue){
        this.$emit("closePopup")
      }
    }
  },
  created(){
    this.$validator.attach({ name: "phone", rules: "required" });
  },
  methods: {
    roles(){
      return gon.config.roles;
    },
    onCaptchaExpired(e){
      console.log(e)
    },
    verifyCaptcha(e){
      console.log(e)
      this.captcha = e
      this.handleSubmit()
    },
    showLogin(){
      this.$store.dispatch('account/AddItem', {type: 'auth', items: 'login' });
    },
    async validateForm(){
      const toValidate = [
        this.$validator.validate('first_name'),
        this.$validator.validate('last_name')
      ]
      if (this.active_nav == 'corporate') { 
        toValidate.push(this.$validator.validate('company_name'))
        toValidate.push(this.$validator.validate('email'))
        toValidate.push(this.$validator.validate('password'))
        toValidate.push(this.$validator.validate('password_confirmation'))
      }

      const results = Promise.all([...toValidate]);
      return (await results).every(isValid => isValid);
    },
    handleSubmit (e) {
      // if(!this.captcha){
      //   return
      // }
      this.submitted = true;
      this.$validator.validateAll().then((result) => {
        if(this.phoneIsValid){
          this.errors.remove('phone')
        }
        if(!this.errors.items.length){
          this.$isLoading('isLoading', 'item_current');
          this.$store.dispatch('users/Register', {user: this.getPayload()});
        }
      })
    },
    getPayload(){
      const acc = this.account
      acc.captcha = this.captcha
      acc.sign_up_type = this.active_nav
      return acc;
    },
    onUpdate(e){
      this.account.country_code = e.countryCode
      this.account.phone = e.formattedNumber
      this.account.phone_number = e.nationalNumber
      if(e.isValid){
        this.phoneIsValid = true
        this.errors.remove('phone')
      }
      else{
        this.phoneIsValid = false
      }
      return e;
    },
  },
  data() {
    return {
      fullPage: true,
      submitted: false,
      active_nav: "personal",
      tabs: ["personal", "corporate"],
      phone: null,
      captcha: null,
      role: null,
      account: {
        agree: false,
        email: null,
        password: null,
        first_name: null,
        last_name: null,
        company_name: null,
        password_confirmation: null,
        phone: null,
        country_code: gon.config.country,
        phone_number: null,
        t: null
      },
      telProps: {
        'required': true,
        'only-countries': ['UA'],
        'default-country-code': gon.config.country,
        'preferred-countries': [gon.config.country],
        'valid-color': 'green',
        'error': false
      }
    }
  }
}