import $http from "axios";
import crudActions from '@/store/crud/actions'
const actions = {
  ...crudActions('loyalty', false),
  Save({ commit }, item) {
    return new Promise((resolve, reject) => {
      let method = item.id ? "put" : "post";
      $http[method](`/loyalty`, item)
        .then(
          response => {
            commit("SET", { type: "item_saved", items: response.data.success });
            if (response.data.success) {
            } else {
              dispatch("marketplace/Failure", response.data.message, {
                root: true
              });
            }
            resolve(response.data);
          },
          error => {
            reject(error);
          }
        )
        .catch(err => console.log(err.response));
    });
  }
}
export default actions
