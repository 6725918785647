import { directive as onClickaway } from "vue-clickaway";

export default {
  directives: {
    onClickaway: onClickaway
  },
  data() {
    return {
      menuShow: false
    };
  },
  computed: {
    itemsCount() {
      return this.$store.getters["cart/getItemsCount"];
    },
    isLoading() {
      return this.$store.getters["marketplace/getLoading"];
    },
  },
  methods: {
    tryGo() {
      if (this.itemsCount > 0){
        this.goToCart();
      }
    },
    openMenu() {
      this.menuShow = true;
    },
    closeMenu() {
      this.menuShow = false;
    },
    show() {
      alert(1);
    }
  }
};
