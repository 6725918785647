export default {
  props: ['title', 'data'],
  data() {
    return {
      menuShow: false
    }
  },
  methods: {
    toggle: function() {
      this.menuShow = !this.menuShow;
    }
  }
}