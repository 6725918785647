import $http from "axios";

export default {
  fetchEvents({ commit }) {

    $http.get("/calendar").then(response => {
      commit('SET', { type: 'items', items: response.data.items })
    })
  }
};
