export default {
  computed: {
    config(){
      return this.$store.getters['marketplace/getGlobalConnfig']
    },
    captchaKey(){
      return '6Lf57bYUAAAAAIA46AcaBMi27R_FU00ZYCgnB5TE'
    }
  }
}
