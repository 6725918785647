import api from '@/lib/api';

const crudActions = (endpoint, show_with_index=true) => {

 const SetPage = ({ commit }, item) => {
    commit('SET', { type: 'page', items: item })
  }
  const SetSorting = ({ commit }, item) => {
    commit('SET', { type: 'sorting', items: item })
  }
  const SetFilters = ({ commit }, item) => {
    commit('SET', { type: 'filters', items: item })
  }
  const SetItemEdited = ({ commit }, item) => {
    commit('SET', { type: 'item_edited', items: item })
  }
  let List = ({ commit, getters, dispatch }) => {
    const params = {
      filters: getters.getFilters,
      page: getters.getPage,
      sorting: getters.getSorting,
    }

    return new Promise((resolve, reject) => {
      api.get(`/${endpoint}`,{ params: params }).then(response => { 
        const items = response.data.success ? response.data.items : []
        commit('SET', { type: 'items', items: items })
        commit('SET_LOCALSTORE', { type: 'last_items', items: items })
        commit('SET', {type: 'total', items: response.data.total })
        if (!response.data.success) {
          dispatch('marketplace/Failure', response.data.message, { root: true })
        }
        resolve(response.data)
      }, error => {
          reject(error);
        }
      ).catch(err => console.log(err.response))
    })
  }
  let Show = ({ commit, dispatch }, id) => {
    return new Promise((resolve, reject) => {
      api.get(`/${endpoint}/${show_with_index ? id : ''}`).then(response => {
        const item = response.data.success ? response.data.item : {}
        commit('SET', {type: 'item_current', items: item })
        if (!response.data.success) {
          dispatch('marketplace/Failure', response.data.message, { root: true })
        }
        resolve(response.data);
      }, error => {
          reject(error);
        }
      ).catch(err => console.log(err.response))
    })
  }
  let Edit = ({ commit, dispatch }, id) => {
    return new Promise((resolve, reject) => {
      api.get(`/${endpoint}/${id}/edit`).then(response => {
        const item = response.data.success ? response.data.item : {}
        commit('SET', {type: 'item_edited', items: item })
        if (!response.data.success) {
          dispatch('marketplace/Failure', response.data.message, { root: true })
        }
        resolve(response.data);
      }, error => {
          console.log(error);
        }
      ).catch(err => console.log(err.response))
    })
  }
  const Info = ({ commit, dispatch }, id ) => {
    return new Promise((resolve, reject) => {
      api.get(`/${endpoint}/${id}/info`).then(response => {
        if(response.data.success){
          commit('SET', {type: 'item_current', items: response.data.item })
        }
        else{
          commit('SET', {type: 'item_current', items: {} })
          dispatch('marketplace/Failure',response.data.message, {root: true})
        }
        resolve(response.data);
      }, error => {
          reject(error);
        }
      ).catch(err => console.log(err.response))
    })
  }
  let Save = ({ commit,dispatch }, item ) => {
    return new Promise((resolve, reject) => {
      let form = new FormData();
      if (item.file) { form.append('logo', item.file ) }
      for (const [key, value] of Object.entries(item)) { form.append(key, value ) }

      api[item.id ? 'put' : 'post'](`/${endpoint}/${item.id ? item.id : ''}`, form, { headers: { 'Content-Type': 'multipart/form-data' } }).then(response => {
        commit('SET', { type: 'item_saved', items: response.data.success })
        if (!response.data.success) {
          dispatch('marketplace/Failure', response.data.message, { root: true })
        }
        resolve(response.data);
      }, error => {
          reject(error);
        }
      ).catch(err => console.log(err.response))
    })
  }
  const Delete = ({ commit, getters }, id) => {
    return new Promise((resolve, reject) => {
      api.delete(`/${endpoint}/${id}`).then(response => {
        commit('SET', { type: 'item_deleted',items: response.data.success })
        resolve(response.data);
      }, error => {
          reject(error);
        }
      ).catch(err => console.log(err.response))
    })
  }


  return { SetPage, SetSorting, SetFilters, SetItemEdited, List, Show, Edit, Info, Save, Delete }
}

export default crudActions
