export default {
  getFilters(state) {
    return state.filters;
  },
  getPage(state) {
    return state.page;
  },
  getItemCurrent(state) {
    return state.item_current;
  },
  getItemNew(state){
    return state.item_new;
  },
  getUnconfirmedItem(state) {
    return state.item_unconfirmed;
  },
  getActivePoint(state) {
    return state.active_point;
  },
  getActiveCompany(state) {
    return state.active_company;
  },
  getActiveCustomer(state) {
    return state.active_customer;
  },
  getId(state) {
    return state.cart_id;
  },
  getItems(state) {
    return state.items;
  },
  getButtons(state) {
    return state.buttons;
  },
  getCartItems(state) {
    return state.cart_items;
  },
  getPaydeskItems(state) {
    return state.paydesk_items;
  },
  getPayment(state) {
    return state.payment_type;
  },
  getItemTotal(state) {
    return state.total;
  },
  getItemsCount(state) {
    let count = 0;
    Object.values(state.cart_items).forEach(function(item) {
      for (let parent_id in item) {
        count += Object.keys(item[parent_id]).length;
      }
    });
    return Number(count);
  },
  productIds(state) {
    let ids = [];
    Object.values(state.cart_items).forEach(function(item) {
      for (let parent_id in item) {
        ids.concat(Object.keys(item[parent_id]));
      }
    });
    return ids;
  },
  productIdsWithParents(state) {
    let ids = {};
    Object.values(state.cart_items).forEach(function(item) {
      for (let parent_id in item) {
        ids.concat(Object.keys(item[parent_id]));
      }
    });
    return ids;
  },
  getApiPayload(state) {
    let payload = { points: [] };
    for (let point_id in state.cart_items) {
      let items = [];
      for (let depot_id in state.cart_items[point_id]) {
        for (let id in state.cart_items[point_id][depot_id]) {
          let item = {
            id: id,
            count: state.cart_items[point_id][depot_id][id].quantity,
            unit: state.cart_items[point_id][depot_id][id].selected_unit.unit,
            unit_measure: state.cart_items[point_id][depot_id][id].selected_unit.unit_measure,
            price: state.cart_items[point_id][depot_id][id].price,
            product_price: state.cart_items[point_id][depot_id][id].product_price,
            payment_type: state.cart_items[point_id][depot_id][id].payment_type,
            additional_data: state.cart_items[point_id][depot_id][id].additional_data
          }
          if (state.cart_items[point_id][depot_id][id].payment_type == "mixed"){
            item["payment_parts"] = state.cart_items[point_id][depot_id][id].payment_parts
          }
          items.push(item);
        }
      }
      payload.points.push({ id: point_id, items: items });
    }

    return payload;
  },

  getPaydeskPayload(state) {
    const payload = []
    for(let item of Object.values(state.paydesk_items)) {
      payload.push({
        id: item.item.id,
        count: item.quantity,
        unit: item.selected_unit.unit,
        unit_measure: item.selected_unit.unit_measure,
        price: item.price,
        payment_type: item.payment_type,
      })
    }
    return payload;
  },

  getTotal(state) {
    let total = 0;
    Object.values(state.cart_items).forEach(function(item) {
      item = Object.values(item);
      for (var i = 0; i < item.length; i++) {
        for (var j = 0; j < item[i].length; j++) {
          total += item[i][j].product_features[0].price;
        }
      }
    });
    return Number(total);
  },
};
