import crudState from '@/store/crud/state'
const state = {
  ...crudState(),
  buttons: [],
  sorting: {
    direction: "DESC",
    field: "id"
  },
}
export default state
