export default {
  item_saved: false,
  colorPalette: {
    primary: '#cd1515',
    secondPrimary: '#E51453',
    layout: '#ffffff',
    body_bg: '#f6f7f9',
    menuHover: '#FCE7EE'
  }
   
}
