import crudActions from '@/store/crud/actions'
import $http from "axios";

const actions = {
  ...crudActions('tags'),
  New({ commit, dispatch }) {
    return new Promise((resolve, reject) => {
      $http
        .get(`/tags/new`)
        .then(
          (response) => {
            if (response.data.success) {
              commit("SET", {
                type: "item_new",
                items: response.data.item,
              });
            } else {
              commit("SET", { type: "item_new", items: {} });
              dispatch("marketplace/Failure", response.data.message, {
                root: true,
              });
            }
            resolve(response.data);
          },
          (error) => {
            reject(error);
          }
        )
        .catch((err) => console.log(err.response));
    });
  },
  setColorPalette({ commit }, item) {
    commit("SET_LOCALSTORE", { type: "colorPalette", items: item });
  }
}
export default actions