import crudGetters from '@/store/crud/getters'
const getters = {
  ...crudGetters(),
  getItemNew(state){
    return state.item_new;
  },
  getButtons(state) {
    return state.buttons;
  },
  getLogItems(state) {
    return state.log_items;
  }
}
export default getters