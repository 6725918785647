import $http from "axios";
import crudActions from '@/store/crud/actions'
const actions = {
  ...crudActions('bonuses'),
  ShowUserBonus({ commit, dispatch }, id) {
    return new Promise((resolve, reject) => {
      $http
        .get(`/bonuses/${id}/user_bonus`)
        .then(
          response => {
            if (response.data.success) {
              commit("SET", {
                type: "item_current",
                items: response.data.item
              });
            } else {
              commit("SET", { type: "item_current", items: {} });
              dispatch("marketplace/Failure", response.data.message, {
                root: true
              });
            }
            resolve(response.data);
          },
          error => {
            reject(error);
          }
        )
        .catch(err => console.log(err.response));
    });
  },
  ListUserBonuses({ commit, getters, dispatch }, user_id) {
    let filters = {};
    for (let [key, value] of Object.entries(getters.getFilters)) {
      if (value && typeof value == "object") {
        filters[key] = value.id;
      } else {
        filters[key] = value;
      }
    }

    let params = {
      filters: filters,
      page: getters.getPage,
      sorting: getters.getSorting,
    };

    if (user_id){
      params["user_id"] = user_id
    }

    return new Promise((resolve, reject) => {
      $http
        .get(`/bonuses/user`, { params: params })
        .then(
          (response) => {
            if (response.data.success) {
              commit("SET", { type: "items", items: response.data.items });
              commit("SET", { type: "total", items: response.data.total });

            } else {
              commit("SET", { type: "items", items: [] });
              commit("SET", { type: "total", items: 1 });
              dispatch("marketplace/Failure", response.data.message, {
                root: true,
              });
            }
            resolve(response.data);
          },
          (error) => {
            reject(error);
          }
        )
        .catch((err) => console.log(err.response));
    });
  }
}
export default actions
