import crudActions from '@/store/crud/actions'
import $http from "axios";

const actions = {
  ...crudActions('show_products'),
  List({ commit }) {
    return new Promise((resolve, reject) => {
      $http.get(`/show_products/`)
        .then(
          (response) => {
            if (response.data.success) {
              commit("SET_SESSIONSTORE", { type: "show_products", items: response.data.items });
            } else {
              commit("SET_SESSIONSTORE", { type: "show_products", items: [] });
            }
            resolve(response.data);
          },
          (error) => {
            reject(error);
          }
        )
        .catch((err) => console.log(err.response));
    });
  },
  Up({ commit, dispatch }, id) {
    return new Promise((resolve, reject) => {
      $http.post(`/show_products/${id}/up`)
        .then(
          (response) => {
            if (response.data.success) {
              commit("SET", { type: "item_saved", items: {} });
              resolve(response.data);
            } else {
              commit("SET", { type: "item_saved", items: {} });
              dispatch("marketplace/Failure", response.data.message, {
                root: true,
              });
            }
          },
          (error) => {
            console.log(error);
          }
        )
        .catch((err) => console.log(err.response));
    });
  },
  Down({ commit, dispatch }, id) {
    return new Promise((resolve, reject) => {
      $http.post(`/show_products/${id}/down`)
        .then(
          (response) => {
            if (response.data.success) {
              commit("SET", { type: "item_saved", items: {} });
              resolve(response.data);
            } else {
              commit("SET", { type: "item_saved", items: {} });
              dispatch("marketplace/Failure", response.data.message, {
                root: true,
              });
            }
          },
          (error) => {
            console.log(error);
          }
        )
        .catch((err) => console.log(err.response));
    });
  },
}
export default actions