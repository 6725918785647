<template lang="pug">
  b-modal(
    :title="translate('common.select_category_title')"
    id="select-category"
    centered
    dialog-class="popup-default popup-default--big"
    content-class="popup-default--content select-category"
    header-class="popup-default--header"
    body-class="popup-default--body"
    hide-footer
    ref="categoryModalRef"
    )
    .category-block
      .category-block--item
        ProductNav(:items="items" v-on:select="select" :withSelect="true")
      .category-block--item
        ProductNavSubmenu(:items="subSelected" v-on:selectItem="selectItem" :selected="selectedCategory")
      .category-block--item
        ProductNavSubmenu(:items="lastSelected" v-on:selectItem="selectItem" :selected="selectedCategory")
    .btn-block
      p {{translate("common.selected_category")}}:
        span.red(v-if="selectedCategory.id")
          |  {{selectedCategory.title}}
</template>

<script>
import ProductNav from '@/components/layout/category-menu/product-nav/ProductNav';
import ProductNavSubmenu from '@/components/layout/category-menu/product-nav-submenu/ProductNavSubmenu';

export default {
  inject: ['$validator'],
  props:["items"],
  components: {
    ProductNav,
    ProductNavSubmenu
  },
  computed: {
    currentCategory: {
      get(){
        return this.$store.getters["marketplace/getCurrentCategory"];
      },
      set(val){
        this.$store.dispatch('marketplace/setCurrentCategory', val);
      }
    },
  },
  
  methods: {
    clear(){
      this.selectedCategory = {}
      this.subSelected = []
      this.lastSelected = []
    },
    select(item){
      this.clear()
      if (item.children && item.children.length) {
        this.subSelected = item.children;
      } else {
        this.selectedCategory = item
      }
    },
    selectItem(item){
      if (item.children && item.children.length) {
        this.lastSelected = item.children;
      } else {
        this.selectedCategory = item
        this.currentCategory = item;
      }
    },
  },
  watch: {
    currentCategory(val) {
      if (val) {
        this.$emit("input", val);
        this.$refs.categoryModalRef.hide();
      }
    }
  },
  data() {
    return {
      selectedCategory: {},
      subSelected: [],
      lastSelected: []
    }
  }
}
</script>

<style lang="scss" scoped>
  .side-nav {
    display: block;
  }
</style>
