import AddProductTable from "@/components/layout/add-product-table/AddProductTable";
import AddProductTableItem from "@/components/layout/add-product-table-item/AddProductTableItem";
export default {
  props: ["product"],
  components: {
    AddProductTable,
    AddProductTableItem,
  },
  computed: {
    inCart() {
      this.cartItems[this.product.depot_id] =
        this.cartItems[this.product.depot_id] || {};
      return (
        Object.keys(this.cartItems[this.product.depot_id])
          .map((i) => Number(i))
          .filter((value) =>
            this.product.items.map((i) => Number(i.id)).includes(value)
          ).length > 0
      );
    },
  },
  data() {
    return {};
  },
};
