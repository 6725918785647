<template lang="pug">
  b-modal(
    :title="translate('common.delete_product')"
    id="delete-product-popup"
    centered
    dialog-class="popup-default popup-default--medium"
    content-class="popup-default--content"
    header-class="popup-default--header"
    body-class="popup-default--body"
    hide-footer
    )
    form
      .default-table
        .default-table--heading.d-none.d-md-flex
          .logo
          .name {{translate('common.product_name')}}
          .check-block
            input.form-checkbox(type="checkbox" id="all" v-model="all")
            label.form-checkbox__label(for="all")
        .default-table--item-wrapper(v-for="item in items")
          .default-table--item
            .logo
              .image-block
                img(:src="item.logo")
            .name
              span.form-label.d-block.d-md-none {{translate('common.product_name')}}
              div
                strong {{ item.title }}
              span.sku(v-tooltip.top="item.sku") {{ item.sku }}
            .check-block
              input.form-checkbox(type="checkbox" :id="'delete_'+item.id" v-model="delete_items[item.id]")
              label.form-checkbox__label(:for="'delete_'+item.id")
      button.btn.btn__transparent.btn__small(@click.prevent="deleteItems") {{translate('common.delete')}}
        
      

</template>

<script>
export default {
  props: ["items"],
  methods:{
    deleteItems(){
      let ids = Object.keys(this.delete_items).filter(x => this.delete_items[x])
      if (!ids.length){
        this.$store.dispatch('marketplace/Failure', this.translate('common.select_items'))
      } else {
        this.$emit("massDelete", ids)
      }
    }
  },
  watch: {
    all(val){
      this.delete_items = this.items.reduce(function(map, obj) {
          map[obj.id] = val;
          return map;
      }, {});
    }
  },
  data() {
    return {
      delete_items: {},
      all: false
    }
  }
}
</script>

<style lang="scss" scoped>
.image-block {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  max-width: 100px;
  height: 50px;
  margin-right: 5px;
  overflow: hidden;
  img {
    width: 100%;
    height: auto;
  }
}

.name {
  flex: 2;
}

.sku {
  display: inline-block;
  max-width: 120px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 12px;
  color: var(--corp_grey);
}

.actions {
  display: flex;
  justify-content: center;
}

.check-block {
  display: flex;
  justify-content: flex-end;
}

.default-table {
  &--item {
    display: flex;
    padding: 5px 10px;
    &--mobile {
      display: none;
    }
  }
}

@media (max-width: 767.98px) {
  .default-table {
    &--item {
      flex-direction: row;
      flex-wrap: wrap;
      & > *:not(.context-menu) {
        flex: none;
        width: 50%;
      }
      &-wrapper {
        height: auto;
        padding: 0;
        overflow: visible;
      }
      .check-block {
        position: absolute;
        top: calc(50% - 8px);
        right: 0;
        width: auto;
      }
    }
  }
  &/deep/.context-menu {
    top: 0;
    right: 0;;
  }
}

@media (max-width: 400.98px) {
  .image-block {
    max-width: 100px;
    height: 50px;
  }
  .default-table {
    &--item {
      display: none;
      &--mobile {
        display: flex;
        width: 100%;
        padding: 10px 0;
        font-size: 12px;
        & > * {
          width: 50%;
        }
        .text {
          & > * {
            margin-bottom: 10px;
            &:last-child {
              margin-bottom: 0;
            }
          }
        }
        .check-block {
          position: absolute;
          top: calc(50% - 8px);
          left: 10px;
          width: auto;
        }
      }
    }
  }
}
</style>
