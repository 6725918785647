import $http from "axios";
import crudActions from '@/store/crud/actions'

const actions = {
  ...crudActions('notes'),
  CreateOrder({ commit, dispatch }, id) {
    return new Promise((resolve, reject) => {
      $http(`/notes/${id}/create_order`)
        .then(
          response => {
            if (response.data.success) {
              commit("SET", { type: "item_edited", items: {} });
              resolve(response.data);
            } else {
              commit("SET", { type: "item_edited", items: {} });
              dispatch("marketplace/Failure", response.data.message, {
                root: true
              });
            }
          },
          error => {
            console.log(error);
          }
        )
        .catch(err => console.log(err.response));
    });
  }
}
export default actions

