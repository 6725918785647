import ProductItemInfo from "@/components/marketplace/product-item-info/ProductItemInfo";
import LastBid from "@/components/layout/last-bid/LastBid";
export default {
  props: ['product'],
  data() {
    return {
      showBid: false
    }
  },
  methods: {
    myOffers(){
      this.$store.dispatch("products/SetActiveItem",this.product)
      this.$isLoading('isLoading', 'my_items');
      this.$store.dispatch('products/My').then(response => {
        if(response.success){
          this.$bvModal.show("send-my-offers")
        }
        else{
          this.$store.dispatch('marketplace/Failure',response.message)
        }
      })
    },
    bidItem(){
      this.$store.dispatch("products/SetActiveItem",this.product)
      this.$bvModal.show("bid-popup")
    },
    mouseOver(product){
      this.showBid = true
    },
    mouseLeave(product){
      this.showBid = false
    }
  },
  components: {
    ProductItemInfo,
    LastBid,
  }
}