import $http from "axios";
import crudActions from '@/store/crud/actions'

const actions = {
  ...crudActions('nova_posts'),
  SetRecipientCities({ commit }, item) {
    commit("SET", { type: "recipient_cities", items: item });
  },
  SetSenderCities({ commit }, item) {
    commit("SET", { type: "sender_cities", items: item });
  },
  ClearSelected({ commit }) {
    commit("SET", { type: "selected_items", items: {} });
  },
  Save({ commit, dispatch }, item) {
    return new Promise((resolve, reject) => {
      $http.post(`/nova_posts`, item)
        .then(
          response => {
            commit("SET", { type: "item_saved", items: response.data.success });
            if (response.data.success) {
            } else {
              dispatch("marketplace/Failure", response.data.message, {
                root: true
              });
            }
            resolve(response.data);
          },
          error => {
            reject(error);
          }
        )
        .catch(err => console.log(err.response));
    });
  },
  GetCities({ commit, dispatch }, { city, user_type }) {
    return new Promise((resolve, reject) => {
      $http
        .get(`/nova_posts/get_cities`, { params: { city: city } })
        .then(
          (response) => {
            if (response.data.success) {
              commit("SET", {
                type: `${user_type}_cities`,
                items: response.data.items,
              });
            } else {
              commit("SET", { type: `${user_type}_cities`, items: [] });
              dispatch("marketplace/Failure", response.data.message, {
                root: true,
              });
            }
            resolve(response.data);
          },
          (error) => {
            reject(error);
          }
        )
        .catch((err) => console.log(err.response));
    });
  },
  GetSender({ commit, dispatch }) {
    return new Promise((resolve, reject) => {
      $http
        .get(`/nova_posts/get_sender`)
        .then(
          (response) => {
            if (response.data.success) {
              commit("SET", {
                type: "senders",
                items: response.data.items,
              });
            } else {
              dispatch("marketplace/Failure", response.data.message, {
                root: true,
              });
            }
            resolve(response.data);
          },
          (error) => {
            reject(error);
          }
        )
        .catch((err) => console.log(err.response));
    });
  },
  GetStreets({ commit, dispatch }, { city_ref, user_type, street }) {
    return new Promise((resolve, reject) => {
      $http
        .get(`/nova_posts/${city_ref}/get_streets`, {
          params: { street: street },
        })
        .then(
          (response) => {
            if (response.data.success) {
              commit("SET", {
                type: `${user_type}_streets`,
                items: response.data.items,
              });
            } else {
              commit("SET", { type: `${user_type}_streets`, items: [] });
              dispatch("marketplace/Failure", response.data.message, {
                root: true,
              });
            }
            resolve(response.data);
          },
          (error) => {
            reject(error);
          }
        )
        .catch((err) => console.log(err.response));
    });
  },
  CreateInvoice({ commit, dispatch }, params) {
    return new Promise((resolve, reject) => {
      $http
        .post(`/nova_posts/create_invoice`, params)
        .then(
          (response) => {
            if (response.data.success) {
              commit("SET", {
                type: `item_saved`,
                items: response.data.success,
              });
            } else {
              commit("SET", { type: `item_saved`, items: {} });
              dispatch("marketplace/Failure", response.data.message, {
                root: true,
              });
            }
            resolve(response.data);
          },
          (error) => {
            reject(error);
          }
        )
        .catch((err) => console.log(err.response));
    });
  },
  GetWarehouses({ commit, dispatch }, { city_ref, user_type, poshtomat }) {
    return new Promise((resolve, reject) => {
      $http
        .get(`/nova_posts/${city_ref}/get_warehouses?poshtomat=${!!poshtomat}`)
        .then(
          (response) => {
            if (response.data.success) {
              commit("SET", {
                type: `${user_type}_warehouses`,
                items: response.data.items,
              });
            } else {
              commit("SET", { type: `${user_type}_warehouses`, items: [] });
              dispatch("marketplace/Failure", response.data.message, {
                root: true,
              });
            }
            resolve(response.data);
          },
          (error) => {
            reject(error);
          }
        )
        .catch((err) => console.log(err.response));
    });
  },
  DeleteInvoices({ commit, dispatch }, ids) {
    return new Promise((resolve, reject) => {
      $http.post(`/nova_posts/delete_invoice`, {ids: ids})
        .then(
          (response) => {
            commit("SET", { type: "item_saved", items: response.data });
            if (response.data.success) {
              resolve(response.data);
            } else {
              dispatch("marketplace/Failure", response.data.message, {
                root: true,
              });
            }
          },
          (error) => {
            console.log(error);
          }
        )
        .catch((err) => console.log(err.response));
    });
  },
  ToRegister({ commit, dispatch }, ids) {
    return new Promise((resolve, reject) => {
      $http.post(`/nova_posts/add_to_register`, {ids: ids})
        .then(
          (response) => {
            commit("SET", { type: "item_saved", items: response.data });
            if (response.data.success) {
              resolve(response.data);
            } else {
              dispatch("marketplace/Failure", response.data.message, {
                root: true,
              });
            }
          },
          (error) => {
            console.log(error);
          }
        )
        .catch((err) => console.log(err.response));
    });
  },
  RemoveFromRegister({ commit, dispatch }, ids) {
    return new Promise((resolve, reject) => {
      $http.post(`/nova_posts/remove_from_register`, {ids: ids})
        .then(
          (response) => {
            commit("SET", { type: "item_saved", items: response.data });
            if (response.data.success) {
              resolve(response.data);
            } else {
              dispatch("marketplace/Failure", response.data.message, {
                root: true,
              });
            }
          },
          (error) => {
            console.log(error);
          }
        )
        .catch((err) => console.log(err.response));
    });
  },
  GetInvoices({ commit, getters, dispatch }) {

    let params = {
      filters: getters.getFilters,
      page: getters.getPage,
      sorting: getters.getSorting,
    };

    return new Promise((resolve, reject) => {
      $http.get(`/nova_posts/get_invoices`, { params: params })
      .then(
        response => {
          commit("SET", { type: "item_list", items: response.data.items });
          commit("SET", {type: "total", items: response.data.total })
          if (!response.data.success) {
            dispatch('marketplace/Failure', response.data.message, { root: true })
          }
          resolve(response.data);
        },
        error => {
          reject(error);
        }
      )
      .catch(err => console.log(err.response));
    });
  },
}
export default actions
