import Vue from 'vue';
const eventBus = new Vue();
import MessageCounter from "@/components/ui/message-counter/MessageCounter";
export default {
  props: ['item'],
  components: {
    MessageCounter
  },
  data() {
    return {
      menuShow: false,
    }
  },
  // created(){
  //   if(this.item.children != undefined && this.item.children.find(st => st.path === this.$route.name)){
  //     this.menuShow = true
  //   }
    
  //   this.eventHub.$on('toggle', item => {
  //     if(this.item.icon != item.icon){
  //       this.menuShow = false
  //     }

  //   })
  // },
  // methods: {
  //   toggle: function() {
  //     if(this.item.children != undefined) {
  //       this.eventHub.$emit('toggle',this.item)
  //       this.menuShow = !this.menuShow;
  //     } else {
  //       this.menuShow = true
  //     }
  //   }
  // }
  created(){
    eventBus.$on('close', () => {
      this.closeMenu()
    });
  },
  methods: {
    // goTo(item_path) {
    //   if(item_path) {
    //     this.$router.push({name: item_path})
    //   }
    // },
    openMenu() {
      eventBus.$emit('close')
      this.menuShow = true
    },
    closeMenu() {
      this.menuShow = false;
    }
  },
  computed: {
    notifications() {
      return this.$store.getters["notifications/getNotifications"];
    },
    main_notification(){
      if (!this.item.children){
        return this.notifications[this.item.path]
      } else {
        return this.item.children.some(obj => (this.notifications[obj.path] || 0) > 0) ? "!" : null
      }
    }
  },
}