<template lang="pug">
  div
    h1 {{ categories.title }}
    .subcategories-block
      .row
        .col-xl-3.col-lg-6.col-md-4.col-sm-12.col-12(v-for="item in subSelected")
          .category-item(
            @click.prevent="select(item)"
          )
            .category-item--img
              img(:src="item.logo", v-if="item.logo")
              img(
                src="https://www.pngrepo.com/png/221498/180/no-photo.png",
                v-else
              )
            .category-item--title {{item.title}}
</template>

<script>

export default {
  data() {
    return {
      subSelected: []
    }
  },
  watch: {
    categories(val) {
      if (val && val.children){
        this.subSelected = this.categories.children;

      }
    }
  },
  computed: {
    categories() {
      return this.$store.getters["marketplace/getCurrentCategory"];
    }
  },
  mounted() {
    this.subSelected = this.categories.children;
  },
  methods: {
    select(item) {
      this.$store.dispatch('marketplace/setCurrentCategory', item);
      if (item.children && item.children.length) {
        this.subSelected = item.children;
      } else {
        let params = { query: Object.assign({}, this.$route.query, {category_id: item.id} ) }
        params["path"] = `/`
        this.$router.push(params);
      }
    },
  }
}

</script>

<style lang="scss" scoped>
  .subcategories-block {
    margin-top: 20px;
  }

  .category-item {
    // padding: 5px;
    text-align: center;
    background: var(--white);
    box-shadow: 0px 0px 1px 0px rgba(50, 50, 50, 0.3);
    margin-bottom: 15px;
    &--img {
      padding: 10px 0;
    }
    &--title {
      padding: 10px 0;
      font-size: 16px;
      font-weight: 500;
    }
  }
</style>

