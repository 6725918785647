export default {
  inject: ['$validator'],
  watch: {
    inputVal(val) {
      this.$emit('input', val);
    },
    value(val) {
      this.inputVal = val
    }
  },
  methods:{
    onlyNumber: function(evt) {
      this.$root.onlyNumber(evt)
    },
    getType(){
      if(this.numbers){
        return "number"
      }
      else{
        return "text"
      }
    }
  },
  data() {
    return { inputVal: this.value }
  },
  props: [ 'options', 'customClass', 'placeholder', 'value' ,'numbers' , 'disabled', 'name', 'submitted', 'validation']
}