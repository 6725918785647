export default {
  data() {
    return {
      phone: null,
      parentComponent: null,
      submitted: false,
      tabs: ['login', 'phone'],
      active_nav: 'login',
      login: {
        phone: null,
        email: null,
        password: null,
      },
      roles: [],
    };
  },
  methods: {
    onUpdate(e){
      this.login.phone = e.formattedNumber.replace(/\+/g, '')
      if(e.isValid){
        this.phoneIsValid = true
        this.errors.remove('phone')
      }
      else{
        this.phoneIsValid = false
      }
      return e;
    },
    onCaptchaExpired(e) {
      console.log(e);
    },
    verifyCaptcha(e) {
      console.log(e);
    },
    showResore() {
      this.$store.dispatch("account/AddItem", {
        type: "auth",
        items: "restore",
      });
    },
    showRegister() {
      this.$store.dispatch("account/AddItem", {
        type: "auth",
        items: "register",
      });
    },
    getPayload(){
      return {
        login_with: this.active_nav,
        device: this.gon.config.device, 
        role: this.login.role || "", 
        login: this.active_nav == 'login' ? this.login.email : this.login.phone,
        password: this.login.password
      }
    },
    submit(e) {
      this.submitted = true;
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$isLoading("isLoading", "item_current");
          window.localStorage.clear();
          window.sessionStorage.clear();
          this.$store
            .dispatch("users/Login", { user: this.getPayload() })
            .then((response) => {
              if (response.success) {
                if (response.roles) {
                  this.roles = response.roles;
                } else {
                  if (this.user.is_client) {
                    this.$store.dispatch("cart/FindNotConfirmed");
                  }
                  this.$router.push(response.item.default_route)
                }
              }
            });
        }
      });
    },
  },
};
