import crudState from '@/store/crud/state'
const state = {
  ...crudState(),
  active_item_type: "products",
  item_searched: [],
  filtered_ids: [],
  item_edited: {
    prices: [
      {
        unit: null,
        price: null,
        sale_price: null,
        unit_measure: null,
        unit_capicity: null,
        on_sale: false,
      },
    ],
  },
  active_item: {
    bids: [],
  },
  full_item: {
    bids: [],
    images: [],
  },
  item_deleted: false,
  full_items: [],
  my_items: [],
  total: 0,
  filters: {
    price: [0, 100000], 
    permissions: [],
    payment_types: [],
    category: {},
    characteristics: {},
    delivery: [],
    origins: [],
    title: "",
    parent_id: 0,
  },
  sorting: {
    direction: "DESC",
    field: "shop_products|updated_at",
  },
  selected_items: {},
}
export default state
