import crudGetters from '@/store/crud/getters'
const getters = {
  ...crudGetters(),
  getChooseMode(state){
    return state.choose_is_active;
  },
  getActiveType(state){
    return state.active_type;
  }
}
export default getters