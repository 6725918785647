import crudGetters from '@/store/crud/getters'
const getters = {
  ...crudGetters(),
  getItemNew(state){
    return state.item_new;
  },
  getItemsCurrent(state){
    return state.items_current;
  },
  getKanbanItems(state){
    return state.kanban_items;
  }
}
export default getters
