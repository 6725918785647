import crudGetters from '@/store/crud/getters'
const getters = {
  ...crudGetters(),
  getContacts(state) {
    return state.contacts;
  },
  getItemNew(state){
    return state.item_new;
  },
  getModulesAvailable(state){
    return state.modules_available;
  }
}
export default getters
