import crudGetters from '@/store/crud/getters'
const getters = {
  ...crudGetters(),
  getItemNew(state) {
    return state.item_new;
  },
  getFilters(state) {
    return state.filters;
  },
  getItem(state) {
    return state.item;
  },
  getPage(state) {
    return state.page || 1;
  },
  getTotal(state) {
    return state.total;
  },
  getNotifications(state) {
    return state.notifications;
  },
}
export default getters
