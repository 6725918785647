import crudGetters from '@/store/crud/getters'
const getters = {
  ...crudGetters(),
  getActiveItemType(state){
    return state.active_item_type;
  },
  getActiveType(state){
    return state.active_type;
  }
}
export default getters

  

