import $http from "axios";
import crudActions from '@/store/crud/actions'
const actions = {
  ...crudActions('diagrams'),
  setDiagramCategories({ commit }, item) {
    commit("SET_SESSIONSTORE", { type: "diagram_categories", items: item });
  },
  Categories({ commit, dispatch }) {
    return new Promise((resolve, reject) => {
      $http(`/diagrams/categories`)
        .then(
          response => {
            if (response.data.success) {
              commit("SET_SESSIONSTORE", { type: "diagram_categories", items: response.data.items });
              resolve(response.data);
            } else {
              commit("SET_SESSIONSTORE", { type: "item_edited", items: {} });
              dispatch("marketplace/Failure", response.data.message, {
                root: true
              });
            }
          },
          error => {
            console.log(error);
          }
        )
        .catch(err => console.log(err.response));
    });
  },
  Show({ commit, dispatch, getters }, id) {
    const params = {
      filters: getters.getFilters,
      page: getters.getPage,
      sorting: getters.getSorting,
    }
    return new Promise((resolve, reject) => {
      $http(`/diagrams/${id}`, {params: params})
        .then(response => {
        const item = response.data.success ? response.data.item : {}
        commit('SET', {type: 'item_current', items: item })
        if (!response.data.success) {
          dispatch('marketplace/Failure', response.data.message, { root: true })
        }
        resolve(response.data);
      }, error => {
          reject(error);
        }
      ).catch(err => console.log(err.response))
    })
  }
}
export default actions
