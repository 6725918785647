import ProductTableItem from "@/components/platform/marketplace/offer/product-table-item/ProductTableItem";

export default {
  props: ["items"],
  components: {
    ProductTableItem
  },
  data(){
    return {
      isPayment: false,
    }
  },
  created(){

  }
}
