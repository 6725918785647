import $http from "axios";
import crudActions from '@/store/crud/actions'
const actions = {
  ...crudActions('routes'),
  List({ commit, getters, dispatch }) {
    let filters = getters.getFilters;
    filters["date"] = filters["date"] || new Date().toJSON().slice(0, 10);
    let params = {
      filters: filters,
      page: getters.getPage,
      sorting: getters.getSorting,
    };

    return new Promise((resolve, reject) => {
      $http
        .get(`/routes`, { params: params })
        .then(
          (response) => {
            if (response.data.success) {
              commit("SET", { type: "items", items: response.data.items });
            } else {
              commit("SET", { type: "items", items: [] });
              dispatch("marketplace/Failure", response.data.message, {
                root: true,
              });
            }
            resolve(response.data);
          },
          (error) => {
            reject(error);
          }
        )
        .catch((err) => console.log(err.response));
    });
  },
  PrintRoute({ date }) {
    $http
      .get(`/routes/print_route`, { date: date })
  },
  TradingShow({ commit, dispatch }, { id }) {
    return new Promise((resolve, reject) => {
      $http
        .get(`/routes/${id}/trading`)
        .then(
          (response) => {
            if (response.data.success) {
              commit("SET", { type: "item_edited", items: response.data.item });
              resolve(response.data);
            } else {
              commit("SET", { type: "item_edited", items: {} });
              dispatch("marketplace/Failure", response.data.message, {
                root: true,
              });
            }
            resolve(response.data);
          },
          (error) => {
            reject(error);
          }
        )
        .catch((err) => console.log(err.response));
    });
  },
  Edit({ commit, dispatch }, params) {
    return new Promise((resolve, reject) => {
      $http(`/routes/${params.id}/edit`, {params: params})
        .then(
          (response) => {
            if (response.data.success) {
              commit("SET", { type: "item_edited", items: response.data.item });
              resolve(response.data);
            } else {
              commit("SET", { type: "item_edited", items: {} });
              dispatch("marketplace/Failure", response.data.message, {
                root: true,
              });
            }
          },
          (error) => {
            console.log(error);
          }
        )
        .catch((err) => console.log(err.response));
    });
  },
  UpdateItem({ commit, dispatch }, params) {
    return new Promise((resolve, reject) => {
      $http.put(`/routes/${params.id}/update_item`, params)
        .then(
          (response) => {
            if (response.data.success) {
              commit("SET", { type: "item_saved", items: response.data.success });
              resolve(response.data);
            } else {
              commit("SET", { type: "item_saved", items: response.data.success });
              dispatch("marketplace/Failure", response.data.message, {
                root: true,
              });
            }
          },
          (error) => {
            console.log(error);
          }
        )
        .catch((err) => console.log(err.response));
    });
  },
  Update({ commit, dispatch }, { id, params }) {
    return new Promise((resolve, reject) => {
      $http
        .put(`/routes/${id}`, {
          items: params,
        })
        .then(
          (response) => {
            if (response.data.success) {
              commit("SET", {
                type: "item_saved",
                items: response.data.success,
              });
              resolve(response.data);
            } else {
              commit("SET", {
                type: "item_saved",
                items: {},
              });
              dispatch("marketplace/Failure", response.data.message, {
                root: true,
              });
            }
          },
          (error) => {
            console.log(error);
          }
        )
        .catch((err) => console.log(err.response));
    });
  },
  Start({ commit, dispatch }, { id, status }) {
    return new Promise((resolve, reject) => {
      $http(`/routes/${id}/${status}`)
        .then(
          (response) => {
            if (response.data.success) {
              commit("SET", {
                type: "item_saved",
                items: response.data.success,
              });
              resolve(response.data);
            } else {
              commit("SET", { type: "item_saved", items: {} });
              dispatch("marketplace/Failure", response.data.message, {
                root: true,
              });
            }
          },
          (error) => {
            console.log(error);
          }
        )
        .catch((err) => console.log(err.response));
    });
  },
  Confirm({ commit, dispatch }, params) {
    return new Promise((resolve, reject) => {
      $http(`/routes/${params.id}/confirm`, {params:params})
        .then(
          (response) => {
            if (response.data.success) {
              commit("SET", {
                type: "item_saved",
                items: response.data.success,
              });
              resolve(response.data);
            } else {
              commit("SET", { type: "item_saved", items: {} });
              dispatch("marketplace/Failure", response.data.message, {
                root: true,
              });
            }
          },
          (error) => {
            console.log(error);
          }
        )
        .catch((err) => console.log(err.response));
    });
  },
  TimeTable({ commit, dispatch }, { date, startable_id }) {
    return new Promise((resolve, reject) => {
      $http(`/routes/timetable?date=${date}&startable_id=${startable_id}`)
        .then(
          (response) => {
            commit("SET", {
              type: "timetable",
              items: response.data.items || {},
            });
          },
          (error) => {
            console.log(error);
          }
        )
        .catch((err) => console.log(err.response));
    });
  }
}
export default actions