import crudGetters from '@/store/crud/getters'
const getters = {
  ...crudGetters(),
  getSenders(state){
    return state.senders;
  },
  getSenderCities(state) {
    return state.sender_cities;
  },
  getRecipientCities(state) {
    return state.recipient_cities;
  },
  getSelected(state){
    return state.selected_items;
  },
  getRecipientWarehouses(state) {
    return state.recipient_warehouses;
  },
  getRecipientStreets(state) {
    return state.recipient_streets;
  },
  getSenderWarehouses(state) {
    return state.sender_warehouses;
  },
  getSenderStreets(state) {
    return state.sender_streets;
  },
  getItemList(state) {
    return state.item_list;
  }
}
export default getters
