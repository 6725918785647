import $http from "axios";
import crudActions from '@/store/crud/actions'
const actions = {
  ...crudActions('telegram_users'),
  Code({ commit, dispatch }, id) {
    return new Promise((resolve, reject) => {
      $http(`/telegram_users/${id}/code`)
        .then(
          (response) => {
            if (response.data.success) {
              commit("SET", { type: "item_saved", items: {} });
              resolve(response.data);
            } else {
              commit("SET", { type: "item_saved", items: {} });
              dispatch("marketplace/Failure", response.data.message, {
                root: true,
              });
            }
          },
          (error) => {
            console.log(error);
          }
        )
        .catch((err) => console.log(err.response));
    });
  },
}
export default actions
