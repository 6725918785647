import $http from "axios";
import crudActions from '@/store/crud/actions'

const actions = {
  ...crudActions('payments'),
  New({ commit, dispatch }) {
    return new Promise((resolve, reject) => {
      $http
        .get(`/payments/new`)
        .then(
          (response) => {
            if (response.data.success) {
              commit("SET", {
                type: "item_new",
                items: response.data.item,
              });
            } else {
              commit("SET", { type: "item_new", items: {} });
              dispatch("marketplace/Failure", response.data.message, {
                root: true,
              });
            }
            resolve(response.data);
          },
          (error) => {
            reject(error);
          }
        )
        .catch((err) => console.log(err.response));
    });
  },
  GetPnlData({ commit, dispatch }) {
    return new Promise((resolve, reject) => {
      $http
        .get(`/payments/pnl_data`)
        .then(
          (response) => {
            if (response.data.success) {
              commit("SET", {
                type: "item_pnl",
                items: response.data.item,
              });
            } else {
              commit("SET", { type: "item_pnl", items: [] });
              dispatch("marketplace/Failure", response.data.message, {
                root: true,
              });
            }
            resolve(response.data);
          },
          (error) => {
            reject(error);
          }
        )
        .catch((err) => console.log(err.response));
    });
  },
  List({ commit, getters, dispatch }) {
    let filters = getters.getFilters;
    let send_filters = {};
    for (let key of Object.keys(filters)) {
      if (filters[key] && typeof filters[key] == "object" && !Array.isArray(filters[key])) {
        send_filters[key] = filters[key].id;
      } else {
        send_filters[key] = filters[key];
      }
    }
    let params = {
      filters: send_filters,
      sorting: getters.getSorting,
      page: getters.getPage,
    };
    return new Promise((resolve, reject) => {
      $http
        .get(`/payments`, { params: params })
        .then(
          (response) => {
            if (response.data.success) {
              commit("SET", { type: "items", items: response.data.items });
              commit("SET", { type: "total", items: response.data.total });
            } else {
              commit("SET", { type: "items", items: [] });
              dispatch("marketplace/Failure", response.data.message, {
                root: true,
              });
            }
            resolve(response.data);
          },
          (error) => {
            reject(error);
          }
        )
        .catch((err) => console.log(err.response));
    });
  },
  ConfirmPayment({ commit, dispatch }, id) {
    return new Promise((resolve, reject) => {
      $http
        .get(`/payments/${id}/confirm_payment`)
        .then(
          (response) => {
            if (response.data.success) {
              commit("SET", {
                type: "item_saved",
                items: response.data.success,
              });
            } else {
              commit("SET", { type: "item_current", items: {} });
              dispatch("marketplace/Failure", response.data.message, {
                root: true,
              });
            }
            resolve(response.data);
          },
          (error) => {
            reject(error);
          }
        )
        .catch((err) => console.log(err.response));
    });
  }
}
export default actions