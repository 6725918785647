import $http from "axios";
import crudActions from '@/store/crud/actions'
const actions = {
  ...crudActions('clients'),
  SetItemSearched({ commit }, item) {
    commit("SET", { type: "searched_items", items: item });
  },
  Search({ commit, getters, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      $http
        .get(`/clients/search`, { params: payload })
        .then(
          response => {
            commit("SET", { type: "searched_items", items: [] });
            if (response.data.success) {
              const items = response.data.items.length ? response.data.items : [{ phone_number: payload.query }]
              commit("SET", { type: "searched_items", items: items });
            }
            resolve(response.data);
          },
          error => {
            reject(error);
          }
        )
        .catch(err => console.log(err.response));
    });
  }
}
export default actions

