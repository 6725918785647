import InfoList from "@/components/layout/info-list/InfoList";
import LastBid from "@/components/layout/last-bid/LastBid";
export default {
  props: ['product'],
  components: {
    InfoList,
    LastBid
  },
  data() {
    return {
      menuShow: false
    }
  },
  methods: {
    toggle: function() {
      this.menuShow = !this.menuShow
    }
  }
}