import SearchBar from "@/components/layout/search-bar/SearchBar";
import ProductNav from "@/components/layout/category-menu/product-nav/ProductNav";
import Filters from "@/components/layout/filters/Filters";
export default {
  props: ['showMenu', 'skipFilters'],
  components: {
    SearchBar,
    ProductNav,
    Filters
  },
  methods:{
    update(){
      this.$emit("update")
    }
  },
  computed:{
    categories(){
      return this.config.categories;
    },
    filters(){
      return this.config.filters
    }
  }
}