import crudActions from '@/store/crud/actions'
import $http from "axios";

const actions = {
  ...crudActions('invoices'),
  List({ commit, getters, dispatch }) {
    let filters = getters.getFilters;
    let send_filters = {};
    for (let key of Object.keys(filters)) {
      if (filters[key] && typeof filters[key] == "object" && !Array.isArray(filters[key])) {
        send_filters[key] = filters[key].id;
      } else {
        send_filters[key] = filters[key];
      }
    }
    let params = {
      filters: send_filters,
      page: getters.getPage,
      sorting: getters.getSorting,
    };
    return new Promise((resolve, reject) => {
      $http
        .get(`/invoices`, { params: params })
        .then(
          (response) => {
            if (response.data.success) {
              commit("SET", { type: "items", items: response.data.items });
            } else {
              commit("SET", { type: "items", items: [] });
              dispatch("marketplace/Failure", response.data.message, {
                root: true,
              });
            }
            resolve(response.data);
          },
          (error) => {
            reject(error);
          }
        )
        .catch((err) => console.log(err.response));
    });
  },
}
export default actions
