import ContextMenu from "@/components/ui/context-menu/ContextMenu";
import CheckBlock from "@/components/layout/check-block/CheckBlock";

export default {
  data() {
    return {
      checked: false,
      menuShow: false
    };
  },
  watch: {
    checked(val) {
      this.$store.dispatch("products/Select", { id: this.item.id, val: val });
    },
  },
  computed: {
    selected_items() {
      return this.$store.getters["products/getSelected"];
    },
  },
  props: [
    "item",
    "options",
    "hasImg",
    "hasHoverClass",
    "hasCheckBlock",
    "hasQuantity",
  ],
  components: {
    ContextMenu,
    CheckBlock,
  },
  methods: {
    toggle() {
      this.menuShow = !this.menuShow;
    },
    pushRoute() {
      if (!this.hasCheckBlock) {
        this.$router.push({ name: "product", params: { id: this.item.id } });
      }
    },
    contextClick({ key, item }) {
      this[key].apply(this, [item]);
    },
    edit() {
      this.$router.push({
        name: this.$route.meta.additional_offer ? "edit-offer" : "edit-product", 
        params: { id: this.item.id },
      });
    },
    add() {
      this.$router.push({
        name: this.$route.meta.additional_offer
          ? `add-new-suboffer`
          : `add-new-subproduct`,
        params: { id: this.item.id },
      });
    },
    list() {
      this.$router.push({
        name: this.$route.meta.additional_offer ? `sub-offers` : `sub-products`,
        params: {
          id: this.item.id,
        },
      });
    },
    delete() {
      if (this.item.items.length > 1){
        this.$emit("deleteProduct", this.item.items)
      } else {
        this.$confirm({
            message: this.translate('common.are_you_sure'),
            button: {
              no: this.translate('common.no_'),
              yes: this.translate('common.yes_')
          },
          callback: confirm => {
            if (confirm) {
              this.$isLoading("isLoading", "item_deleted");
              this.$store.dispatch("products/Delete", this.item.id).then(
                (response) => {
                  if (response.success) {
                    this.$isLoading("isLoading", "list");
                    this.$store.dispatch("products/List");
                  }
                },
              )
            }
          }
        })
      }
    }
  }
};
