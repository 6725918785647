import $http from "axios";

const actions = {
  Buttons({ commit, getters }) {
    return new Promise((resolve, reject) => {
      $http
        .get(`/cabinet/buttons`)
        .then(
          (response) => {
            if (response.data.success) {
              resolve(response.item);
              commit("SET", { type: "cabinet_buttons", items: response.data.item });
            } else {
              commit("SET", {
                type: "cabinet_buttons",
                items: getters["getItemCurrent"],
              });
            }
            resolve(response.item);
          },
          (error) => {
            reject(error);
          }
        )
        .catch((err) => console.log(err.response));
    });
  },
}
export default actions
