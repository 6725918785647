import $http from "axios";
import crudActions from '@/store/crud/actions'
const actions = {
  ...crudActions('tariffs'),
  CreateRequest({ commit }, id) {
    return new Promise((resolve, reject) => {
      $http
        .post(`/tariffs/${id}/create_request`)
        .then(
          (response) => {
            if (response.data.success) {
              commit("SET", { type: "item_saved", items: response.data.success });
            } else {
              commit("SET", { type: "item_editem_savedited", items: {} });
            }
            resolve(response.data);
          },
          (error) => {
            reject(error);
          }
        )
        .catch((err) => console.log(err.response));
    });
  },
}
export default actions
