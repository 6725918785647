import crudState from '@/store/crud/state'
const state = {
  ...crudState(),
  item_new: {},
  items_current: [],
  kanban_items: {},
  item_called: false,
  filters: {
    title:"",
    volumes: null,
  }
}
export default state
