import Vue from 'vue';
const eventBus = new Vue();
import { directive as onClickaway } from "vue-clickaway";
// import ProductNavSubmenu from '@/components/layout/ProductNavSubmenu';
import ProductNavSubmenuList from "@/components/layout/category-menu/product-nav-submenu-list/ProductNavSubmenuList";
export default {
  props: ["item", "path", "withSelect"],
  directives: {
    onClickaway: onClickaway,
  },
  components: {
    // ProductNavSubmenu,
    ProductNavSubmenuList,
  },
  data() {
    return {
      menuShow: false,
    };
  },
  created (){
    eventBus.$on('close', (data) => {
      this.closeMenu()
    })
  },
  methods: {
    setCategory(item) {
      this.$store.dispatch('marketplace/setCurrentCategory', item);
      let params = {
        query: Object.assign({}, this.$route.query, { category_id: item.id }),
      };
      if (this.$route.name == "product"){
        params["path"] = `/`
      }
      this.$router.push(params);
      this.menuShow = false;
      this.$emit("update");
    },
    openMenu(item) {
      if (this.withSelect) {
        this.$emit("select", item);
      } else if (item.children && item.children.length) {
        eventBus.$emit('close')
        this.menuShow = true;
      }
    },
    closeMenu() {
      this.menuShow = false;
    },
    setChildCategory(item) {
      this.$store.dispatch('marketplace/setCurrentCategory', item);
      if (!item.children) {
        this.$store.dispatch('products/SetPage', 1 );
        this.setCategory(item)
      }
    }
  },
};
