import crudGetters from '@/store/crud/getters'
const getters = {
  ...crudGetters(),
  getItems(state){
    return state.items;
  },
  getItem(state){
    return state.item;
  }
}
export default getters
