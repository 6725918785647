export default {
  props: ['items','selected'],
  methods: {
    select(item){
      this.eventHub.$emit('selectCategory', item)
      this.$emit("selectItem",item)
    }
  },
  created(){
    // this.eventHub.$on('selectCategory', item => {
    //   this.selected = item
    // })
  }
}