export default {
  props: ["item","path"],
  data() {
    return {
      menuShow: false
    }
  },
  methods: {
    setCategory(item) {
      this.setCurrentCategory(item)
      this.$store.dispatch('products/SetPage', 1 );
      let params = { query: Object.assign({}, this.$route.query, {category_id: item.id} ) }
      params["path"] = `/`
      this.$router.push(params);
      this.menuShow = false;
      this.$emit("update")
    },
    setCurrentCategory(item) {
      this.$store.dispatch('marketplace/setCurrentCategory', item);
    }
  }
}