import $http from 'axios';
import crudActions from '@/store/crud/actions'

const actions = {
  ...crudActions('conversations'),
  Clear({ commit },item){
    commit('SET', { type: 'items', items: [] })
  },
  Create({ commit }, recepient_id ){
    return new Promise((resolve, reject) => {
      $http.post(`/conversations`, {recepient_id: recepient_id} ).then(response => {
        commit('SET', {type: 'item_saved',items: true })
        resolve(response.data);
      }, error => {
          reject(error);
        }
      ).catch(err => console.log(err.response))
    })
  },
  AddMessage({ commit }, item ){ 
    return new Promise((resolve, reject) => {
      let form = new FormData();
      if (item.file) { form.append('file', item.file ) }
      for (const [key, value] of Object.entries(item)) { form.append(key, value ) }

      $http.post(`/conversations`, form, { headers: { 'Content-Type': 'multipart/form-data' } }).then(response => {
        commit('SET', {type: 'item_updated',items: true })
        resolve(response.data);
      }, error => {
          reject(error);
        }
      ).catch(err => console.log(err.response))
    })
  },
  SendMessage({ commit }, item ){ 
    return new Promise((resolve, reject) => {
      let form = new FormData();
      for (const [key, value] of Object.entries(item)) { form.append(key, value ) }

      $http.post(`/conversations/create_message`, form, { headers: { 'Content-Type': 'multipart/form-data' } }).then(response => {
        commit('SET', {type: 'item_updated',items: true })
        resolve(response.data);
      }, error => {
          reject(error);
        }
      ).catch(err => console.log(err.response))
    })
  }
}
export default actions
