import crudState from '@/store/crud/state'
const state = {
  ...crudState(),
  invoices: [],
  suppliers: [],
  filters: {
    title: "",
    warehouse_id: "",
  },
  sorting: {
    direction: "DESC",
    field: "storage_incoming_invoices.updated_at"
  }
}
export default state

