import { render, staticRenderFns } from "./ProductItemRequest.pug?vue&type=template&id=90cf252e&scoped=true&lang=pug&"
import script from "./ProductItemRequest.js?vue&type=script&lang=js&"
export * from "./ProductItemRequest.js?vue&type=script&lang=js&"
import style0 from "./ProductItemRequest.scss?vue&type=style&index=0&id=90cf252e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../shared/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "90cf252e",
  null
  
)

export default component.exports