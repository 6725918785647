import ProductNavItem from '@/components/layout/category-menu/product-nav-item/ProductNavItem';
// import ProductNavSubmenu from '@/components/layout/ProductNavSubmenu';
export default {
  props: ['hasSubmenu', 'items', 'path', 'active',"withSelect"],
  components: {
    ProductNavItem,
    // ProductNavSubmenu
  },
  data() {
    return {
      menuShow: false,
    }
  },
  methods: {
    select(item){
      this.$emit("select",item)
    }
  }
}