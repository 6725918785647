export default {
  message: null,
  breadcrumbs: [],
  loading: false,
  contact_saved: false,
  show_login_btn: false,
  need_login: false,
  feedback_saved: false,
  global_config: {
    roles:['seller','buyer','trader'],
    categories: [],
    sellers: [],
    filters:{
      delivery_conditions: [],
      origins: [],
      payment_types: [],
      permissions: [],
      prices: [0,100500]
    },
  },
  last_config: {
    roles:['seller','buyer','trader'],
    categories: [],
    sellers: [],
    filters:{
      delivery_conditions: [],
      origins: [],
      payment_types: [],
      permissions: [],
      prices: [0,100500]
    },
  },
  filters: {
    offers: {},
    requests: {}
  },
  product_filters: {
    delivery: [],
    payment_types: [],
    characteristics: {},
    origins: [],
    permissions: [],
    prices: [0, 100000],
  },
  sorting: {
    offers: {
      direction: "DESC",
      field: "updated_at"
    },
    requests: {
      direction: "DESC",
      field: "updated_at"
    }
  },
  currentCategory: '',
  currentCompany: {},
  currentCompanyItems:{
    offers: [],
    requests: []
  },
  error: null
}

