import Treeselect from '@riophae/vue-treeselect'
import ProductName from "@/components/layout/product-name/ProductName";
import ProductRow from "@/components/platform/marketplace/offer/product-table-item/row/Row";

export default {
  props: ['item'],
  components: {
    Treeselect,
    ProductName,
    ProductRow
  },
  data(){
    return {
      selected: {}
    }
  },

  watch:{
    selected(val){
      console.log(val)
    }
  }
}
