import $http from "axios";
import crudActions from '@/store/crud/actions'

const actions = {
  ...crudActions('movements'),
  Finish({ commit, getters, dispatch }, id) {
    return new Promise((resolve, reject) => {
      $http
        .get(`/movements/${id}/finish`)
        .then(
          (response) => {
            if (response.data.success) {
            } else {
              commit("SET", { type: "items", items: [] });
              dispatch("marketplace/Failure", response.data.message, {
                root: true,
              });
            }
            resolve(response.data);
          },
          (error) => {
            reject(error);
          }
        )
        .catch((err) => console.log(err.response));
    });
  },
  Start({ commit, getters, dispatch }, id) {
    return new Promise((resolve, reject) => {
      $http
        .get(`/movements/${id}/start`)
        .then(
          (response) => {
            if (response.data.success) {
              commit("SET", { type: "items", items: [] });
            } else {
              commit("SET", { type: "items", items: [] });
              dispatch("marketplace/Failure", response.data.message, {
                root: true,
              });
            }
            resolve(response.data);
          },
          (error) => {
            reject(error);
          }
        )
        .catch((err) => console.log(err.response));
    });
  },
  Departure({ commit, getters, dispatch }, item) {
    return new Promise((resolve, reject) => {
      $http
        .put(`/movements/${item.id}/departure`, item)
        .then(
          (response) => {
            if (response.data.success) {
              commit("SET", {
                type: "item_saved",
                items: [],
              });
            } else {
              commit("SET", { type: "item_saved", items: [] });
              dispatch("marketplace/Failure", response.data.message, {
                root: true,
              });
            }
            resolve(response.data);
          },
          (error) => {
            reject(error);
          }
        )
        .catch((err) => console.log(err.response));
    });
  },
  Arrival({ commit, getters, dispatch }, item) {
    return new Promise((resolve, reject) => {
      $http
        .put(`/movements/${item.id}/arrival`, item)
        .then(
          (response) => {
            if (response.data.success) {
              commit("SET", {
                type: "item_saved",
                items: [],
              });
            } else {
              commit("SET", { type: "item_saved", items: [] });
              dispatch("marketplace/Failure", response.data.message, {
                root: true,
              });
            }
            resolve(response.data);
          },
          (error) => {
            reject(error);
          }
        )
        .catch((err) => console.log(err.response));
    });
  },
  Cancell({ commit, getters, dispatch }, item) {
    return new Promise((resolve, reject) => {
      $http
        .put(`/movements/${item.id}/cancell`, item)
        .then(
          (response) => {
            if (response.data.success) {
              commit("SET", {
                type: "item_saved",
                items: [],
              });
            } else {
              commit("SET", { type: "item_saved", items: [] });
              dispatch("marketplace/Failure", response.data.message, {
                root: true,
              });
            }
            resolve(response.data);
          },
          (error) => {
            reject(error);
          }
        )
        .catch((err) => console.log(err.response));
    });
  },
  New({ commit, dispatch }) {
    return new Promise((resolve, reject) => {
      $http
        .get(`/movements/new`)
        .then(
          (response) => {
            if (response.data.success) {
              commit("SET", {
                type: "item_new",
                items: response.data.item,
              });
              commit("SET", { type: "item_edited", items: {items: {}}});

            } else {
              commit("SET", { type: "item_new", items: {} });
              dispatch("marketplace/Failure", response.data.message, {
                root: true,
              });
            }
            resolve(response.data);
          },
          (error) => {
            reject(error);
          }
        )
        .catch((err) => console.log(err.response));
    });
  },
  Show({ commit, dispatch }, id) {
    return new Promise((resolve, reject) => {
      $http
        .get(`/movements/${id}`)
        .then(
          (response) => {
            if (response.data.success) {
              commit("SET", {
                type: "item_edited",
                items: response.data.item,
              });
            } else {
              commit("SET", { type: "item_edited", items: {} });
              dispatch("marketplace/Failure", response.data.message, {
                root: true,
              });
            }
            resolve(response.data);
          },
          (error) => {
            reject(error);
          }
        )
        .catch((err) => console.log(err.response));
    });
  },
  Edit({ commit, dispatch }, id) {
    return new Promise((resolve, reject) => {
      $http(`/movements/${id}/edit`)
        .then(
          (response) => {
            if (response.data.success) {
              commit("SET", { type: "item_edited", items: response.data.item });
              resolve(response.data);
            } else {
              commit("SET", { type: "item_edited", items: {} });
              dispatch("marketplace/Failure", response.data.message, {
                root: true,
              });
            }
          },
          (error) => {
            console.log(error);
          }
        )
        .catch((err) => console.log(err.response));
    });
  },
  Save({ commit, dispatch }, item) {
    return new Promise((resolve, reject) => {
      let method = item.id ? "put" : "post";
      $http[method](`/movements/${item.id || ""}`, item)
        .then(
          (response) => {
            commit("SET", { type: "item_saved", items: response.data.success });
            if (response.data.success) {
            } else {
              dispatch("marketplace/Failure", response.data.message, {
                root: true,
              });
            }
            resolve(response.data);
          },
          (error) => {
            reject(error);
          }
        )
        .catch((err) => console.log(err.response));
    });
  }
}
export default actions
