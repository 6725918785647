import crudGetters from '@/store/crud/getters'
const getters = {
  ...crudGetters(),
  getInvoices(state) {
    return state.invoices;
  },
  getSuppliers(state) {
    return state.suppliers;
  }
}
export default getters


