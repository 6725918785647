import VueSlider from "vue-slider-component";
import Treeselect from "@riophae/vue-treeselect";

export default {
  props: ["items"],
  components: {
    VueSlider,
    Treeselect,
  },
  created(){
    this.filters.prices = this.filters.prices || this.config.filters.prices
  },
  methods: {
    clear() {
      this.$router.push({ query: {} });
      this.menuShow = false;
    },
    getSort() {
      let sortArray = [
        "id",
        "shop_product_prices|price",
        "shop_product_bonuses|bonus_value",
      ];
      let sortItem = sortArray.map((i) =>
        this.translate("common.sorting." + i)
      );
      return sortItem;
    },
    setCharacteristicFilter(id){
      let query = JSON.parse(JSON.stringify(this.$route.query))
      query[id] = this.filters.characteristics[id]
      this.clear()
      this.$router.push({ query: query });
    },
    setFilter(type){
      let query = JSON.parse(JSON.stringify(this.$route.query))
      query[type] = this.filters[type]
      this.clear()
      this.$router.push({ query: query });
    },
    update() {
      this.menuShow = false;
      this.$isLoading("isLoading", "items");
      this.$store.dispatch("products/List");
      window.scrollTo(0, 0);
    },
    toggleMenu() {
      this.menuShow = !this.menuShow;
    },
  },
  data() {
    return {
      menuShow: false,
      sorter: {
        sorter: "rating",
        title: this.translate("common.sorting.rating"),
      }
    };
  },
  computed: {
    prices() {
      return this.items.prices[0] ? this.items.prices : [0, 100000];
    },
    productFilters() {
      return this.$store.getters["products/getFilters"];
    },
    filters: {
      get(){
        return this.$store.getters["marketplace/getProductFilters"];
      },
      set(val){
        this.$store.dispatch("marketplace/SetProductFilters", val);
      }
    }
  },
  watch: {
    sorter(val) {
      this.$router.push({
        query: Object.assign({}, this.$route.query, {
          direction: "ASC",
          field: val.sorter,
        }),
      });
    },
  },
};
