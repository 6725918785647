import $http from "axios";
import crudActions from '@/store/crud/actions'
const actions = {
  ...crudActions('products'),
  SetActiveItemsType({ commit }, type) {
    if (["offers", "requests"].includes(type)) {
      commit("SET", { type: "active_item_type", items: type });
    }
  },
  SetActiveItem({ commit }, item) {
    commit("SET", { type: "active_item", items: item });
  },
  SetItems({ commit }, item) {
    commit("SET", { type: "items", items: item });
  },
  SetFullItems({ commit }, item) {
    commit("SET", { type: "full_items", items: item });
  },
  ClearSelected({ commit }) {
    commit("SET", { type: "selected_items", items: {} });
  },
  Select({ commit, getters }, { id, val }) {
    let currentSelected = getters.getSelected;
    currentSelected[id] = val;
    commit("SET", {
      type: "selected_items",
      items: Object.assign({}, getters.getSelected, currentSelected),
    });
  },
  
  DescriptionImageAttach({ commit }, file) {
    return new Promise((resolve, reject) => {
      let form = new FormData();
      form.append(`image`, file);
      $http
        .post(`/products/description_image_attach`, form, {
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(
          (response) => {
            commit("SET", { type: "item_saved", items: response.data.success });
            resolve(response.data);
          },
          (error) => {
            reject(error);
          }
        )
        .catch((err) => console.log(err.response));
    });
  },
  List({ commit, getters }, cabinet) {
    cabinet = cabinet || false;
    return new Promise((resolve, reject) => {
      let filters = getters.getFilters;
      let send_filters = {};
      for (let key of Object.keys(filters)) {
        if (filters[key] && typeof filters[key] == "object" && !Array.isArray(filters[key])) {
          send_filters[key] = filters[key].id;
        } else {
          send_filters[key] = filters[key];
        }
      }
      let params = {
        filters: send_filters,
        page: getters.getPage,
        sorting: getters.getSorting,
      };
      if (cabinet) {
        params["cabinet"] = true;
      }
      $http
        .get(`/products`, { params: params })
        .then(
          (response) => {
            if (response.data.success) {
              commit("SET", { type: "items", items: response.data.items });
              commit("SET", { type: "total", items: response.data.total });
              commit('SET_LOCALSTORE', { type: 'last_items', items: response.data.items })
              commit("SET", { type: "filtered_ids", items: response.data.filtered_ids });
            } else {
              commit("SET", { type: "items", items: [] });
            }
            resolve(response.data);
          },
          (error) => {
            reject(error);
          }
        )
        .catch((err) => console.log(err.response));
    });
  },
  Save({ commit, getters }, item) {
    return new Promise((resolve, reject) => {
      let form = new FormData();
      if (item.files) {
        for (var i = item.files.length - 1; i >= 0; i--) {
          form.append(`images['${i}']`, item.files[i]);
        }
      }
      for (const [key, value] of Object.entries(item)) {
        form.append(key, value);
      }
      let method = item.id ? "put" : "post";
      $http[method](`/products/${item.id || ""}`, form, {
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(
          (response) => {
            commit("SET", { type: "item_saved", items: response.data.success });
            resolve(response.data);
          },
          (error) => {
            reject(error);
          }
        )
        .catch((err) => console.log(err.response));
    });
  },
  Search({ commit, getters }, query) {
    return new Promise((resolve, reject) => {
      $http
        .get(`/products/search?query=${query}`)
        .then(
          (response) => {
            if (response.data.success) {
              commit("SET", {
                type: "item_searched",
                items: response.data.item,
              });
            } else {
              commit("SET", { type: "item_searched", items: {} });
            }
            resolve(response.data);
          },
          (error) => {
            reject(error);
          }
        )
        .catch((err) => console.log(err.response));
    });
  },
  MassUpdate({ commit, getters }, item) {
    return new Promise((resolve, reject) => {
      $http
        .post(`/products/mass_update`, item)
        .then(
          (response) => {
            commit("SET", { type: "item_saved", items: response.data.success });
            resolve(response.data);
          },
          (error) => {
            reject(error);
          }
        )
        .catch((err) => console.log(err.response));
    });
  },
  FullList({ commit, getters }) {
    return new Promise((resolve, reject) => {
      let params = {
        filters: getters.getFilters,
        page: getters.getPage,
        sorting: getters.getSorting,
      };
      $http
        .get(`/products/full`, { params: params })
        .then(
          (response) => {
            if (response.data.success) {
              commit("SET", { type: "full_items", items: response.data.items });
              commit("SET", { type: "total", items: response.data.total });
            } else {
              commit("SET", { type: "items", items: [] });
            }
            resolve(response.data);
          },
          (error) => {
            reject(error);
          }
        )
        .catch((err) => console.log(err.response));
    });
  },

  My({ commit, getters }) {
    return new Promise((resolve, reject) => {
      $http
        .get(`/offers/my`)
        .then(
          (response) => {
            if (response.data.success) {
              commit("SET", { type: "my_items", items: response.data.items });
            } else {
              commit("SET", { type: "my_items", items: [] });
            }
            resolve(response.data);
          },
          (error) => {
            reject(error);
          }
        )
        .catch((err) => console.log(err.response));
    });
  },
  Full({ commit, getters }, id) {
    return new Promise((resolve, reject) => {
      $http
        .get(`/offers/${id}/full`)
        .then(
          (response) => {
            if (response.data.success) {
              commit("SET", { type: "full_item", items: response.data.item });
            } else {
              commit("SET", { type: "full_item", items: {} });
            }
            resolve(response.data);
          },
          (error) => {
            reject(error);
          }
        )
        .catch((err) => console.log(err.response));
    });
  },
  DeleteImage({ commit, getters }, { id, image_id }) {
    return new Promise((resolve, reject) => {
      $http
        .post(`/products/${id}/remove_attach/images/${image_id}`)
        .then(
          (response) => {
            commit("SET", {
              type: "item_deleted",
              items: response.data.success,
            });
            resolve(response.data);
          },
          (error) => {
            reject(error);
          }
        )
        .catch((err) => console.log(err.response));
    });
  },
}
export default actions