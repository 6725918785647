export default {
  inject: ["$validator"],
  props: [
    "placeholder",
    "value",
    "disabled",
    "name",
    "submitted",
    "validation",
    "product",
    "count",
  ],
  methods: {
    onlyNumber: function(evt) {
      this.$root.onlyNumber(evt);
    },
    minus() {
      if (this.checkAvailability()) {
        if (this.inputVal >= 1) {
          this.inputVal--;
        }
      }
    },
    plus() {
      if (this.checkAvailability()) {
        this.inputVal++;
      }
    },
  },
  data() {
    return { inputVal: 0 };
  },
  created() {
    this.inputVal = this.value;
  },
  watch: {
    inputVal(val) {
      this.$emit("input", val);
      this.$store.dispatch("cart/UpdateFullCart", {
        product: this.product,
        quantity: Number(val),
      });
    },
    value(val, old) {
      this.inputVal = val || 0;
    },
  },
};
