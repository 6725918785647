import $http from "axios";
import crudActions from '@/store/crud/actions'
const actions = {
  ...crudActions('storage_items'),
  Edit({ commit, getters }, sku) {
    let params = {
      filters: getters.getFilters,
    };
    return new Promise((resolve, reject) => {
      $http
        .get(`/storage_items/${sku}/edit`, { params: params })
        .then(
          (response) => {
            if (response.data.success) {
              commit("SET", { type: "item_edited", items: response.data.item });
            } else {
              commit("SET", { type: "item_edited", items: {} });
            }
            resolve(response.data);
          },
          (error) => {
            reject(error);
          }
        )
        .catch((err) => console.log(err.response));
    });
  },
  Save({ commit, getters }, item) {
    let params = {
      filters: getters.getFilters,
    };
    return new Promise((resolve, reject) => {
      $http["post"](`/storage_items/`, Object.assign(item, params))
        .then(
          (response) => {
            commit("SET", { type: "item_saved", items: response.data.success });
            resolve(response.data);
          },
          (error) => {
            reject(error);
          }
        )
        .catch((err) => console.log(err.response));
    });
  },
  List({ commit, getters, dispatch }) {
    const params = {
      filters: getters.getFilters,
      page: getters.getPage,
      sorting: getters.getSorting,
    }
    return new Promise((resolve, reject) => {
      $http.get(`/storage_items`,{ params: params }).then(response => { 
        const items = response.data.success ? response.data.items : []
        commit('SET', { type: 'items', items: items })
        commit('SET_LOCALSTORE', { type: 'last_items', items: items })
        commit('SET', {type: 'total', items: response.data.total })
        commit('SET', {type: 'buttons', items: response.data.buttons })
        if (!response.data.success) {
          dispatch('marketplace/Failure', response.data.message, { root: true })
        }
        resolve(response.data)
      }, error => {
          reject(error);
        }
      ).catch(err => console.log(err.response))
    })
  },
  GetLogs({ commit, getters, dispatch }, params) {
    let data = {
      ...params,
      page: getters.getPage
    }
    return new Promise((resolve, reject) => {
      $http
      .get(`/storage_items/get_logs`, { params: data })
        .then(
          (response) => {
            if (response.data.success) {
              commit("SET", { type: "log_items", items: response.data.items });
              commit("SET", {
                type: "total_page",
                items: response.data.total,
              });
            } else {
              commit("SET", { type: "items", items: [] });
              dispatch("marketplace/Failure", response.data.message, {
                root: true,
              });
            }
            resolve(response.data);
          },
          (error) => {
            reject(error);
          }
        )
        .catch((err) => console.log(err.response));
    });
  },
  SetWarningQuantity({ commit, getters, dispatch }, params) {
    return new Promise((resolve, reject) => {
      $http
      .get(`/storage_items/${params.id}/set_warning_quantity`, { params: params })
        .then(
          (response) => {
            if (response.data.success) {
              commit("SET", { type: "item_saved", items: response.data.success });
            } else {
              commit("SET", { type: "item_saved", items: [] });
              dispatch("marketplace/Failure", response.data.message, {
                root: true,
              });
            }
            resolve(response.data);
          },
          (error) => {
            reject(error);
          }
        )
        .catch((err) => console.log(err.response));
    });
  },
  New({ commit, dispatch }) {
    return new Promise((resolve, reject) => {
      $http
        .get(`/storage_items/new`)
        .then(
          (response) => {
            if (response.data.success) {
              commit("SET", {
                type: "item_new",
                items: response.data.item,
              });
            } else {
              commit("SET", { type: "item_new", items: {} });
              dispatch("marketplace/Failure", response.data.message, {
                root: true,
              });
            }
            resolve(response.data);
          },
          (error) => {
            reject(error);
          }
        )
        .catch((err) => console.log(err.response));
    });
  },
}
export default actions
