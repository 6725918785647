import NavMenu from "@/components/layout/nav-menu/NavMenu";
import ExternalLinks from "@/components/ui/external-links/ExternalLinks";
import MessageCounter from "@/components/ui/message-counter/MessageCounter";
import QuickMenu from "@/components/ui/quick-menu/QuickMenu";
import Breadcrumbs from "@/components/layout/breadcrumbs/Breadcrumbs";
import AuthPopup from "@/components/popup/AuthPopup.vue";
// import FinanceBoard from "@/components/finance-board/FinanceBoard.vue";
import Bonuses from "@/components/ui/bonuses/Bonuses";
import Cart from "@/components/ui/cart/Cart";

export default {
  components: {
    NavMenu,
    ExternalLinks,
    MessageCounter,
    QuickMenu,
    Breadcrumbs,
    AuthPopup,
    Cart,
    Bonuses,
    // FinanceBoard
  },
  data() {
    return {
      darkMode: false,
      menuShow: false,
      activeTab: window.location.pathname.replace("/", ""),
      lang: window.gon.config.lang.map(obj => ({id: obj, label: this.translate(`lang.${obj}`)})),
      window: {
        width: 0
      }
    };
  },
  watch: {
    darkMode() {
      let htmlElement = document.documentElement;

      if (this.darkMode) {
        localStorage.setItem('theme', 'dark');
        htmlElement.setAttribute('theme', 'dark');
      } else {
        localStorage.setItem('theme', 'light');
        htmlElement.setAttribute('theme', 'light');
      }
    }
  },
  methods: {
    setLocale(l){
      window.location.href = `/${l.id}/${window.location.path}`
    },
    showLogin() {
      this.$bvModal.show("register-popup");
      if ("login" != this.$router.name) {
        this.$router.push({ name: "login" });
        this.menuShow = false;
      }
    },
    toggle: function() {
      this.menuShow = !this.menuShow;
    },
    handleResize() {
      this.window.width = window.innerWidth;
    }
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  computed: {
    defauldLangSelected(){
      return this.gon.config.locale ? this.lang.find((obj) => obj.id == this.gon.config.locale) : {id: "en", label: "En"}
    },
    isNotMap() {
      return "map" != this.$route.name;
    },
    show_map() {
      return gon.config.show_map;
    },
    showBreadcrumbs() {
      this.show_map && !this.$route.meta.is_map;
    }
  }
};
