export default {
  data() {
    return {
      phone: null,
      roles: [],
      submitted: false,
      tabs: ['email', 'phone'],
      active_nav: 'phone',
      item: {
        email: null,
        captcha: null,
      },
    }
  },

  methods:{
    onUpdate(e){
      this.item.login = e.formattedNumber.replace(/\+/g, '')
      if(e.isValid){
        this.phoneIsValid = true
        this.errors.remove('phone')
      }
      else{
        this.phoneIsValid = false
      }
      return e;
    },
    onCaptchaExpired(e){
    },
    verifyCaptcha(e){
      this.item.captcha = e
      this.submit()
    },
    showLogin(){
      this.$store.dispatch('account/AddItem', {type: 'auth', items: 'login' });
    },
    getPayload(){
      const acc = this.item
      acc.captcha = this.captcha
      acc.restore_type = this.active_nav
      return acc;
    },
    submit (e) {
      this.submitted = true;
      this.$validator.validateAll().then((result) => {
        if(result){
          this.$isLoading('isLoading', 'remind');
          this.$store.dispatch('users/Remind', {user: this.getPayload()} ).then(response => {
            if(response.success){
              if (response.roles) {
                this.roles = response.roles;
              } else {
                this.$store.dispatch('account/AddItem', {type: 'auth', items: 'login' });
                this.$store.dispatch('marketplace/Success', this.translate("common.check_your_email") )
              }
            }
            else{
              this.$store.dispatch('marketplace/Failure',response.message)
            }
          })
        }
      })
    }
  }
}